<div class="password-check">
    <div class="password-check-column">
        <ul>
            <li class="list" [ngClass]="{ 'strike': hasLowercase() }">{{ 'LOGIN.PASSWORD_LIST.LOWERCASE' | translate }}</li>
            <li class="list" [ngClass]="{ 'strike': hasEight() }">{{ 'LOGIN.PASSWORD_LIST.EIGHT' | translate }}</li>
            <li class="list" [ngClass]="{ 'strike': hasNumber() }">{{ 'LOGIN.PASSWORD_LIST.NUMBER' | translate }}</li>
        </ul>
    </div>
    <div>
        <ul>
            <li class="list" [ngClass]="{ 'strike': hasUppercase() }">{{ 'LOGIN.PASSWORD_LIST.UPPERCASE' | translate }}</li>
            <li class="list" [ngClass]="{ 'strike': hasSpecial() }">{{ 'LOGIN.PASSWORD_LIST.SPECIAL' | translate }}</li>
        </ul>
    </div>
</div>