<div class="connexion">
    <form [formGroup]="loginForm" (ngSubmit)="onSent()">
      <h1 class="title">{{ 'LOGIN.LOG' | translate }}</h1>
      <mat-form-field class="account-input">
        <mat-label>{{ 'LOGIN.ACCOUNT' | translate }}</mat-label>
        <input matInput id="account" type="text" formControlName="account" autocomplete="account" required>
      </mat-form-field>
      <div *ngIf="formControls.account.errors && (formControls.account.dirty || formControls.account.touched)" class="text-danger">
        <span *ngIf="formControls.account.errors.required">{{ 'LOGIN.ACCOUNT_REQUIRED' | translate }}</span>
        <span *ngIf="formControls.account.errors.pattern">{{ 'LOGIN.ACCOUNT_PATTERN' | translate }}</span>
        <span *ngIf="formControls.account.errors.maxlength">{{ 'LOGIN.ACCOUNT_LENGTH' | translate }}</span>
      </div>

      <div class="navigation-link" *ngIf="!premiseAccount">
        <div class="link-container first-link"><span class="link" (click)="navigateToWhatIsAccount()" id="navigate-to-what-is-account">{{ 'LOGIN.WHAT_IS_ACCOUNT' | translate }}</span></div>
        <div class="link-container last-link"><span class="link" (click)="navigateToForgotAccount()" id="navigate-to-forgot-account">{{ 'LOGIN.FORGOT_ACCOUNT' | translate }}</span></div>
      </div>

      <div class="second-input">
        <mat-form-field>
          <mat-label>{{ 'LOGIN.EMAIL' | translate }}</mat-label>
          <input matInput id="connexion-username-input" type="email" formControlName="username" autocomplete="email" required>
        </mat-form-field>
        <div *ngIf="formControls.username.errors && (formControls.username.dirty || formControls.username.touched)" class="text-danger">
          <span *ngIf="formControls.username.errors.required">{{ 'LOGIN.EMAIL_REQUIRED' | translate }}</span>
          <span *ngIf="formControls.username.errors.pattern">{{ 'LOGIN.EMAIL_PATTERN' | translate }}</span>
        </div>
      </div>

      <app-password-input [form]="loginForm" autocomplete="password"></app-password-input>
      <div class="link-container last-link navigation-link"><span class="link" (click)="navigateToForgotPassword()" id="navigate-to-forgot-password">{{ 'LOGIN.FORGOT_PASSWORD' | translate }}</span></div>
      <app-text-button class="login-button" [text]="'LOGIN.LOGIN' | translate" [disabled]="loginForm.invalid" size="big" id="connexion-login"></app-text-button>
      <span class="login-warning">
        <span>{{ 'LOGIN.LOGIN_WARNING_START' | translate }}</span>
        <a href="https://www.ziwo.io/terms-and-condition/" target=”terms”  (click)="onTermsConsult()" id="connexion-terms">{{ 'LOGIN.LOGIN_WARNING_FIRST_LINK' | translate }}</a>
        <span>{{ 'LOGIN.LOGIN_WARNING_MIDDLE' | translate }}</span>
        <a href="https://www.ziwo.io/data-privacy/" target=”privacy” (click)="onPrivacyConsult()" id="connexion-privacy">{{ 'LOGIN.LOGIN_WARNING_SECOND_LINK' | translate }}</a>
        <span>{{ 'LOGIN.LOGIN_WARNING_END' | translate }}</span>
      </span>
    </form>
</div>