import { Injectable } from '@angular/core';
import _ from 'lodash';
import { DialpadCoreService } from 'src/app/services/dialpad-core.service';

@Injectable({
  providedIn: 'root'
})
export class InboxSocketService {
  private endpointByEventName: {[key: string]: string} = {
    /* eslint-disable @typescript-eslint/naming-convention */
    AssignedConversation: 'GET /conversations:assign',
    UpdatedConversation: 'GET /conversations:update',
    UnassignedConversation: 'GET /conversations:unassign',
    NewConversationLog: 'GET /conversations/logs:insert',
    UpdatedConversationLog: 'GET /conversations/logs:update',
    LiveSmsBalance: 'GET /live/sms/balance',
    /* eslint-enable @typescript-eslint/naming-convention */
  };

  constructor(
    private dialpadCoreService: DialpadCoreService,
  ) {
    this.initSocketHandlers();
  }

  // An enum will be introduced for endpoint at the time of code refacto
  public refreshEvent(endpoint: string): void {
    this.getSocket().then((socket: any) => {
      socket.emit(this.endpointByEventName[endpoint], {});
    });
  }

  private initSocketHandlers(): void {
    _.forEach(this.endpointByEventName, (endpoint: string, eventName:string) => {
      this.getSocket().then(socket => socket.emit('subscribe', endpoint));
      // @ts-ignore
      this['on' + eventName] = (callback: any): Promise<void> => this.getSocket().then(socket => socket.on(endpoint, callback));
      // @ts-ignore
      this['off' + eventName]
        = (listener: any): Promise<void> => this.getSocket().then(socket => socket.removeListener(endpoint, listener));
    });
  }

  private async getSocket(): Promise<any> {
    await this.dialpadCoreService.getCore();
    return this.dialpadCoreService.agentSocket;
  }
}
