import { EventEmitter, Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class LoadingScreenService {

  public showEvent = new EventEmitter<boolean|undefined>();

  constructor() { }

  public hide(): void {
    this.showEvent.emit(false);
  }

  public show(): void {
    this.showEvent.emit(true);
  }
}
