<app-offline-alert></app-offline-alert>
<div id="layout" [ngClass]="currentUser.type">
  <div class="sidebar">
    <div class="ziwo-logo">
      <app-ziwo-logo diameter="45px"></app-ziwo-logo>
    </div>
    <nav class="entries">
      <div class="entry" *ngFor="let entry of menu.items" [ngClass]="{'selected': isCurrentRoute(entry.route)}"
        [matTooltip]="entry.title" matTooltipPosition="right">
        <a [routerLink]="entry.route">
          <mat-icon *ngIf="entry.icon">{{ entry.icon }}</mat-icon>
          <mat-icon *ngIf="entry.iconPath" [svgIcon]="entry.iconPath"></mat-icon>
        </a>
      </div>
      <div *ngIf="menu.more && menu.more.length > 0" class="entry">
        <span [matMenuTriggerFor]="moreMenu">
          <mat-icon>more_vert</mat-icon>
        </span>
        <mat-menu #moreMenu="matMenu">
          <div *ngFor="let item of menu.more">
            <div *ngIf="!item.route">
              <div class="infobox">
                <strong [innerHtml]="item.title"></strong>
              </div>
            </div>
            <div *ngIf="item.route">
              <button class="menu-item" mat-menu-item [id]="'layout-' + item.title">
                <span>{{ item.title | translate }}</span>
                <mat-icon>{{ item.icon }}</mat-icon>
              </button>
            </div>
          </div>
        </mat-menu>
      </div>
    </nav>
    <div class="user">
      <app-user-picture [matMenuTriggerFor]="userMenu"></app-user-picture>
      <mat-menu #userMenu="matMenu">
        <div class="infobox">
          <strong [innerHtml]="currentUserService.get() | fullname"></strong>
          <span [innerHtml]="currentUserService.get().username"></span>
        </div>
        <button class="menu-item" mat-menu-item id="layout-profile-button" [routerLink]="getProfileRoute()">
          <span>{{ 'LAYOUT.PROFILE' | translate }}</span>
          <mat-icon>launch</mat-icon>
        </button>
        <button class="menu-item" mat-menu-item (click)="logout()" id="layout-logout-button">
          <span>{{ 'LAYOUT.LOGOUT' | translate }}</span>
          <mat-icon>logout</mat-icon>
        </button>
      </mat-menu>
    </div>
  </div>
  <div class="content">
    <app-status-bar></app-status-bar>
    <router-outlet></router-outlet>
  </div>
</div>