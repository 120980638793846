<div class="connexion forgot-account">
    <form [formGroup]="forgotAccountForm" (ngSubmit)="onSent()">
      <h1 class="title">{{ 'LOGIN.FORGOT_ACCOUNT_PASSWORD.FORGOT_ACCOUNT_NAME' | translate }}</h1>
      <div class="login-message grey-message">{{ 'LOGIN.FORGOT_ACCOUNT_PASSWORD.SEND_MESSAGE' | translate }}</div>
      
      <mat-form-field class="email-input">
        <mat-label>{{ 'LOGIN.EMAIL' | translate }}</mat-label>
        <input matInput id="forgot-account-input" type="text" formControlName="username" required>
      </mat-form-field>
      <div *ngIf="formControls.username.errors && (formControls.username.dirty || formControls.username.touched)" class="text-danger">
        <span *ngIf="formControls.username.errors.required">{{ 'LOGIN.EMAIL_REQUIRED' | translate }}</span>
        <span *ngIf="formControls.username.errors.pattern">{{ 'LOGIN.EMAIL_PATTERN' | translate }}</span>
      </div>
      
      <app-text-button class="login-button" [text]="'LOGIN.FORGOT_ACCOUNT_PASSWORD.SEND_REMINDER' | translate" size="big" [disabled]="forgotAccountForm.invalid" id="forgot-account-send-reminder"></app-text-button>
      
      <div class="navigation-link">
        <div class="link-container"><span class="link" (click)="onQuit()" id="forgot-account-navigation-back"><mat-icon class="link-icon">arrow_back</mat-icon>{{ 'LOGIN.FORGOT_ACCOUNT_PASSWORD.BACK_LOGIN' | translate }}</span></div>
      </div>

      <span class="login-warning">
        This site is protected by reCAPTCHA and the Google
        <a href="https://policies.google.com/privacy" target="privacy-google">Privacy Policy</a> and
        <a href="https://policies.google.com/terms" target="terms-google">Terms of Service</a> apply.
      </span>
    </form>
</div>