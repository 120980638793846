import { AfterContentChecked, ChangeDetectorRef, Component, ElementRef, Input, OnInit, ViewChild } from '@angular/core';
import _ from 'lodash';
import { InfoCategory, InfoService, SmsInfos } from '../../../../../services/info.service';
import { Conversation, ConversationLog, ConversationsService, ConversationType, LogType } from '../../../conversations.service';

@Component({
  selector: 'app-current-conversation-panel-logs',
  templateUrl: './current-conversation-panel-logs.component.html',
  styleUrls: ['./current-conversation-panel-logs.component.scss']
})
export class CurrentConversationPanelLogsComponent implements OnInit, AfterContentChecked {

  // @ts-ignore
  @ViewChild('conversationLogs') public conversationLogs: ElementRef;
  @Input() public conversation!: Conversation;

  constructor(private infoService: InfoService, private ref: ChangeDetectorRef, private conversationsService: ConversationsService) { }

  public ngOnInit(): void {
  }

  public ngAfterContentChecked(): void {
    this.ref.detectChanges();
  }

  public backToMainConversation(): void {
    this.conversationsService.backToMainConversation();
  }

  public trackById(index: number, log: ConversationLog): string {
    return log.id;
  }

  public shouldDisplayMessenger(): boolean {
    return this.conversation && !this.conversation.isHistory && (this.conversation.status !== 'closed'
      || this.conversation.type === ConversationType.Call && this.infoService.getSmsInfos().enabled);
  }

  public displayPreviousMessagesButton(): boolean {
    return this.conversation.hasUnrespondedTransfer && this.isFirstLogInvisible();
  }

  public scrollToPreviousMessages(): void {
    if (!this.conversation.areLogsLoading) this.scrollToFirstLog(true);
  }

  public onScroll(event: any): void {
    // Scrolled to the top of the element
    if (!this.conversation.allLogsLoaded && !this.conversation.areLogsLoading && event.target.scrollTop === 0) {
      this.scrollMore(event.target);
    }
  }

  private scrollToFirstLog(scrollMoreToLoad: boolean = false): void {
    if (this.conversation.allLogsLoaded) {
      const firstLog = this.getFirstLog();
      if (firstLog) firstLog.scrollTo();
    } else if(scrollMoreToLoad) this.scrollToFindFirstLog();
  }

  private scrollMore(eventTarget: HTMLElement, callback?: () => void): Promise<any> {
    return this.conversation.getMoreLogs().then((res:any) => window.setTimeout(() => {
      if (eventTarget) eventTarget.scrollTop = 200;
      if (callback) callback();
    }, 0));
  }

  private scrollToFindFirstLog(): void {
    if (this.conversationLogs && this.conversationLogs.nativeElement) {
      this.scrollMore(this.conversationLogs.nativeElement, () => this.scrollToFirstLog());
    }
  }

  private isFirstLogInvisible(): boolean {
    if (!this.conversationLogs || !this.conversationLogs.nativeElement) return false;
    if (!this.conversation.allLogsLoaded) return true;

    const firstLog = this.getFirstLog();
    if (firstLog && this.getLogElement(firstLog.id)) {
      const distanceToPartiallyCoverLog = 18;
      // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
      return this.getLogElement(firstLog.id)!.getBoundingClientRect().top + distanceToPartiallyCoverLog
        < this.conversationLogs.nativeElement.getBoundingClientRect().top;
    }
    return false;
  }

  private getLogElement(logId: string): HTMLElement | null {
    return document.getElementById(logId);
  }

  private getFirstLog(): ConversationLog | undefined {
    return _.last(this.conversation.logs);
  }
}
