import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { AbstractControl, FormControl, FormGroup, ValidatorFn, Validators } from '@angular/forms';
import { TranslateService } from '@ngx-translate/core';
import { AccountService } from '../../services/account.service';
import { CurrentUserService, FormUser, User } from '../../services/current-user.service';
import { RegexService } from '../../services/regex.service';
import { UploaderService } from '../../services/uploader.service';
import { InfoboxService } from '../infobox/infobox.service';
import { base64ToFile, ImageCroppedEvent } from 'ngx-image-cropper';

@Component({
  selector: 'app-profile',
  templateUrl: './profile.component.html',
  styleUrls: ['./profile.component.scss']
})
export class ProfileComponent implements OnInit {
  @ViewChild('imageSelectorElement') public imageSelectorElement!: ElementRef;

  public profileForm!: FormGroup;
  public instanceName = this.accountService.accountName;
  public isDraggingOver = false;
  public imageSelector = new ImageSelector();
  public imageChangedEvent: any = '';

  constructor(
    public currentUserService: CurrentUserService,
    private regexService: RegexService,
    private accountService: AccountService,
    private infoboxService: InfoboxService,
    private uploaderService: UploaderService,
    private translateService: TranslateService,
  ) { }


  public imageCropped(event: ImageCroppedEvent): void {
    this.imageSelector.croppedImage = event.base64;
    if (this.imageSelector.croppedImage) this.imageSelector.croppedBlob = base64ToFile(this.imageSelector.croppedImage);
  }

  public ngOnInit(): void {
    this.profileForm = this.buildForm();
  }

  public get formControls(): { [key: string]: AbstractControl } {
    return this.profileForm.controls;
  }

  public get currentUser(): {firstName: string; lastName: string} {
    return {
      firstName: this.formControls.firstName.value,
      lastName: this.formControls.lastName.value,
    };
  }

  public async onSave(): Promise<any> {
    const photo = await this.uploadImage();
    let photoName;
    if (photo && photo.result) photoName = photo.content.name;
    const updateProfileResult = await this.currentUserService.updateUser(this.getNewProfile(photoName));
    if (!updateProfileResult.result) this.infoboxService.error(updateProfileResult.errorMessage || updateProfileResult.error.error.message);
    else {
      this.imageSelector.image = null;
      this.imageSelector.selected = false;
      this.infoboxService.shortInfo(this.translateService.instant('PROFILE.SAVE_SUCCESS'));
    }
    return updateProfileResult;
  }

  public displayMessages(controlName: string): boolean {
    return Boolean(!this.formControls[controlName] || this.formControls[controlName].errors &&
      (this.formControls[controlName].dirty || this.formControls[controlName].touched));
  }

  public displayPasswordCheck(): boolean {
    return Boolean(this.formControls.newPassword.value);
  }

  public onDragLeave(event: any): void {
    this.isDraggingOver = false;
  }

  public onDragOver(event: any): void {
    this.isDraggingOver = true;
    event.preventDefault();
  }

  public onDropSuccess(event: any): void {
    this.isDraggingOver = false;
    event.preventDefault();
    const files = this.uploaderService.getFilesFromDataTransfer(event.dataTransfer);
    this.imageSelector.select(files);
    this.fileChangeEvent(event);
  }

  public selectFromEvent(event: Event): void {
    const target = event.target as HTMLInputElement;
    if (!target.files) return;
    this.imageSelector.select(Array.from(target.files));
    this.fileChangeEventFromEvent(event);
  }

  private fileChangeEventFromEvent(event: Event): void {
    this.imageChangedEvent = event;
  }

  private fileChangeEvent(event: any): void {
    event.dataTransfer.items[0].webkitGetAsEntry().file((imageEvent: any) => {
      this.imageChangedEvent = {target: {files: [imageEvent]}};
    });
  }

  private buildForm(): FormGroup {
    return new FormGroup({
      firstName: new FormControl(
        this.currentUserService.get().firstName,
        [Validators.required, Validators.pattern(this.regexService.alphaNum)]
      ),
      lastName: new FormControl(
        this.currentUserService.get().lastName,
        [Validators.required, Validators.pattern(this.regexService.alphaNum)]
      ),
      contactNumber: new FormControl(this.currentUserService.get().contactNumber || '', [Validators.pattern(this.regexService.phone)]),
      oldPassword: new FormControl('', [Validators.pattern(this.regexService.eightChars)]),
      newPassword: new FormControl('', [
        Validators.pattern(this.regexService.lowercase),
        Validators.pattern(this.regexService.uppercase),
        Validators.pattern(this.regexService.digit),
        Validators.pattern(this.regexService.special),
        Validators.pattern(this.regexService.eightChars),
      ]),
      confirmPassword: new FormControl('', []),
    }, [this.checkMatchValidator(), this.checkPasswordChanging()]);
  }

  private checkMatchValidator(): ValidatorFn {
    return (control: AbstractControl): {[key: string]: any} | null => {
      const controlsPassword = this.profileForm && this.formControls.newPassword;
      const matchingControlsPassword = this.profileForm && this.formControls.confirmPassword;
      let error = null;
      if (!controlsPassword || !matchingControlsPassword || matchingControlsPassword.errors && !matchingControlsPassword.errors.match) {
        return error;
      }
      if (controlsPassword.value !== matchingControlsPassword.value) {
        error = { match: true };
      }
      matchingControlsPassword.setErrors(error);
      return error;
    };
  }

  private checkPasswordChanging(): ValidatorFn {
    return (control: AbstractControl): {[key: string]: any} | null => {
      const newPassword = this.profileForm && this.formControls.newPassword;
      const oldPassword = this.profileForm && this.formControls.oldPassword;
      let error = null;
      if (!newPassword || !oldPassword || oldPassword.errors && !oldPassword.errors.conditionalRequired) {
        return error;
      }
      if (newPassword.value && !oldPassword.value) {
        error = { conditionalRequired: true };
      }
      oldPassword.setErrors(error);
      return error;
    };
  }

  private uploadImage(): Promise<any> {
    if (!this.imageSelector.selected || !this.imageSelector.croppedBlob) {
      return Promise.resolve({status: false, errorMessage: this.translateService.instant('PROFILE.NO_IMAGE_SELECTED')});
    }
    const file = this.uploaderService.fileFromBlob(this.imageSelector.croppedBlob, 'name');
    return this.uploaderService.uploadFile('image', file, 'profile');
  }

  private getNewProfile(photoName?: string): FormUser {
    const newProfile: FormUser = {
      firstName: this.formControls.firstName.value,
      lastName: this.formControls.lastName.value,
      contactNumber: this.formControls.contactNumber.value,
      profileType: this.currentUserService.get().profileType,
      photo: photoName,
    };
    if (this.formControls.newPassword.value) {
      newProfile.currentPassword = this.formControls.oldPassword.value;
      newProfile.password = this.formControls.newPassword.value;
    }
    return newProfile;
  }
}

export class ImageSelector {
  public selected = false;
  public image: string | ArrayBuffer | null = null;
  public croppedBlob?: Blob;
  public croppedImage?: string | null;

  constructor() { }

  public select(files?: File[]): void {
    if (!files) return;
    const file = files.length ? files[0] : files;
    const reader = new FileReader();
    reader.onload = (event: ProgressEvent<FileReader>): void => {
      this.image = event.target ? event.target.result : null;
      this.selected = true;
    };
    reader.readAsDataURL(file as Blob);
  }
}
