import { Component, ElementRef, Inject, OnInit, ViewChild } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { TranslateService } from '@ngx-translate/core';
import _ from 'lodash';
import { InfoboxService } from '../../../../../components/infobox/infobox.service';
import { CustomerNumberPipe } from '../../../../../pipes/customer-number.pipe';
import { FullnamePipe } from '../../../../../pipes/fullname.pipe';
import { GeneralInfos, InfoCategory, InfoService } from '../../../../../services/info.service';
import { RegexService } from '../../../../../services/regex.service';
import { ContactType, Customer } from '../../../conversations.service';
import { InboxService } from '../../../inbox.service';
import { MatSelect } from '@angular/material/select';

export interface ContactInfo {
  isOpen: boolean;
  isNew: boolean;
  controlIndex: number;
}

@Component({
  selector: 'app-customer-profile-dialog',
  templateUrl: './customer-profile-dialog.component.html',
  styleUrls: ['./customer-profile-dialog.component.scss']
})
export class CustomerProfileDialogComponent implements OnInit {

  @ViewChild('selectCustomerProfileTitle') public selectCustomerProfileTitle!: MatSelect;
  @ViewChild('customerProfileTitleInput') public customerProfileTitleInput!: ElementRef;

  public isSaving = false;
  public hideSlot: {[key: string]: boolean} = {phone: false, email: false};
  public previousCustomTitle = '';
  public formCustomer!: Customer;
  public profileForm!: FormGroup;
  public titleList: string[] = [];
  public genderList: string[] = [];
  public today = new Date();
  public warningByContactValue: { [key: string]: string } = {};
  public isTitleCustom = false;
  public customerContactsInfoArray: {
    phone: ContactInfo[];
    email: ContactInfo[];
  } = {phone: [], email: []};
  private maxControlNumberOfContactTypes: { [key: string]: number } = { phone: 0, email: 0 };
  private hasPrimaryPhone = false;

  constructor(
    public regexService: RegexService,
    private translateService: TranslateService,
    private fullnamePipe: FullnamePipe,
    private fb: FormBuilder,
    private dialogRef: MatDialogRef<CustomerProfileDialogComponent>,
    private customerNumberPipe: CustomerNumberPipe,
    private infobox: InfoboxService,
    private inboxService: InboxService,
    private infoService: InfoService,
    @Inject(MAT_DIALOG_DATA) public data: {customer: Customer},
  ) { }

  public ngOnInit(): void {
    this.formCustomer = _.cloneDeep(this.data.customer);
    const customerTitle = this.data.customer.title;
    this.titleList = [customerTitle];
    this.profileForm = this.getForm();
    this.initContactsControlForm();
    this.inboxService.getCustomerTitles().then((res: any) => {
      this.titleList = res && res.result ? res.content.map((title: any) => title.title) : [];
      if (!this.titleList.includes(customerTitle)) this.titleList.push(customerTitle);
    });
    this.genderList = ['male', 'female', 'other'];
    this.customerContactsInfoArray = {
      phone: this.getArrayOfContacts(this.maxControlNumberOfContactTypes.phone),
      email: this.getArrayOfContacts(this.maxControlNumberOfContactTypes.email),
    };
  }

  public get formControls(): { [key: string]: FormControl } {
    return this.profileForm.controls as { [key: string]: FormControl };
  }

  public getFormTitle(): string {
    return this.translateService.instant('SHARED.GENERAL.EDIT') + ' ' + this.fullnamePipe.transform({
      firstName: this.formControls.firstName && this.formControls.firstName.value,
      lastName: this.formControls.lastName && this.formControls.lastName.value,
    });
  }

  public save(): Promise<any> {
    if (this.disableSave()) {
      throw new Error(this.translatedAlert('SAVE_DISABLED'));
    }
    this.isSaving = true;
    this.updateCustomerCopy();
    return this.data.customer.save(this.formCustomer).then((res: any) => {
      if (!res.result) {
        this.infobox.error(this.errorMessageForResult(res));
      } else {
        this.infobox.info(this.translatedAlert('SUCCESS'));
        this.close();
      }
      this.isSaving = false;
      return res;
    }).catch((error: Error) => {
      this.infobox.error(this.translatedAlert('CATCH_SAVE'));
      this.isSaving = false;
      return error;
    });
  }

  public close(): void {
    if (this.dialogRef && this.dialogRef.close) {
      this.dialogRef.close();
    }
  }

  public trackByIndex(index: number): number {
    return index;
  }

  public trackByControlIndex(index: number, contact: ContactInfo): number {
    return contact.controlIndex;
  }

  public canEditContactValue(contactType: ContactType, contactIsNew: boolean): boolean {
    return contactIsNew
      || contactType !== ContactType.Phone
      || !this.infoService.getGeneralInfos().maskPhoneNumbers;
  }

  public toggleContactOpen(contactType: ContactType, currentIndex: number): void {
    const contact = this.customerContactsInfoArray[contactType][currentIndex];
    if (contact) contact.isOpen = !contact.isOpen;
  }

  public onPrimaryToggle(contactType: ContactType, controlIndex: number): void {
    const toggledControlName = `isPrimary${contactType}${controlIndex}`;
    if (this.formControls[toggledControlName] && this.formControls[toggledControlName].value) {
      _.map(this.formControls, (control: any, controlName: string) => {
        if (controlName.includes(`isPrimary${contactType}`) && controlName !== toggledControlName) {
          this.formControls[controlName].setValue(false);
        }
      });
    } else if (contactType === ContactType.Phone) {
      this.setFirstPhoneAsPrimary(contactType);
    }
  }

  public addNewContact(contactType: ContactType): void {
    const newIndex = this.maxControlNumberOfContactTypes[contactType];
    this.addControlForNewContact(contactType, '', contactType === ContactType.Phone && !this.hasPrimaryPhone, newIndex);
    this.customerContactsInfoArray[contactType].push({isOpen: true, isNew: true, controlIndex: newIndex});
  }

  public deleteContact(contactType: ContactType, controlIndex: number, contactIndex: number): void {
    this.customerContactsInfoArray[contactType].splice(contactIndex, 1);
    if (contactType === ContactType.Phone && this.formControls[`isPrimary${contactType}${controlIndex}`]
      && this.formControls[`isPrimary${contactType}${controlIndex}`].value) {
      if (this.customerContactsInfoArray.phone.length) {
        this.setFirstPhoneAsPrimary(contactType);
      } else {
        this.hasPrimaryPhone = false;
      }
    }
    this.removeContactControl(contactType, controlIndex, contactIndex);
    this.profileForm.markAsDirty();
  }

  public disableSave(): boolean {
    return this.profileForm.invalid || this.isSaving || !this.hasCustomerBeenChanged() || this.arePhonesInvalid();
  }

  public getContactTitle(controlIndex: number, contactType: ContactType): string {
    const contactValue = this.formControls[contactType + controlIndex] && this.formControls[contactType + controlIndex].value;
    if (!contactValue) {
      return this.translateService.instant( `INBOX.CURRENT_CONVERSATION.INFO.CUSTOMER_FORM.NEW_${contactType}`.toUpperCase());
    }
    return contactType === ContactType.Phone ? this.customerNumberPipe.transform(contactValue) : contactValue;
  }

  public titleIsCustom(): void {
    window.setTimeout(() => {
      this.formControls.title.setValue(this.previousCustomTitle);
      this.focusTitleInput();
    }, 0);
    this.isTitleCustom = true;
    this.formControls.titleInput.setValidators([Validators.pattern(this.regexService.maxChars)]);
    this.formControls.titleInput.updateValueAndValidity();
  }


  public titleIsChosen(): void {
    this.previousCustomTitle = this.formControls.title && this.formControls.title.value;
    this.clickSelectTitle();
    this.isTitleCustom = false;
    this.formControls.titleInput.clearValidators();
    this.formControls.titleInput.updateValueAndValidity();
  }


  public getMakePrimaryText(contactType: ContactType): string {
    return this.translateService.instant(`INBOX.CURRENT_CONVERSATION.INFO.CUSTOMER_FORM.MAKE_PRIMARY_${contactType.toUpperCase()}`);
  }

  public toggleHideSlot(contactType: ContactType): void {
    this.hideSlot[contactType] = !this.hideSlot[contactType];
  }

  public addContactTootip(contactType: ContactType): string {
    return this.translateService.instant(`INBOX.CURRENT_CONVERSATION.INFO.CUSTOMER_FORM.ADD_${contactType.toUpperCase()}`);
  }

  public getHeadText(contactType: ContactType): string {
    const translatedContactType = this.translateService.instant(
      `INBOX.CURRENT_CONVERSATION.INFO.CUSTOMER_FORM.${contactType.toUpperCase()}S`);
    return `${translatedContactType} (${this.customerContactsInfoArray[contactType].length})`;
  }

  public displayMessages(controlName: string): boolean {
    return Boolean(!this.formControls[controlName] || this.formControls[controlName].errors &&
      (this.formControls[controlName].dirty || this.formControls[controlName].touched));
  }

  public arePhonesInvalid(): boolean {
    return !this.hasPrimaryPhone;
  }

  public getWarning(contactType: ContactType, controlIndex: number): string {
    return this.formControls[contactType + controlIndex]
      && this.warningByContactValue[this.formControls[contactType + controlIndex].value] || '';
  }

  public getCustomerContactsInfoArrayForType(contactType: ContactType): ContactInfo[] {
    return this.customerContactsInfoArray[contactType];
  }

  public onUpdateContact(contactType: ContactType): void {
    const countByContactName: { [key: string]: number } = {};
    this.customerContactsInfoArray[contactType].map((contactInfo: ContactInfo) => {
      const currentValue = this.formControls[contactType + contactInfo.controlIndex]
        && this.formControls[contactType + contactInfo.controlIndex].value;
      if (currentValue) {
        countByContactName[currentValue] = countByContactName[currentValue]
          ? countByContactName[currentValue] + 1
          : 1;
        if (countByContactName[currentValue] > 1) {
          this.warningByContactValue[currentValue] = 'duplicate';
        }
      }
    });
  }

  public getTranslatedText(textLabel: string): string {
    return this.translateService.instant(`INBOX.CURRENT_CONVERSATION.INFO.CUSTOMER_FORM.${textLabel}`);
  }

  public getIsPrimary(contactType: ContactType, controlIndex: number): string {
    return this.formControls['isPrimary' + contactType + controlIndex] && this.formControls['isPrimary' + contactType + controlIndex].value;
  }

  public isContactPatternInvalid(contactType: ContactType, controlIndex: number): boolean {
    return this.hasRegexErrors(
      this.regexService[contactType === ContactType.Email ? 'email' : 'internationalPhone'],
      contactType + controlIndex
    );
  }

  public isContactLengthInvalid(contactType: ContactType, controlIndex: number): boolean {
    return contactType === ContactType.Email && this.hasRegexErrors(this.regexService.maxChars, contactType + controlIndex);
  }

  public hasRegexErrors(regexp: RegExp, controlName: string): boolean {
    return this.formControls[controlName] && !regexp.test(this.formControls[controlName].value);
  }

  private hasCustomerBeenChanged(): boolean {
    return this.profileForm.dirty;
  }

  private clickSelectTitle(): void {
    window.setTimeout(() => {
      this.selectCustomerProfileTitle.open();
    }, 0);
  }

  private errorMessageForResult(res: any): string {
    if (res.errorMessage.includes('already exists')) {
      return res.contact + ' ' + this.translatedAlert('OTHER_CUSTOMER');
    }
    if (res.errorMessage.includes('Invalid phone number')) {
      return res.contact + ' ' + this.translatedAlert('NOT_VALID');
    }
    return res.errorMessage;
  }

  private translatedAlert(text: string): string {
    return this.translateService.instant(`INBOX.CURRENT_CONVERSATION.INFO.CUSTOMER_FORM.ALERTS.${text}`);
  }

  private getForm(): FormGroup {
    return this.fb.group({
      title: new FormControl(this.formCustomer.title, []),
      titleInput: new FormControl('', []),
      firstName: new FormControl(this.formCustomer.firstName, [
        Validators.required,
        Validators.pattern(this.regexService.alphaNum),
        Validators.pattern(this.regexService.maxChars),
      ]),
      lastName: new FormControl(this.formCustomer.lastName, [
        Validators.pattern(this.regexService.alphaNum),
        Validators.pattern(this.regexService.maxChars),
      ]),
      gender: new FormControl(this.formCustomer.gender, []),
      dob: new FormControl(this.formCustomer.dob, []),
    });
  }

  private focusTitleInput(): void {
    if (this.customerProfileTitleInput && this.customerProfileTitleInput.nativeElement) {
      this.customerProfileTitleInput.nativeElement.focus();
    }
  }

  private initContactsControlForm(): void {
    this.data.customer.phones.map((phone: any, index: number) => {
      this.addControlForNewContact(ContactType.Phone, phone.phone, phone.isPrimary, index);
      if (phone.isPrimary) {
        this.hasPrimaryPhone = true;
      }
    });
    this.data.customer.emails.map((email: any, index: number) => {
      this.addControlForNewContact(ContactType.Email, email.email, email.isPrimary, index);
    });
  }

  private removeContactControl(contactType: ContactType, controlIndex: number, contactIndex: number): void {
    const controlName = contactType + controlIndex;
    this.profileForm.removeControl(controlName);
    this.profileForm.removeControl(`isPrimary${controlName}`);
  }

  private addControlForNewContact(contactType: ContactType, value: string, isPrimaryValue: boolean, index: number): void {
    this.addControl(contactType + index, value, contactType);
    this.addControl(`isPrimary${contactType}${index}`, isPrimaryValue);
    this.maxControlNumberOfContactTypes[contactType]++;
  }

  private addControl(controlName: string, defaultValue: string | boolean, contactType?: ContactType): void {
    const validators = contactType
      ? (contactType === ContactType.Email
        ? [Validators.required, Validators.pattern(this.regexService.email), Validators.pattern(this.regexService.maxChars)]
        : [Validators.required, Validators.pattern(this.regexService.internationalPhone)])
      : [];
    this.profileForm.addControl(controlName, new FormControl(defaultValue, validators));
  }

  private updateCustomerCopy(): void {
    if (this.isTitleCustom && this.formControls.titleInput || this.formControls.title) {
      this.formCustomer.title = this.isTitleCustom ? this.formControls.titleInput.value : this.formControls.title.value;
    }
    if (this.formControls.firstName) {
      this.formCustomer.firstName = this.formControls.firstName.value;
    }
    if (this.formControls.lastName) {
      this.formCustomer.lastName = this.formControls.lastName.value;
    }
    if (this.formControls.gender) {
      this.formCustomer.gender = this.formControls.gender.value;
    }
    if (this.formControls.dob) {
      this.formCustomer.dob = this.formControls.dob.value;
    }
    if (this.customerContactsInfoArray.phone.length) {
      this.formCustomer.phones = this.customerContactsInfoArray.phone.map((contactInfo: ContactInfo) => ({
        phone: this.formControls[ContactType.Phone + contactInfo.controlIndex].value,
        isPrimary: this.formControls['isPrimaryphone' + contactInfo.controlIndex].value,
      }));
    }
    if (this.customerContactsInfoArray.email.length) {
      this.formCustomer.emails = this.customerContactsInfoArray.email.map((contactInfo: ContactInfo) => ({
        email: this.formControls[ContactType.Email + contactInfo.controlIndex].value,
        isPrimary: this.formControls['isPrimaryemail' + contactInfo.controlIndex].value,
      }));
    }
  }

  private getArrayOfContacts(size: number): ContactInfo[] {
    const array: ContactInfo[] = [];
    for (let i = 0; i < size; i++) {
      array.push({isOpen: false, isNew: false, controlIndex: i});
    }
    return array;
  }

  private setFirstPhoneAsPrimary(contactType: ContactType): void {
    this.formControls[`isPrimary${contactType}${this.customerContactsInfoArray.phone[0].controlIndex}`].setValue(true);;
  }
}





