<div class="current-conversation-panel-title">
    <div class="container" [ngClass]="{ 'has-placeholder': !conversation }">
        <app-customer-image class="customer-image" *ngIf="conversation" [customer]="conversation.customer"></app-customer-image>
        <div class="title-content">
            <span class="title" [ngClass]="{ 'big': !conversation }">{{ conversation ? conversation.title : ('INBOX.CURRENT_CONVERSATION.PLACEHOLDER_TITLE' | translate) }}</span>
            <app-expire-info class="expire-info" *ngIf="displayExpireInfo()" [expireHours]="conversation!.expireTime"></app-expire-info>
            <div class="subtitle" *ngIf="displayCallInfo()">
                <span class="subtitle-label">{{ getCallInfoLabel() }}<span class="subtitle-label-bold">&nbsp;{{ getCallInfoClient() }}</span></span>
            </div>
            <div class="subtitle" *ngIf="displayWhatsAppClosedInfo()">
                <span class="subtitle-label"><span class="subtitle-label-bold">{{ getWhatsAppInfoLabel() }}</span>&nbsp;{{ getWhatsAppInfoClosingAgent() }}</span>
            </div>
        </div>

        <div class="buttons" *ngIf="conversation">
            <app-icon-text-button *ngIf="shouldDisplayTransfer()" [matMenuTriggerFor]="transferMenu" (menuOpened)="transferDialogOpened()" [iconPath]="transferIcon.label" [text]="'INBOX.CURRENT_CONVERSATION.LOGS.TRANSFER_BUTTON' | translate" additionalIcon="arrow_drop_down" id="panel-title-transfer-button"></app-icon-text-button>
            <app-icon-text-button *ngIf="!isAgentOutboundRoaming()" (click)="call()" icon="call" [text]="'INBOX.CURRENT_CONVERSATION.LOGS.CALL_BUTTON' | translate" [isPrimary]="isCallPrimaryAction()" id="panel-title-call-button"></app-icon-text-button>
            <app-icon-text-button *ngIf="isAgentOutboundRoaming()" (click)="roamingCall()" icon="speaker_phone" [text]="'INBOX.CURRENT_CONVERSATION.LOGS.ROAMING_CALL_BUTTON' | translate" [isPrimary]="isCallPrimaryAction()" id="panel-title-roaming-call-button"></app-icon-text-button>
            <app-icon-text-button *ngIf="shouldDisplayClose()" (click)="closeConversation()" icon="check" [text]="'INBOX.CURRENT_CONVERSATION.LOGS.CLOSE_BUTTON' | translate" [isPrimary]="true" id="panel-title-close-button"></app-icon-text-button>

            <mat-menu #transferMenu="matMenu" class="transfer-menu">
                <app-transfer-dialog [conversation]="conversation" [agentsList]="agentsList" [isLoading]="isAgentsListLoading" [displayAgentsListErrorPlaceholder]="failedLoadingAgentsList" (click)="stopPropagation($event)" (refreshAgentsList)="refreshAgentsList()"></app-transfer-dialog>
            </mat-menu>
        </div>
    </div>
</div>
