<mat-toolbar class="administration-toolbar">
    <div class="tools administration-toolbar-main">
      <span class="administration-toolbar-main-title">
          <span>{{ tableTitle | translate }}</span>
        <span class="administration-toolbar-main-subtitle">
            <span>{{ subtitle | translate }}</span>&nbsp;{{ currentCount }}&nbsp;<span>{{ of | translate }}&nbsp;</span>
            <app-count-placeholder [count]="totalCount" (getCount)="getCount.emit()"></app-count-placeholder>
        </span>
      </span>
    </div>
</mat-toolbar>
