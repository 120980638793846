<div class="current-conversation-panel-logs" *ngIf="conversation">
    <app-more-unreads-button class="more-unreads-button" [isPreviousMessages]="true" *ngIf="displayPreviousMessagesButton()" (click)="scrollToPreviousMessages()" id="scroll-to-previous-messages"></app-more-unreads-button>
    <div class="container">
        <div class="progress-bar-container" *ngIf="conversation.areLogsLoading">
            <mat-progress-bar mode="indeterminate"></mat-progress-bar>
        </div>
        <div class="conversation-logs" [ngClass]="{ 'no-messenger': !shouldDisplayMessenger() }" (scroll)="onScroll($event)" #conversationLogs>
            <div class="back-to-conversation" *ngIf="conversation.isHistory" (click)="backToMainConversation()" id="back-to-main-conversation">
                <mat-icon class="back-to-conversation-icon">arrow_back</mat-icon><span class="back-to-conversation-text">{{ 'INBOX.CURRENT_CONVERSATION.LOGS.BACK' | translate }}</span>
            </div>
            <div class="logs-top-padding" [ngClass]="{ 'is-history-padding': conversation.isHistory }"></div>
            <app-current-conversation-log [log]="log" *ngFor="let log of conversation.logs | reverse; trackBy: trackById"
                [contact]="conversation | conversationContact" [customer]="conversation.customer"></app-current-conversation-log>
        </div>
        
        <app-messenger class="messenger" *ngIf="shouldDisplayMessenger()" [conversation]="conversation"></app-messenger>
    </div>
</div>