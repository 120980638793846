import { Pipe, PipeTransform } from '@angular/core';
import { InfoCategory, InfoService, RecordingsInfos } from '../services/info.service';
import { RegexService } from '../services/regex.service';

@Pipe({
  name: 'recordingFile'
})
export class RecordingFilePipe implements PipeTransform {

  constructor(
    private infoService: InfoService,
    private regexService: RegexService,
  ) { }

  public transform(recordingFileSource: string): string {
    return recordingFileSource && !this.regexService.url.test(recordingFileSource)
      ? this.infoService.getRecordingsInfos().endpoint + recordingFileSource
      : recordingFileSource;
  }

}
