import { Pipe, PipeTransform } from '@angular/core';
import { Conversation } from '../agent/inbox/conversations.service';
import { CustomerNumberPipe } from './customer-number.pipe';
import { FullnamePipe } from './fullname.pipe';

@Pipe({
  name: 'conversationContact',
  pure: false
})
export class ConversationContactPipe implements PipeTransform {

  constructor(
    private fullnamePipe: FullnamePipe,
    private customerNumberPipe: CustomerNumberPipe
  ) {
  }

  public transform(conversation: Conversation): string {
    return conversation.customer.firstName || conversation.customer.lastName
      ? this.fullnamePipe.transform(conversation.customer)
      : this.customerNumberPipe.transform(conversation.phone);
  }

}
