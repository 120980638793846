<div class="cy-date-filter" (click)="openPanel()" [title]="title">
    <mat-icon class="flex-20">date_range</mat-icon>

    <span class="cy-date-filter-value flex-100" *ngIf="!filterModel || !currentDateRange.rollingDate && !currentDateRange.from && !currentDateRange.to">
      {{ (title || 'DATE_TIME_PICKER.DATE') | translate }}
    </span>

    <div class="flex-100" *ngIf="filterModel">
      <div class="flex-100" *ngIf="currentDateRange.rollingDate" [ngSwitch]="currentDateRange.rollingDate.includes('last') && currentDateRange.rollingDate.includes('days')">
        <span class="cy-date-filter-value has-value flex-100" *ngSwitchCase="false">
          {{ getRollingLabel(currentDateRange.rollingDate) }}
        </span>
        <ng-container *ngIf="currentDateRange.from">
            <span class="cy-date-filter-value has-value flex-100" *ngSwitchCase="false">{{ currentDateRange.from | date: 'short' }}</span>
        </ng-container>

        <span class="cy-date-filter-value has-value flex-100" *ngSwitchCase="true">
          {{ getRollingLabel(currentDateRange.rollingDate) }}
        </span>
      </div>

      <div *ngIf="!currentDateRange.rollingDate">
        <span class="cy-date-filter-value has-value flex-100" *ngIf="!currentDateRange.to && currentDateRange.from">{{ 'DATE_TIME_PICKER.SINCE' | translate }}</span>
        <span class="cy-date-filter-value has-value flex-100" *ngIf="currentDateRange.from">{{ currentDateRange.from | date: 'short' }}</span>

        <span class="cy-date-filter-value has-value flex-100" *ngIf="currentDateRange.from && currentDateRange.to"> - </span>

        <span class="cy-date-filter-value has-value flex-100" *ngIf="!currentDateRange.from && currentDateRange.to">{{ 'DATE_TIME_PICKER.UNTIL' | translate }}</span>
        <span class="cy-date-filter-value has-value flex-100" *ngIf="currentDateRange.to">{{ currentDateRange.to | date: 'short' }}</span>
      </div>
    </div>
</div>