<div class="transfer-dialog-agent">
    <div class="agent-image">
        <app-customer-image [customer]="agent" [isAgent]="true" [withStatus]="true" size="small" [displayNameLetter]="false"></app-customer-image>
    </div>
    <div class="agent-name">
        {{ agent | fullname }}
    </div>
    <div class="selected-icon" *ngIf="isSelected">
        <mat-icon>check</mat-icon>
    </div>
</div>
