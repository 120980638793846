import { Component, Input, OnInit } from '@angular/core';
import { MatIconRegistry } from '@angular/material/icon';
import { DomSanitizer } from '@angular/platform-browser';

@Component({
  selector: 'app-transfer-badge',
  templateUrl: './transfer-badge.component.html',
  styleUrls: ['./transfer-badge.component.scss']
})
export class TransferBadgeComponent implements OnInit {

  @Input() public hasUnrespondedTransfer = false;
  public transferIcon = { label: 'transfer-icon', path: '/assets/images/whatsapp/transfer-icon.svg' };

  constructor(
    private matIconRegistry: MatIconRegistry,
    private domSanitizer: DomSanitizer
  ) {
    this.matIconRegistry.addSvgIcon(
      this.transferIcon.label,
      this.domSanitizer.bypassSecurityTrustResourceUrl(this.transferIcon.path)
    );
  }

  public ngOnInit(): void {
  }

}
