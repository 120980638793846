<div class="transfer-dialog administration-form">
    <mat-tab-group class="transfer-tabs">
        <mat-tab>
            <ng-template mat-tab-label>
                <div class="tab-label">
                    <span>{{ 'INBOX.CURRENT_CONVERSATION.INFO.TRANSFER_FORM.TAB_TITLE' | translate }}</span>
                </div>
            </ng-template>
            <ng-template matTabContent>
                <div class="tab-content">
                    <div class="is-available">
                        <mat-slide-toggle [(ngModel)]="availableFilter" labelPosition="before">{{ 'INBOX.CURRENT_CONVERSATION.INFO.TRANSFER_FORM.AVAILABLE' | translate }}</mat-slide-toggle>
                    </div>
                    <div class="transfer-title"><span>{{ 'INBOX.CURRENT_CONVERSATION.INFO.TRANSFER_FORM.TRANSFER_DESCRIPTION' | translate }}</span></div>
                    <div class="agents-search">
                        <app-search class="search-bar" (callback)="searchAgents($event)" [needCallback]="true" [searchText]="'INBOX.CURRENT_CONVERSATION.INFO.TRANSFER_FORM.SEARCH' | translate" inputId="transfer-search"></app-search>
                    </div>
                    <div class="transfer-title"><span>{{ 'INBOX.CURRENT_CONVERSATION.INFO.TRANSFER_FORM.AGENTS' | translate }}</span></div>
                    <div class="agents-list" [ngClass]="{ 'with-scroll': !isLoading && !displayAgentsListErrorPlaceholder && !displayAgentsListPlaceholder()}">
                        <mat-progress-bar *ngIf="isLoading" mode="indeterminate"></mat-progress-bar>
                        <div class="agents-list-content" *ngIf="!isLoading && !displayAgentsListErrorPlaceholder && !displayAgentsListPlaceholder()">
                            <app-transfer-dialog-agent [agent]="agent" [isSelected]="isAgentSelected(agent.id)" (click)="selectAgent(agent.id)" *ngFor="let agent of (agentsList | hasProperty: 'liveInfo.status' : 'Available' : availableFilter) | searchName: searchAgentsText; trackBy: trackById" id="select-agent-to-transfer"></app-transfer-dialog-agent>
                        </div>
                        <div class="agents-list-placeholder" *ngIf="!isLoading && (displayAgentsListErrorPlaceholder || displayAgentsListPlaceholder())">
                            <img [src]="getPlaceholderImageSource()" alt="Unable to load" />
                            <span class="error-title">{{ getPlaceholderTitle() }}</span>
                            <span class="error-description">{{ getPlaceholderDescription() }}</span>
                            <div class="refresh" (click)="refreshAgentsList.emit()" *ngIf="displayAgentsListErrorPlaceholder" id="refresh-agents-list">
                                <mat-icon class="refresh-icon">refresh</mat-icon>
                                <span class="refresh-text">{{ 'INBOX.CURRENT_CONVERSATION.INFO.TRANSFER_FORM.REFRESH' | translate }}</span>
                            </div>
                        </div>
                    </div>
                </div>
            </ng-template>
        </mat-tab>
    </mat-tab-group>
    
    <div class="bottom-buttons buttons-in-menu">
        <button mat-raised-button class="dialog-transfer-button" [disabled]="disableTransfer()" (click)="transfer()" id="transfer-to-agent-button">{{ 'INBOX.CURRENT_CONVERSATION.INFO.TRANSFER_FORM.TRANSFER' | translate }}</button>
    </div>
</div>
