import { Component, OnInit, Output, EventEmitter } from '@angular/core';
import { FormGroup, FormControl, Validators, AbstractControl } from '@angular/forms';
import { RegexService } from 'src/app/services/regex.service';
import { LoginService } from '../../../login.service';
import { UsernameService } from '../../../../../services/username.service';
import { AccountService } from '../../../../../services/account.service';
import { RouterService } from '../../../../../services/router.service';

@Component({
  selector: 'app-forgot-password',
  templateUrl: './forgot-password.component.html',
  styleUrls: [
    './forgot-password.component.scss',
    '../../connexion/connexion.component.scss',
    '../forgot-account/forgot-account.component.scss'
  ]
})
export class ForgotPasswordComponent implements OnInit {
  @Output() public forgotPasswordNavigation = new EventEmitter<{origin: string; email: string}>();
  public forgotPasswordForm = new FormGroup({});
  constructor(
    private regexService: RegexService,
    private router: RouterService,
    private account: AccountService,
    private login: LoginService,
    private usernameService: UsernameService,
  ) {}

  public ngOnInit(): void {
    this.forgotPasswordForm = this.buildForm();
  }

  public get formControls(): { [key: string]: AbstractControl } {
    return this.forgotPasswordForm.controls;
  }

  public onSent(): void {
    if (this.forgotPasswordForm.valid) {
      this.login.resetPassword(this.formControls.account.value, this.formControls.username.value)
        .then(() => this.forgotPasswordNavigation.emit({origin: 'forgot-password', email: this.formControls.username.value}))
        .catch(() => {});
    }
  }

  public onQuit(): void {
    this.router.navigate(['login']);
  }

  private buildForm(): FormGroup {
    return new FormGroup({
      account: new FormControl({ value: this.account.accountName || '', disabled: this.account.isOnPremise() },
        [Validators.required, Validators.pattern(this.regexService.alphaNum), Validators.maxLength(50)]),
      username: new FormControl(this.usernameService.username || '', [Validators.required, Validators.pattern(this.regexService.email)]),
    });
  }

}
