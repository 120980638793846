import { Pipe, PipeTransform } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { GeneralInfos, InfoCategory, InfoService } from '../services/info.service';

@Pipe({
  name: 'customerNumber'
})
export class CustomerNumberPipe implements PipeTransform {

  constructor(
    private translateService: TranslateService,
    private infoService: InfoService,
  ) {}

  public transform(number: string): string {
    return this.infoService.getGeneralInfos().maskPhoneNumbers
      ? this.translateService.instant('PIPES.MASKED')
      : number;
  }
}
