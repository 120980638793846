import { Component, Input, OnInit } from '@angular/core';
import { MatIconRegistry } from '@angular/material/icon';
import { DomSanitizer } from '@angular/platform-browser';
import { TranslateService } from '@ngx-translate/core';
import { DialpadCoreService } from 'src/app/services/dialpad-core.service';
import { InfoboxService } from '../../../../../components/infobox/infobox.service';
import { Route, StatusBarService } from '../../../../../components/status-bar/status-bar.service';
import { CustomerNumberPipe } from '../../../../../pipes/customer-number.pipe';
import { FullnamePipe } from '../../../../../pipes/fullname.pipe';
import { CurrentUserService } from '../../../../../services/current-user.service';
import { Conversation, ConversationCategory, ConversationType } from '../../../conversations.service';
import { InboxService } from '../../../inbox.service';

@Component({
  selector: 'app-current-conversation-panel-title',
  templateUrl: './current-conversation-panel-title.component.html',
  styleUrls: ['./current-conversation-panel-title.component.scss']
})
export class CurrentConversationPanelTitleComponent implements OnInit {

  @Input() public conversation?: Conversation;
  public transferIcon = { label: 'transfer-icon', path: '/assets/images/whatsapp/transfer-icon.svg' };
  public agentsList: any[] = [];
  public isAgentsListLoading = false;
  public failedLoadingAgentsList = false;

  constructor(
    protected translateService: TranslateService,
    private matIconRegistry: MatIconRegistry,
    private domSanitizer: DomSanitizer,
    private infobox: InfoboxService,
    private currentUserService: CurrentUserService,
    private dialpadCoreService: DialpadCoreService,
    private customerNumberPipe: CustomerNumberPipe,
    private fullnamePipe: FullnamePipe,
    private inboxService: InboxService,
    private statusBarService: StatusBarService,
  ) {
    this.matIconRegistry.addSvgIcon(
      this.transferIcon.label,
      this.domSanitizer.bypassSecurityTrustResourceUrl(this.transferIcon.path)
    );
  }

  public ngOnInit(): void {
  }

  public isAgentOutboundRoaming(): boolean {
    return Boolean(this.currentUserService.get().outboundRoaming);
  }

  public call(): void {
    if (this.conversation && this.dialpadCoreService.client) this.dialpadCoreService.client.startCall(this.conversation.phone);
  }

  public roamingCall(): void {
    if (this.conversation && this.dialpadCoreService.client) {
      this.dialpadCoreService.client.startClick2Call(this.conversation.phone, true).then(() => {
        this.statusBarService.showDialpad = true;
        this.statusBarService.emitNavigationEventToDialpad(Route.Roaming);
      });
    }
  }

  public closeConversation(): void {
    if (!this.conversation) {
      return;
    }
    this.conversation.close().then((res: any) => {
      if (!res.result) {
        this.infobox.error(res.errorMessage);
      }
    }).catch(() => {});
  }

  public shouldDisplayClose(): boolean {
    return this.conversation && this.conversation.status === ConversationCategory.New
      ? this.isConversationExpired()
      : this.canConversationBeMovedByUser();
  }

  public shouldDisplayTransfer(): boolean {
    return this.canConversationBeMovedByUser();
  }

  public stopPropagation(event: any): void {
    event.stopPropagation();
  }

  public transferDialogOpened(): void {
    const transferSearchElement = document.getElementById('transfer-search');
    if (transferSearchElement) {
      transferSearchElement.focus();
    }
    this.refreshAgentsList();
  }

  public refreshAgentsList(): void {
    if (!this.conversation || this.isAgentsListLoading) return;
    this.isAgentsListLoading = true;
    this.conversation.getAgentsForTransfer().then((res: any) => {
      if (res.result) {
        this.agentsList = res.content;
        this.failedLoadingAgentsList = false;
      } else {
        this.failedLoadingAgentsList = true;
      }
      this.isAgentsListLoading = false;
    }).catch(() => {
      this.isAgentsListLoading = false;
      this.failedLoadingAgentsList = true;
    });
  }

  public isCallPrimaryAction(): boolean {
    return !this.shouldDisplayTransfer() && !this.shouldDisplayClose();
  }

  public displayExpireInfo(): boolean {
    return Boolean(this.conversation && !this.displayCallInfo() && !this.displayWhatsAppClosedInfo());
  }

  public displayCallInfo(): boolean {
    return Boolean(this.conversation && this.conversation.type === ConversationType.Call);
  }

  public displayWhatsAppClosedInfo(): boolean {
    return Boolean(this.conversation
      && this.conversation.type === ConversationType.WhatsApp
      && this.conversation.status === ConversationCategory.Closed);
  }

  public getCallInfoLabel(): string {
    const direction = this.conversation && this.conversation.info.direction === 'outbound' ? 'TO' : 'FROM';
    return this.translateService.instant(`INBOX.CURRENT_CONVERSATION.CALL_LABEL_${direction}`);
  }

  public getCallInfoClient(): string {
    return this.conversation ? this.customerNumberPipe.transform(this.conversation.phone) : '';
  }

  public getWhatsAppInfoLabel(): string {
    return this.translateService.instant('INBOX.CURRENT_CONVERSATION.WHATSAPP_LABEL_CLOSED');
  }

  public getWhatsAppInfoClosingAgent(): string {
    return this.conversation ? this.fullnamePipe.transform(this.inboxService.getAgentsByIds()[this.conversation.userId]) : '';
  }

  private canConversationBeMovedByUser(): boolean {
    return Boolean(
      this.conversation
      && !this.conversation.isHistory
      && this.conversation.status !== ConversationCategory.Closed
      && this.conversation.type !== ConversationType.Call
    );
  }

  private isConversationExpired(): boolean {
    return Boolean(
      this.conversation
      && this.conversation.expireTime !== null
      && this.conversation.expireTime <= 0
    );
  }
}
