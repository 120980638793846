import { DatePipe } from '@angular/common';
import { Pipe, PipeTransform } from '@angular/core';
import { InfoCategory, InfoService, TimezoneInfos } from '../services/info.service';

@Pipe({
  name: 'tzTime'
})
export class TzTimePipe implements PipeTransform {

  public timezone!: any;

  constructor(
    private datePipe: DatePipe,
    private infoService: InfoService,
  ) {
    this.timezone = this.infoService.getTimezoneInfos();
  }

  public transform(date?: string | Date, seconds: boolean = true, filterTimezone: any = this.timezone): string {
    return date && this.datePipe.transform(date, seconds ? 'mediumTime' : 'shortTime', filterTimezone.offset) || '';
  }

}
