<form [formGroup]="inputFilterForm">
  <mat-form-field [title]="placeholderLabel | translate">
    <mat-label class="text-input-filter">{{ placeholderLabel | translate }}</mat-label>
    <input matInput type="text" formControlName="inputValue" (input)="formatFilter()">
  </mat-form-field>
  <div *ngIf="formControls.inputValue.errors && (formControls.inputValue.dirty || formControls.inputValue.touched)" class="text-danger">
    <span *ngIf="formControls.inputValue.errors.pattern && filterName === 'phone'">{{ 'FILTERS.INPUT.PATTERN_PHONE' | translate }}</span>
    <span *ngIf="formControls.inputValue.errors.pattern && filterName !== 'phone'">{{ 'FILTERS.INPUT.PATTERN_ALPHANUM' | translate }}</span>
    <span *ngIf="formControls.inputValue.errors.maxlength">{{ 'FILTERS.INPUT.MAX_LENGTH' | translate }}</span>
  </div>
</form>
