/* eslint-disable */
import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import { HttpClientModule, HttpClient } from '@angular/common/http';
import { TranslateModule, TranslateLoader } from '@ngx-translate/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { OverlayContainer } from '@angular/cdk/overlay';
import { HttpLoaderFactory } from '../translate-loader';
import { MatNativeDateModule, MAT_DATE_LOCALE } from '@angular/material/core';
import { MatButtonModule } from '@angular/material/button';
import { MatDividerModule } from '@angular/material/divider';
import { MatListModule } from '@angular/material/list';
import { MatIconModule } from '@angular/material/icon';
import { MatInputModule } from '@angular/material/input';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { MatSelectModule } from '@angular/material/select';
import { MatTooltipModule } from '@angular/material/tooltip';
import { MatSliderModule } from '@angular/material/slider';
import { MatBottomSheetModule } from '@angular/material/bottom-sheet';
import { MatTabsModule } from '@angular/material/tabs';
import { MatSlideToggleModule } from '@angular/material/slide-toggle';
import { MatMenuModule } from '@angular/material/menu';
import { MatToolbarModule } from '@angular/material/toolbar'; 
import { MatRadioModule } from '@angular/material/radio';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatCardModule } from '@angular/material/card';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatProgressBarModule } from '@angular/material/progress-bar';
import { MatDialogModule } from '@angular/material/dialog';
import { ZiwoDialpadModule } from 'ziwo-dialpad';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { LoginComponent } from './public/login/login.component';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { LoaderComponent } from './components/loader/loader.component';
import { InfoboxComponent } from './components/infobox/infobox.component';
import { AdminComponent } from './admin/admin.component';
import { AgentComponent } from './agent/agent.component';
import { HomeComponent } from './pages/home/home.component';
import { DashboardComponent } from './admin/dashboard/dashboard.component';
import { CallListComponent } from './agent/call-list/call-list.component';
import { ZiwoLogoComponent } from './components/ziwo-logo/ziwo-logo.component';
import { UserPictureComponent } from './components/user-picture/user-picture.component';
import { StatusBarComponent } from './components/status-bar/status-bar.component';
import { InboxComponent } from './agent/inbox/inbox.component';
import { ConversationsListComponent } from './agent/inbox/conversations-list/conversations-list.component';
import { ConversationComponent } from './components/conversation/conversation.component';
import { ListOfConversationsComponent } from './agent/inbox/conversations-list/list-of-conversations/list-of-conversations.component';
import { ConversationChannelComponent } from './components/conversation-channel/conversation-channel.component';
import { ChannelComponent } from './components/channel/channel.component';
import { TzDatePipe } from './pipes/tz-date.pipe';
import { TzTimePipe } from './pipes/tz-time.pipe';
import { TzDateTimePipe } from './pipes/tz-date-time.pipe';
import { ExpireInfoComponent } from './components/conversation/expire-info/expire-info.component';
import { TransferBadgeComponent } from './components/conversation/transfer-badge/transfer-badge.component';
import { NotificationComponent } from './components/notification/notification.component';
import { AttachmentBadgeComponent } from './components/conversation/attachment-badge/attachment-badge.component';
import { ReadAndUnreadListsComponent } from './agent/inbox/conversations-list/read-and-unread-lists/read-and-unread-lists.component';
import { ConversationContactPipe } from './pipes/conversation-contact.pipe';
import { FullnamePipe } from './pipes/fullname.pipe';
import { CustomerNumberPipe } from './pipes/customer-number.pipe';
import { NoConversationPlaceholderComponent } from './agent/inbox/conversations-list/no-conversation-placeholder/no-conversation-placeholder.component';
import { CurrentConversationComponent } from './agent/inbox/current-conversation/current-conversation.component';
import { CurrentConversationPanelComponent } from './agent/inbox/current-conversation/current-conversation-panel/current-conversation-panel.component';
import { CurrentConversationInfoComponent } from './agent/inbox/current-conversation/current-conversation-info/current-conversation-info.component';
import { MoreUnreadsButtonComponent } from './agent/inbox/more-unreads-button/more-unreads-button.component';
import { CurrentConversationPanelTitleComponent } from './agent/inbox/current-conversation/current-conversation-panel/current-conversation-panel-title/current-conversation-panel-title.component';
import { CurrentConversationPanelPlaceholderComponent } from './agent/inbox/current-conversation/current-conversation-panel/current-conversation-panel-placeholder/current-conversation-panel-placeholder.component';
import { CurrentConversationPanelLogsComponent } from './agent/inbox/current-conversation/current-conversation-panel/current-conversation-panel-logs/current-conversation-panel-logs.component';
import { MessengerComponent } from './agent/inbox/current-conversation/current-conversation-panel/current-conversation-panel-logs/messenger/messenger.component';
import { CurrentConversationLogComponent } from './agent/inbox/current-conversation/current-conversation-panel/current-conversation-panel-logs/current-conversation-log/current-conversation-log.component';
import { CustomerImageComponent } from './components/customer-image/customer-image.component';
import { IconTextButtonComponent } from './components/icon-text-button/icon-text-button.component';
import { TextWithLinksComponent } from './components/text-with-links/text-with-links.component';
import { LogTimestampComponent } from './components/log-timestamp/log-timestamp.component';
import { LogStatusComponent } from './components/log-status/log-status.component';
import { AttachmentComponent } from './components/attachment/attachment.component';
import { ConversationLogRecordingComponent } from './components/conversation-log-recording/conversation-log-recording.component';
import { FileIconComponent } from './components/attachment/file-icon/file-icon.component';
import { ConversationContactComponent } from './components/conversation-contact/conversation-contact.component';
import { SecondsToTimePipe } from './pipes/seconds-to-time.pipe';
import { RecordingFilePipe } from './pipes/recording-file.pipe';
import { CurrentConversationInfoTitleComponent } from './agent/inbox/current-conversation/current-conversation-info/current-conversation-info-title/current-conversation-info-title.component';
import { ExpendButtonComponent } from './components/expend-button/expend-button.component';
import { AudioComponent } from './components/audio/audio.component';
import { SearchComponent } from './components/search/search.component';
import { CustomerProfileDialogComponent } from './agent/inbox/current-conversation/current-conversation-info/customer-profile-dialog/customer-profile-dialog.component';
import { TransferDialogComponent } from './agent/inbox/current-conversation/current-conversation-panel/transfer-dialog/transfer-dialog.component';
import { TransferDialogAgentComponent } from './agent/inbox/current-conversation/current-conversation-panel/transfer-dialog/transfer-dialog-agent/transfer-dialog-agent.component';
import { ConversationsTableModuleTableComponent } from './components/conversations/conversations-table-module/conversations-table-module-table/conversations-table-module-table.component';
import { ConversationsTableModuleTitleComponent } from './components/conversations/conversations-table-module/conversations-table-module-title/conversations-table-module-title.component';
import { ConversationsTableModuleFiltersComponent } from './components/conversations/conversations-table-module/conversations-table-module-filters/conversations-table-module-filters.component';
import { ConversationsTableModuleTableRowComponent } from './components/conversations/conversations-table-module/conversations-table-module-table/conversations-table-module-table-row/conversations-table-module-table-row.component';
import { CountPlaceholderComponent } from './components/conversations/conversations-table-module/conversations-table-module-title/count-placeholder/count-placeholder.component';
import { ConversationsTableModuleTableTitleComponent } from './components/conversations/conversations-table-module/conversations-table-module-table/conversations-table-module-table-title/conversations-table-module-table-title.component';
import { ConversationInformationComponent } from './components/conversations/conversations-table-module/conversations-table-module-table/conversation-information/conversation-information.component';
import { ConversationLogComponent } from './components/conversations/conversations-table-module/conversations-table-module-table/conversation-information/conversation-log/conversation-log.component';
import { ConversationDetailsComponent } from './components/conversations/conversations-table-module/conversations-table-module-table/conversation-information/conversation-details/conversation-details.component';
/* eslint-enable */
import { ReversePipe } from './pipes/reverse.pipe';
import { LoginLeftPaneComponent } from './public/login/login-left-pane/login-left-pane.component';
import { LoginRightPaneComponent } from './public/login/login-right-pane/login-right-pane.component';
import { ConnexionComponent } from './public/login/login-right-pane/connexion/connexion.component';
import { PasswordInputComponent } from './components/password-input/password-input.component';
import { TextButtonComponent } from './components/text-button/text-button.component';
import { SetPasswordComponent } from './public/login/login-right-pane/set-password/set-password.component';
import { ForgotAccountPasswordComponent } from './public/login/login-right-pane/forgot-account-password/forgot-account-password.component';
import { WhatAccountComponent } from './public/login/login-right-pane/forgot-account-password/what-account/what-account.component';
import { ForgotAccountComponent } from './public/login/login-right-pane/forgot-account-password/forgot-account/forgot-account.component';
import { ForgotPasswordComponent } from './public/login/login-right-pane/forgot-account-password/forgot-password/forgot-password.component';
import { CheckInboxComponent } from './public/login/login-right-pane/forgot-account-password/check-inbox/check-inbox.component';
import { PasswordCheckComponent } from './components/password-input/password-check/password-check.component';
import { LoadingScreenComponent } from './components/loading-screen/loading-screen.component';
import { ConversationPreviewPipe } from './pipes/conversation-preview.pipe';
import { HasPropertyPipe } from './pipes/has-property.pipe';
import { SearchNamePipe } from './pipes/search-name.pipe';
import { OfflineAlertComponent } from './components/offline-alert/offline-alert.component';
import { ProfileComponent } from './components/profile/profile.component';
import { ImageCropperModule } from 'ngx-image-cropper';
import { CopyInfoComponent } from './components/copy-info/copy-info.component';
import { ClipboardModule } from '@angular/cdk/clipboard';
import { EnumFilterComponent } from './filters/enum-filter/enum-filter.component';
import { InputFilterComponent } from './filters/input-filter/input-filter.component';
import { DateTimePickerComponent } from './filters/date-time-picker/date-time-picker.component';
import { AgentsFilterComponent } from './filters/agents-filter/agents-filter.component';
import { AlertComponent } from './components/alert/alert.component';
import { OnEndScrollDirective } from './directives/on-end-scroll.directive';
import { NavigationButtonComponent } from './components/navigation-button/navigation-button.component';
import { DateTimePickerPanelComponent } from './filters/date-time-picker/date-time-picker-panel/date-time-picker-panel.component';
import { FocusOnDirective } from './directives/focus-on.directive';
import { DateTimePickerInputComponent } from './filters/date-time-picker/date-time-picker-input/date-time-picker-input.component';
import { ConversationsComponent } from './components/conversations/conversations.component';

@NgModule({
  declarations: [
    AppComponent,
    LoginComponent,
    LoaderComponent,
    InfoboxComponent,
    AdminComponent,
    AgentComponent,
    HomeComponent,
    DashboardComponent,
    CallListComponent,
    ZiwoLogoComponent,
    UserPictureComponent,
    StatusBarComponent,
    InboxComponent,
    ConversationsListComponent,
    ConversationComponent,
    ListOfConversationsComponent,
    ConversationChannelComponent,
    ChannelComponent,
    TzDatePipe,
    TzTimePipe,
    TzDateTimePipe,
    ExpireInfoComponent,
    TransferBadgeComponent,
    NotificationComponent,
    AttachmentBadgeComponent,
    ReadAndUnreadListsComponent,
    ConversationContactPipe,
    FullnamePipe,
    CustomerNumberPipe,
    NoConversationPlaceholderComponent,
    CurrentConversationComponent,
    CurrentConversationPanelComponent,
    CurrentConversationInfoComponent,
    MoreUnreadsButtonComponent,
    CurrentConversationPanelTitleComponent,
    CurrentConversationPanelPlaceholderComponent,
    CurrentConversationPanelLogsComponent,
    MessengerComponent,
    CurrentConversationLogComponent,
    CustomerImageComponent,
    IconTextButtonComponent,
    TextWithLinksComponent,
    LogTimestampComponent,
    LogStatusComponent,
    AttachmentComponent,
    ConversationLogRecordingComponent,
    FileIconComponent,
    ConversationContactComponent,
    SecondsToTimePipe,
    RecordingFilePipe,
    CurrentConversationInfoTitleComponent,
    ExpendButtonComponent,
    AudioComponent,
    SearchComponent,
    CustomerProfileDialogComponent,
    TransferDialogComponent,
    TransferDialogAgentComponent,
    ReversePipe,
    LoginLeftPaneComponent,
    LoginRightPaneComponent,
    ConnexionComponent,
    PasswordInputComponent,
    TextButtonComponent,
    SetPasswordComponent,
    ForgotAccountPasswordComponent,
    WhatAccountComponent,
    ForgotAccountComponent,
    ForgotPasswordComponent,
    CheckInboxComponent,
    PasswordCheckComponent,
    LoadingScreenComponent,
    ConversationPreviewPipe,
    HasPropertyPipe,
    SearchNamePipe,
    OfflineAlertComponent,
    ProfileComponent,
    CopyInfoComponent,
    ConversationsComponent,
    ConversationsTableModuleTitleComponent,
    ConversationsTableModuleFiltersComponent,
    ConversationsTableModuleTableComponent,
    ConversationsTableModuleTableTitleComponent,
    ConversationsTableModuleTableRowComponent,
    CountPlaceholderComponent,
    EnumFilterComponent,
    InputFilterComponent,
    DateTimePickerComponent,
    AgentsFilterComponent,
    AlertComponent,
    OnEndScrollDirective,
    ConversationInformationComponent,
    ConversationDetailsComponent,
    ConversationLogComponent,
    NavigationButtonComponent,
    DateTimePickerPanelComponent,
    FocusOnDirective,
    DateTimePickerInputComponent,
  ],
  imports: [
    HttpClientModule,
    BrowserModule,
    AppRoutingModule,
    BrowserAnimationsModule,
    ReactiveFormsModule,
    FormsModule,
    ClipboardModule,
    TranslateModule.forRoot({
      defaultLanguage: 'en',
      loader: {
        provide: TranslateLoader,
        useFactory: HttpLoaderFactory,
        deps: [HttpClient]
      }
    }),
    MatNativeDateModule,
    MatTabsModule,
    MatMenuModule,
    MatToolbarModule,
    MatBottomSheetModule,
    MatSlideToggleModule,
    MatButtonModule,
    MatDividerModule,
    MatListModule,
    MatCheckboxModule,
    MatFormFieldModule,
    MatProgressBarModule,
    MatDialogModule,
    MatSliderModule,
    MatRadioModule,
    MatCardModule,
    MatDatepickerModule,
    MatSelectModule,
    MatIconModule,
    MatInputModule,
    MatProgressSpinnerModule,
    MatTooltipModule,
    ImageCropperModule,
    ZiwoDialpadModule,
  ],
  providers: [
    {provide: OverlayContainer, useClass: OverlayContainer},
    {provide: MAT_DATE_LOCALE, useValue: navigator.languages && navigator.languages[0] || navigator.language},
    TzDatePipe,
    TzTimePipe,
    TzDateTimePipe,
    FullnamePipe,
    CustomerNumberPipe,
    ConversationContactPipe,
    SecondsToTimePipe,
    RecordingFilePipe,
    ReversePipe,
    ConversationPreviewPipe,
    SearchNamePipe,
    HasPropertyPipe,
  ],
  bootstrap: [AppComponent]
})
export class AppModule { }
