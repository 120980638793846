<div class="connexion forgot-account check-inbox">
    <form [formGroup]="checkInboxForm">
        <img alt="Account explanation" src="../../../../../assets/images/login/check-inbox.png" />
        <h1 class="title">{{ 'LOGIN.FORGOT_ACCOUNT_PASSWORD.CHECK_INBOX' | translate }}</h1>
        <div class="login-message centered-message">
            {{ ('LOGIN.FORGOT_ACCOUNT_PASSWORD.CREDENTIALS_MESSAGE_BEGIN' | translate) + this.username + 
            ((mode === 'password' ? 'LOGIN.FORGOT_ACCOUNT_PASSWORD.PASSWORD_MESSAGE' : 'LOGIN.FORGOT_ACCOUNT_PASSWORD.ACCOUNT_MESSAGE') | translate) }}</div>
            
        <div class="navigation-link">
            <div class="link-container"><span class="link" (click)="onQuit()" id="check-inbox-navigation-back"><mat-icon class="link-icon">arrow_back</mat-icon>{{ 'LOGIN.FORGOT_ACCOUNT_PASSWORD.BACK_LOGIN' | translate }}</span></div>
        </div>
    </form>
</div>