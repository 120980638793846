import { Component, DoCheck, Input, OnInit } from '@angular/core';
import { SecondsToTimePipe } from '../../pipes/seconds-to-time.pipe';
import { RecordingFilePipe } from '../../pipes/recording-file.pipe';
import { ConversationLog } from '../../agent/inbox/conversations.service';
import { CurrentUserService } from '../../services/current-user.service';

@Component({
  selector: 'app-conversation-log-recording',
  templateUrl: './conversation-log-recording.component.html',
  styleUrls: ['./conversation-log-recording.component.scss']
})
export class ConversationLogRecordingComponent implements OnInit, DoCheck {

  @Input() public log!: ConversationLog;
  @Input() public small = false;
  public isAgent = true;
  public recordingDuration = '';
  public recordingFile = '';

  constructor(
    private secondsToTimePipe: SecondsToTimePipe,
    private recordingFilePipe: RecordingFilePipe,
    private currentUserService: CurrentUserService,
  ) {
    this.isAgent = this.currentUserService.isAgent();
  }

  public ngOnInit(): void {
    if (this.log.content.endedAt) {
      this.setRecordingFile();
    } else {
      this.ngDoCheck = (): void => this.checkForCallEnd();
    }
    this.recordingDuration = this.secondsToTimePipe.transform(this.log.content.queueTalkTime || this.log.content.talkTime, true);
  }

  public ngDoCheck(): void {
  }

  private checkForCallEnd(): void {
    if (this.log.content.endedAt) {
      this.setRecordingFile();
      this.ngDoCheck = (): void => {};
    }
  }

  private setRecordingFile(): void {
    // eslint-disable-next-line no-bitwise
    const FLAG_HAS_RECORDING = 1 << 15;
    // eslint-disable-next-line no-bitwise
    if (FLAG_HAS_RECORDING & this.log.content.flags) {
      this.recordingFile = this.recordingFilePipe.transform(this.log.content.callID + '.mp3');
    }
  }

}
