<div class="login-left-pane {{ dayTimeCssClass }}">
    <div class="cloud"></div>
    
    <div class="flying-ziwo">
      <img src="/assets/images/login/flying-ziwo.svg" alt="Flying Ziwo" />
    </div>

    <h1 class="title">{{ 'LOGIN.SIMPLY' | translate }}</h1>

    <img class="cityline" src="/assets/images/login/cityline.svg" alt="Cloud banner" />
</div>