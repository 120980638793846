import { Injectable } from '@angular/core';
import { CanActivate, Router, UrlTree } from '@angular/router';
import { CurrentUserService } from 'src/app/services/current-user.service';
import { LoginService } from './login.service';

@Injectable({
  providedIn: 'root'
})
export class LoginGuardService implements CanActivate {

  constructor(
    private loginService: LoginService,
    private currentUserService: CurrentUserService,
    private router: Router,
  ) { }
  public async canActivate(): Promise<boolean | UrlTree> {
    if (!(await this.loginService.isLoggedIn())) return true;
    this.loginService.goToLoggedApp();
    return false;
    // TO DO: Replace above 2 lines with the one below, once admin side of the app is implemented
    // return this.router.parseUrl(this.currentUserService.isAgent() ? 'agent' : 'admin');
  }
}

