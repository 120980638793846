import { Directive } from '@angular/core';
import { MatIconRegistry } from '@angular/material/icon';
import { DomSanitizer } from '@angular/platform-browser';
import { TranslateService } from '@ngx-translate/core';
import _ from 'lodash';
import { LoginService } from '../../public/login/login.service';
import { CurrentUserService } from '../../services/current-user.service';
import { RouterService } from '../../services/router.service';

export interface SideMenu {
  items: SideMenuItem[];
  /* set icon & route to "" (empty string) in an item to define a new section title */
  more: SideMenuItem[];
}

export interface SideMenuItem {
  icon?: string;
  iconPath?: string;
  title: string;
  route: string;
}

@Directive()
export abstract class LayoutComponent {

  public currentUser = this.currentUserService.get();
  public menu : SideMenu = this.getMenu();
  public iconsByLabel = {
    conversation: { label: 'conversation', iconPath: '/assets/images/whatsapp/conversation.svg' },
  };

  constructor(
    public currentUserService: CurrentUserService,
    protected router: RouterService,
    protected translateService: TranslateService,
    protected loginService: LoginService,
    protected matIconRegistry: MatIconRegistry,
    protected domSanitizer: DomSanitizer,
  ) {
    _.map(this.iconsByLabel, (icon:any) => {
      if (!icon.iconPath) {
        return;
      }
      this.matIconRegistry.addSvgIcon(
        icon.label,
        this.domSanitizer.bypassSecurityTrustResourceUrl(icon.iconPath)
      );
    });
  }

  public logout(): void {
    this.loginService.logout();
  }

  public isCurrentRoute(route: string): boolean {
    return window.location.pathname.includes(route);
  }

  public abstract getProfileRoute(): string;

  protected abstract getMenu(): SideMenu;
}
