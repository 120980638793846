import { Injectable, EventEmitter } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';

export enum InfoboxType {
  Info = 'info',
  Error = 'error',
}

export interface InfoboxData {
  type: InfoboxType;
  content: string;
  delay?: number;
  className?: string;
}

@Injectable({
  providedIn: 'root'
})
export class InfoboxService {

  public pushEvent = new EventEmitter<InfoboxData|undefined>();

  constructor(
    private translate: TranslateService,
  ) { }

  public shortInfo(content: string, className?: string): void {
    return this.info(content, 1300, className);
  }

  public longInfo(content: string, className?: string): void {
    return this.info(content, 5000, className);
  }

  public error(content: string, delay: number = 4000): void {
    return this.push({
      type: InfoboxType.Error,
      content,
      delay
    });
  }

  public push(data: InfoboxData): void {
    this.pushInfobox(data.type, data.content, data.delay, data.className);
  }

  public clear(): void {
    this.pushEvent.emit();
  }

  public info(content: string, delay: number = 8000, className?: string): void {
    return this.push({
      type: InfoboxType.Info,
      content,
      delay,
      className
    });
  }

  private pushInfobox(type: InfoboxType, content: string, delay?: number, className?: string): void {
    this.pushEvent.emit({
      type,
      content,
      delay,
      className,
    });
  }
}
