import { Pipe, PipeTransform } from '@angular/core';
import { FullnamePipe } from './fullname.pipe';

@Pipe({
  name: 'searchName',
  pure: false
})
export class SearchNamePipe implements PipeTransform {

  constructor(
    private fullnamePipe: FullnamePipe,
  ) { }

  public transform(array: any[], searchText?: string): any[] {
    if (!searchText || !array) {
      return array;
    }
    return array.filter((object: any) =>
      this.checkIncludesText(this.fullnamePipe.transform(object), searchText)
    );
  }

  private checkIncludesText(text: string, textToCompare: string): boolean {
    return text.toLowerCase().includes(textToCompare.toLowerCase());
  }

}
