import { AfterContentChecked, ChangeDetectorRef, Component, Input, OnInit } from '@angular/core';
import moment from 'moment';
import { Conversation, ConversationsService, ConversationType } from '../../agent/inbox/conversations.service';
import { TzDateTimePipe } from '../../pipes/tz-date-time.pipe';
import { TzDatePipe } from '../../pipes/tz-date.pipe';


interface LabelByChannelInterface {
  [key: string]: string;
}
@Component({
  selector: 'app-conversation',
  templateUrl: './conversation.component.html',
  styleUrls: ['./conversation.component.scss']
})
export class ConversationComponent implements OnInit, AfterContentChecked {

  @Input() public conversation!: Conversation;
  @Input() public withStatus = false;
  @Input() public relativeDate = false;
  @Input() public withScroll = false;
  @Input() public scrollingId = '';
  @Input() public displayChannel = false;
  @Input() public size = 'medium';

  public lastInteractionAtFromNow = '';
  private labelByChannel: LabelByChannelInterface = {
    // eslint-disable-next-line @typescript-eslint/naming-convention
    WhatsApp: 'WhatsApp',
    sms: 'SMS',
    inbound: 'Inbound Call',
    outbound: 'Outbound Call',
  };

  constructor(
    private ref: ChangeDetectorRef,
    private conversationsService : ConversationsService,
    private tzDateTimePipe: TzDateTimePipe,
    private tzDatePipe: TzDatePipe,
  ) { }

  public ngOnInit(): void {
  }

  public ngAfterContentChecked(): void {
    this.setLastInteractionAtFromNow();
    this.ref.detectChanges();
  }

  public getChannelLabel(): string {
    return this.labelByChannel[this.getChannel()];
  }

  public getLastInteractionAtWithPipe(): string {
    return this.relativeDate
      ? this.tzDatePipe.transform(this.getLastInteractionAt())
      : this.tzDateTimePipe.transform(this.getLastInteractionAt());
  }

  public setLastInteractionAtFromNow(): string {
    return this.lastInteractionAtFromNow = moment(this.getLastInteractionAt()).fromNow();
  }

  public displayStatus(): boolean {
    return Boolean(this.conversation.lastMessageStatus);
  }

  public isSelectedConversation(conversation: Conversation): boolean {
    return this.conversationsService.isSelectedConversation(conversation);
  }

  private getLastInteractionAt(): string {
    return ((this.conversation.lastRepliedAt || '0') > (this.conversation.lastIncomingAt || '0')
      ? this.conversation.lastRepliedAt
      : this.conversation.lastIncomingAt)
    || this.conversation.createdAt;
  }

  private getChannel(): string {
    return this.conversation.type === ConversationType.Call
      ? this.conversation.info && this.conversation.info.direction
      : this.conversation.type;
  }

}
