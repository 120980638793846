import { Injectable } from '@angular/core';
import { Core } from './core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Endpoints } from './endpoints';
import { AccountService } from '../services/account.service';
import { StorageService } from '../storage.service';

export enum RequestMethod {
  Get = 'get',
  Post = 'post',
  Put = 'put',
  Delete = 'delete',
}

export interface ApiError {
  error?: {
    error: {message: string};
    info: {status: number};
    result: boolean;
  };
  headers?: HttpHeaders;
  message?: string;
  errorMessage?: string;
  name?: string;
  ok?: boolean;
  status: number | boolean;
  statusText?: string;
  url?: string;
}
@Injectable({
  providedIn: 'root'
})
export class ApiService extends Core {

  public readonly endpoints = new Endpoints();

  constructor(
    protected httpClient: HttpClient,
    private accountService: AccountService,
    private storage: StorageService,
  ) {
    super(httpClient);
  }

  public get accessToken(): string | null {
    return this.storage.local.getAccessToken();
  }

  public apiUrl(path:string): string {
    return `https://${this.accountService.accountName}-api.aswat.co${path}`;
  }

  public getRequest(path: string): Promise<any> {
    return this.apiRequest(RequestMethod.Get, path).catch((error: ApiError) => error);
  }

  public postRequest(path: string, body?: any): Promise<any> {
    return this.apiRequest(RequestMethod.Post, path, body).catch((error: ApiError) => error);
  }

  public putRequest(path: string, body?: any): Promise<any> {
    return this.apiRequest(RequestMethod.Put, path, body).catch((error: ApiError) => error);
  }

  public deleteRequest(path: string): Promise<any> {
    return this.apiRequest(RequestMethod.Delete, path).catch((error: ApiError) => error);
  }

  protected apiRequest(method: RequestMethod, path: string, body?: any): Promise<any> {
    if (!this.accessToken) return Promise.reject('No access token');
    const params: Array<any> = [this.apiUrl(path)];
    if ([RequestMethod.Post, RequestMethod.Put].includes(method)) {
      params.push(body || {});
    }
    // eslint-disable-next-line @typescript-eslint/naming-convention
    params.push({ headers: { access_token: this.accessToken } });
    // @ts-ignore
    return this.httpClient[method](...params).toPromise();
  }
}
