<div class="connexion what-account">
    <form [formGroup]="forgotForm" (ngSubmit)="onQuit()">
      <h1 class="title">{{ 'LOGIN.FORGOT_ACCOUNT_PASSWORD.WHAT_ACCOUNT' | translate }}</h1>
      <img alt="Account explanation" src="../../../../../assets/images/login/account-explanation.png" />
      <div class="login-message">{{ 'LOGIN.FORGOT_ACCOUNT_PASSWORD.ACCOUNT_DEFINITION' | translate }}</div>
      
      <div class="navigation-link">
        <div class="link-container"><span class="link" (click)="navigate()" id="what-account-navigation-forgot-account">{{ 'LOGIN.FORGOT_ACCOUNT' | translate }}</span></div>
      </div>
      
      <app-text-button class="login-button" [text]="'LOGIN.FORGOT_ACCOUNT_PASSWORD.THANKS' | translate" size="big" id="what-account-thanks"></app-text-button>
    </form>
</div>