import { Component, OnDestroy, OnInit } from '@angular/core';
import { LoadingScreenService } from './loading-screen.service';
import { Subscription } from 'rxjs';

@Component({
  selector: 'app-loading-screen',
  templateUrl: './loading-screen.component.html',
  styleUrls: ['./loading-screen.component.scss']
})
export class LoadingScreenComponent implements OnInit, OnDestroy {

  public showLoader = false;

  private showSub = Subscription.EMPTY;

  constructor(
    private loadingScreenService: LoadingScreenService,
  ) { }

  public ngOnInit(): void {
    this.showSub = this.loadingScreenService.showEvent.subscribe((show: boolean) => {
      this.showLoader = show;
    });
  }

  public ngOnDestroy(): void {
    this.showSub.unsubscribe();
  }

}
