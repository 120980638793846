import { Component, OnDestroy, OnInit } from '@angular/core';
import { MatIconRegistry } from '@angular/material/icon';
import { DomSanitizer } from '@angular/platform-browser';
import { TranslateService } from '@ngx-translate/core';
import { LayoutComponent, SideMenu } from '../components/layout/layout.component';
import { LoginService } from '../public/login/login.service';
import { CurrentUserService } from '../services/current-user.service';
import { DialpadCoreService } from '../services/dialpad-core.service';
import { NotificationService } from '../services/notification.service';
import { RouterService } from '../services/router.service';

@Component({
  selector: 'app-agent',
  templateUrl: '../components/layout/layout.component.html',
  styleUrls: ['./agent.component.scss', '../components/layout/layout.component.scss']
})
export class AgentComponent extends LayoutComponent implements OnInit, OnDestroy {
  private readonly agentFaviconPath = 'assets/favicon-agent.png';
  private readonly adminFaviconPath = 'assets/favicon.ico';

  constructor(
    public currentUserService: CurrentUserService,
    protected router: RouterService,
    protected translateService: TranslateService,
    protected loginService: LoginService,
    protected matIconRegistry: MatIconRegistry,
    protected domSanitizer: DomSanitizer,
    private dialpadCoreService: DialpadCoreService,
    private notificationService: NotificationService,
  ) {
    super(currentUserService, router, translateService, loginService, matIconRegistry, domSanitizer);
  }

  public ngOnInit(): void {
    this.setAgentFavicon();
    this.dialpadCoreService.init(); // REQUIRED! Set windows events for the dialpad
    this.notificationService.start();
  }

  public ngOnDestroy(): void {
    this.setAdminFavicon();
    this.notificationService.stop();
  }

  public getProfileRoute(): string {
    return '/agent/profile';
  }

  protected getMenu(): SideMenu {
    return {
      items: [
        // {
        //   icon: 'dialpad',
        //   title: this.translateService.instant('LAYOUT.TOOLTIP.DIALPAD'),
        //   route: '/agent/home',
        // },
        {
          icon: 'inbox',
          title: this.translateService.instant('LAYOUT.TOOLTIP.INBOX'),
          route: '/agent/inbox',
        },
        // {
        //   icon: 'phone',
        //   title: this.translateService.instant('LAYOUT.TOOLTIP.CALL_LIST'),
        //   route: '/agent/calls',
        // },
        {
          iconPath: 'conversation',
          title: this.translateService.instant('LAYOUT.TOOLTIP.CONVERSATIONS'),
          route: '/agent/conversations',
        }
      ],
      more: [],
    };
  }

  private setAgentFavicon(): void {
    this.getFaviconElement().href = this.agentFaviconPath;
  }

  private setAdminFavicon(): void {
    this.getFaviconElement().href = this.adminFaviconPath;
  }

  private getFaviconElement(): HTMLLinkElement {
    return document.querySelector('link[rel="icon"]') as HTMLLinkElement;
  }
}
