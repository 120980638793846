import { Component, Input, OnInit } from '@angular/core';
import { RouterService } from 'src/app/services/router.service';

@Component({
  selector: 'app-navigation-button',
  templateUrl: './navigation-button.component.html',
  styleUrls: ['./navigation-button.component.scss']
})
export class NavigationButtonComponent implements OnInit {

  @Input() public destination = '';
  @Input() public paramName = '';
  @Input() public paramValue = '';

  constructor(
    private routerService: RouterService,
  ) { }

  public ngOnInit(): void {
  }

  public goToDestination(): void {
    this.routerService.navigate([this.destination]);
    this.routerService.updateUrlIDParameter(this.paramValue, this.paramName);
  }

}
