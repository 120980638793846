import { Injectable } from '@angular/core';
import _ from 'lodash';
import { Resolve } from '@angular/router';
import { ApiService } from '../api/api.service';
import { ConfigMap } from '../classes/config-map';

export enum InfoCategory {
  General = 'general',
  Timezone = 'timezone',
  Timezones = 'timezones',
  Sms = 'sms',
  WA = 'wa',
  Recordings = 'recordings',
  Storage = 'storage',
  StorageMimeTypes = 'storageMimeTypes',
  StorageUploadSizeLimits = 'storageUploadSizeLimits',
  Interface = 'interface',
}

export interface GeneralInfos {
  maskPhoneNumbers: boolean;
}

export interface TimezoneInfos {
  id: string;
  name: string;
  offset: string;
  local?: boolean;
}

export interface SmsInfos {
  enabled: boolean;
}

export interface WAInfos {
  enabledForMe: boolean;
  conversationsLimitPerAgent: number;
}

export interface InterfaceInfos {
  oldAgentInterface: boolean;
}

export interface RecordingsInfos {
  endpoint: string;
}

export interface StorageInfos {
  endpoint: string;
}

export interface StorageMimeTypesInfos {
  audio: string[];
  dialplan: string[];
  document: string[];
  image: string[];
  ivr: string[];
  text: string[];
}

export interface StorageUploadSizeLimitsInfos {
  audio: number;
  document: number;
  image: number;
  ivr: number;
  text: number;
}

export type Infos = GeneralInfos
| TimezoneInfos
| TimezoneInfos[]
| SmsInfos
| WAInfos
| InterfaceInfos
| RecordingsInfos
| StorageInfos
| StorageMimeTypesInfos
| StorageUploadSizeLimitsInfos;

interface InfosMappings {
  url: string;
  calculation?: (content: any) => Infos;
}

export class InfoError extends Error {
  constructor(category: InfoCategory) {
    super(`Failed to fetch ${category} infos`);
  }
}

@Injectable({
  providedIn: 'root'
})
export class InfoService implements Resolve<void> {
  protected timezonesById: {[key: string]: TimezoneInfos} = {};
  protected fetchedInfos: Map<InfoCategory, Infos | null> = new Map();
  protected mappingsByCategory = new ConfigMap<InfoCategory, InfosMappings>([
    [InfoCategory.General, {
      url: '/settings/info/general'
    }],
    [InfoCategory.Timezone, {
      url: '/info',
      calculation: (content: any): TimezoneInfos => content.timezone
    }],
    [InfoCategory.Timezones, {
      url: '/static/timezones',
      calculation: (content: any): TimezoneInfos[] => this.formatedTimezones(content)
    }],
    [InfoCategory.Sms, {
      url: '/info',
      calculation: (content: { [key: string]: any; sms: SmsInfos} ): SmsInfos => content.sms
    }],
    [InfoCategory.WA, {
      url: '/settings/info/WhatsApp'
    }],
    [InfoCategory.Interface, {
      url: '/info',
      calculation: (content: any): InterfaceInfos => ({ oldAgentInterface: content.oldAgentInterface })
    }],
    // TO DO should be static service
    [InfoCategory.Recordings, {
      url: '/static/callRecordingsEndpoint',
      calculation: (content: { endpoint: string }): RecordingsInfos => content
    }],
    [InfoCategory.Storage, {
      url: '/static/storageEndpoint',
      calculation: (content: { endpoint: string }): StorageInfos => ({ endpoint: this.apiService.apiUrl(content.endpoint) })
    }],
    [InfoCategory.StorageMimeTypes, {
      url: '/static/storageMimeTypes',
    }],
    [InfoCategory.StorageUploadSizeLimits, {
      url: '/static/storageUploadSizeLimits',
    }],
  ]);

  constructor(
    private apiService: ApiService,
  ) {}

  public async resolve(): Promise<any> {
    return this.fetchInfos();
  }

  public getGeneralInfos(): GeneralInfos {
    return this.get(InfoCategory.General) as GeneralInfos;
  }

  public getTimezoneInfos(): TimezoneInfos {
    return this.get(InfoCategory.Timezone) as TimezoneInfos;
  }

  public getTimezonesInfos(): TimezoneInfos[] {
    return this.get(InfoCategory.Timezones) as TimezoneInfos[];
  }

  public getSmsInfos(): SmsInfos {
    return this.get(InfoCategory.Sms) as SmsInfos;
  }

  public getWAInfos(): WAInfos {
    return this.get(InfoCategory.WA) as WAInfos;
  }

  public getInterfaceInfos(): InterfaceInfos {
    return this.get(InfoCategory.Interface) as InterfaceInfos;
  }

  public getRecordingsInfos(): RecordingsInfos {
    return this.get(InfoCategory.Recordings) as RecordingsInfos;
  }

  public getStorageInfos(): StorageInfos {
    return this.get(InfoCategory.Storage) as StorageInfos;
  }

  public getStorageMimeTypesInfos(): StorageMimeTypesInfos {
    return this.get(InfoCategory.StorageMimeTypes) as StorageMimeTypesInfos;
  }

  public getStorageUploadSizeLimitsInfos(): StorageUploadSizeLimitsInfos {
    return this.get(InfoCategory.StorageUploadSizeLimits) as StorageUploadSizeLimitsInfos;
  }

  public getTimezoneById(timezoneId: string): TimezoneInfos {
    return this.timezonesById[timezoneId];
  }

  protected fetchInfosOfCategory(category: InfoCategory): Promise<Infos> {
    const mappingsOfCategory = this.mappingsByCategory.get(category);
    return this.apiService.getRequest(mappingsOfCategory.url)
      .then((res: any) => mappingsOfCategory.calculation ? mappingsOfCategory.calculation(res.content) : res.content)
      .catch(() => null);
  }

  private fetchInfos(): Promise<any> {
    return Promise.all(
      Object.values(InfoCategory).map(async category => this.fetchedInfos.set(category, await this.fetchInfosOfCategory(category)))
    );
  }

  private get(category: InfoCategory): Infos {
    const infos = this.fetchedInfos.get(category);
    if (!infos) throw(new InfoError(category));
    return infos;
  }

  private formatedTimezones(timezones: TimezoneInfos[]): TimezoneInfos[] {
    timezones.forEach(timezone => this.timezonesById[timezone.id] = timezone);
    return timezones;
  }
}
