import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'hasProperty',
  pure: false
})
export class HasPropertyPipe implements PipeTransform {

  public transform(array: any[], key: string, value: any, applyFilter: boolean): any[] {
    if (!applyFilter || !array) {
      return array;
    }
    const keys = key.split('.');
    return array.filter((object: any) => (keys.length >= 2 ? object[keys[0]][keys[1]] : object[keys[0]]) === value);
  }

}
