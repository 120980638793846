import { Component, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges } from '@angular/core';

@Component({
  selector: 'app-search',
  templateUrl: './search.component.html',
  styleUrls: ['./search.component.scss']
})
export class SearchComponent implements OnInit, OnChanges {


  @Input() public clearOnChanges!: any;
  @Input() public needCallback = false;
  @Input() public searchText = '';
  @Input() public inputId = 'search-input';
  @Output() public callback = new EventEmitter<string>();
  public searchInput = '';

  constructor() { }

  public ngOnInit(): void {
  }

  public ngOnChanges(changes: SimpleChanges): void {
    if (changes.clearOnChanges && this.searchInput) {
      this.searchInput = '';
    }
  }

  public search(): void {
    if (this.needCallback) {
      this.emitCallback();
    }
  }

  public clear(): void {
    this.searchInput = '';
    if (this.needCallback) {
      this.emitCallback('');
    }
  }

  private emitCallback(searchToEmit?: string): void {
    window.setTimeout(() => {
      this.callback.emit(searchToEmit === undefined ? this.searchInput : searchToEmit);
    }, 0);
  }
}
