import { Component, Inject, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { TranslateService } from '@ngx-translate/core';
import moment from 'moment';
import { DateRange, PanelDateRange } from '../date-time-picker.component';

@Component({
  selector: 'app-date-time-picker-panel',
  templateUrl: './date-time-picker-panel.component.html',
  styleUrls: ['./date-time-picker-panel.component.scss']
})
export class DateTimePickerPanelComponent implements OnInit {

  public manualRangeWarning = '';
  public rollingDateWarning = '';
  public fromDateFocus = false;
  public fromTimeFocus = false;
  public toDateFocus = false;
  public toTimeFocus = false;
  public lastNDaysFocus = false;
  public sinceRange!: string;
  public lastNDays!: number | string;
  public fromDate?: Date | string;
  public fromTime?: Date | string;
  public toDate?: Date | string;
  public toTime?: Date | string;

  constructor(
    private dialogRef: MatDialogRef<DateTimePickerPanelComponent>,
    private translateService: TranslateService,
    @Inject(MAT_DIALOG_DATA) public data: {
      currentDateRange: DateRange;
      onPanelChange: Function;
      onlyCustom: boolean;
      dateRangesColumns: any[];
      sinceDateRanges: PanelDateRange[];
      maximumDateRangeDays: number;
      noReset: boolean;
    },
  ) { }

  public ngOnInit(): void {
    if (this.data.currentDateRange.rollingDate) {
      if (this.data.currentDateRange.rollingDate.includes('since')) {
        this.sinceRange = this.data.currentDateRange.rollingDate;
      }
      if (/last(\d+)days/.test(this.data.currentDateRange.rollingDate)) {
        // This cannot be null from the date-time-picker component
        // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
        this.lastNDays = parseInt(/^last(\d+)days$/.exec(this.data.currentDateRange.rollingDate)![1], 10);
      }
    } else {
      if (this.data.currentDateRange.from) {
        this.fromDate = moment(this.data.currentDateRange.from)
          .hour(moment(this.data.currentDateRange.from).hour()).minutes(moment(this.data.currentDateRange.from).minutes()).toDate();
        this.fromTime = moment(this.data.currentDateRange.from, 'HHmm').format('HH:mm');
      }
      if (this.data.currentDateRange.to) {
        this.toDate = moment(this.data.currentDateRange.to)
          .hour(moment(this.data.currentDateRange.to).hour()).minutes(moment(this.data.currentDateRange.to).minutes()).toDate();
        this.toTime = moment(this.data.currentDateRange.to, 'HHmm').format('HH:mm');
      }
    }
  }

  public trackByIndex(index: number): number {
    return index;
  }

  public isFocused(type: string): boolean {
    if (type === 'custom') {
      return Boolean(!this.data.currentDateRange.rollingDate && (this.data.currentDateRange.from || this.data.currentDateRange.to));
    }
    if (type === 'lastNDays') return /last(\d+)days/.test(this.data.currentDateRange.rollingDate);
    if (type.includes('since')) return Boolean(this.sinceRange);
    return this.data.currentDateRange.rollingDate === type;
  }

  public onManualRangeChange(timeType: string): void {
    this.resetAllBut(['fromDate','fromTime','toDate','toTime']);

    this.checkMaxAndMinTimes(timeType);

    if (this.fromTime && !this.fromDate) this.fromDate = moment().toDate();
    if (this.toTime && !this.toDate) this.toDate = moment().toDate();

    if (this.fromDate) {
      if (!this.fromTime) this.fromTime = moment(this.fromDate).startOf('day').toDate();
      this.data.currentDateRange.from = moment(this.fromDate)
        .hour(Number(moment(this.fromTime, 'HHmm').format('HH')))
        .minutes(Number(moment(this.fromTime, 'HHmm').format('mm')))
        .seconds(Number(moment(this.fromTime, 'HHmm').format('ss'))).toDate();
    }
    if (this.toDate) {
      if (!this.toTime) this.toTime = moment(this.toDate).endOf('day').toDate();
      this.data.currentDateRange.to = moment(this.toDate)
        .hour(Number(moment(this.toTime, 'HHmm').format('HH')))
        .minutes(Number(moment(this.toTime, 'HHmm').format('mm')))
        .seconds(Number(moment(this.toTime, 'HHmm').format('ss'))).toDate();
    }

    this.checkManualRangeWarning();
    this.data.onPanelChange(this.data.currentDateRange);
  }

  public onRollingDateChange(type: string): void {
    this.resetAllBut([]);

    this.data.currentDateRange.rollingDate = type;

    this.data.onPanelChange(this.data.currentDateRange);
    this.close();
  }

  public onSinceRollingDateChange(): void {
    this.resetAllBut(['sinceRange']);

    if (!this.sinceRange) this.sinceRange = 'sinceToday';
    this.data.currentDateRange.rollingDate = this.sinceRange;
    this.data.onPanelChange(this.data.currentDateRange);
  }

  public onLastNDaysRollingDateChange(): void {
    this.resetAllBut(['lastNDays']);
    this.checkLastNDaysInRange();
    this.data.currentDateRange.rollingDate = `last${this.lastNDays}days`;
    this.data.onPanelChange(this.data.currentDateRange);
  }

  public reset(): void {
    this.data.onPanelChange({});
    this.close();
  }

  public save(): void {
    this.close();
  }

  public close(): void {
    if (this.dialogRef?.close) {
      this.dialogRef.close();
    }
  }

  public focusLastNDays(): void {
    this.lastNDaysFocus = true;
  }

  public toggleFromDateFocus(): void {
    this.fromDateFocus = !this.fromDateFocus;
  }

  public toggleFromTimeFocus(): void {
    this.fromTimeFocus = !this.fromTimeFocus;
  }

  public toggleToDateFocus(): void {
    this.toDateFocus = !this.toDateFocus;
  }

  public toggleToTimeFocus(): void {
    this.toTimeFocus = !this.toTimeFocus;
  }

  private checkLastNDaysInRange(): void {
    if (!this.lastNDays || this.lastNDays < 1) this.lastNDays = 1;
    if (this.data.maximumDateRangeDays) {
      this.rollingDateWarning = '';
      if (this.lastNDays > this.data.maximumDateRangeDays) {
        this.lastNDays = this.data.maximumDateRangeDays;
        this.rollingDateWarning =
          this.translateService.instant('DATE_TIME_PICKER.PANEL.RANGES', {maximumDateRangeDays: this.data.maximumDateRangeDays});
      }
    }
  }

  private resetAllBut(fieldsNotToReset: string[] = []): void {
    this.data.currentDateRange = { from: null, to: null, rollingDate: '' };
    ['sinceRange','lastNDays','fromDate','fromTime','toDate','toTime']
      .filter((field: string) => !fieldsNotToReset.includes(field))
      // @ts-ignore this[fieldToReset] is a string
      .forEach((fieldToReset: string) => this[fieldToReset] = '');
  }

  private checkManualRangeWarning(): void {
    if (this.data.maximumDateRangeDays) {
      const manualRangeLengthInDays = moment(this.data.currentDateRange.to).diff(this.data.currentDateRange.from, 'days');
      this.manualRangeWarning = manualRangeLengthInDays > this.data.maximumDateRangeDays
        ? this.translateService.instant('DATE_TIME_PICKER.PANEL.RANGES_MANUAL',
          {maximumDateRangeDays: this.data.maximumDateRangeDays, manualRangeLengthInDays: manualRangeLengthInDays})
        : '';
    }
  }

  private checkMaxAndMinTimes(timeType: string): void {
    if (!this.toDate || !this.fromDate || !this.toTime || !this.fromTime) return;
    const isSameDate = moment(this.toDate).isSame(moment(this.fromDate), 'day');
    if (!isSameDate) return;
    const format = 'HH:mm';
    const maxTime = this.toTime;
    const minTime = this.fromTime;
    if (timeType === 'from' && moment(maxTime, format).isBefore(moment(this.fromTime, format))) this.fromTime = maxTime;
    else if (moment(minTime, format).isAfter(moment(this.toTime, format))) this.toTime = minTime;
  }

}
