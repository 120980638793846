<div class="attachment" [ngClass]="{ 'with-remove': withRemove }">
    <app-file-icon class="file-icon" [iconType]="attachment.icon || 'default'" [noColor]="noColor"></app-file-icon>
    <div class="attachment-text" [ngClass]="{ 'with-remove': withRemove }">
        <div *ngIf="!attachment.url || !withDownload">
            <span class="attachment-text-title">{{ attachment.name }}</span>
        </div>
        <div *ngIf="attachment.url && withDownload">
            <a class="attachment-text-download" [href]="attachment.url" target="_blank" download>
                <span class="attachment-text-title">{{ attachment.name }}</span>
            </a>
        </div>
        <span class="attachment-text-date" *ngIf="displayDate">{{ attachment.createdAt | tzDateTime }}</span>
    </div>
    <div *ngIf="withRemove" class="attachment-remove" (click)="onRemove()"><mat-icon>close</mat-icon></div>
</div>