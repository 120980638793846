import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';

@Component({
  selector: 'app-table-module-title',
  templateUrl: './conversations-table-module-title.component.html',
  styleUrls: ['./conversations-table-module-title.component.scss']
})
export class ConversationsTableModuleTitleComponent implements OnInit {

  @Input() public tableTitle!: string;
  @Input() public subtitle!: string;
  @Input() public of!: string;
  @Input() public currentCount = 0;
  @Input() public totalCount?: string | number;
  @Output() public getCount = new EventEmitter();

  constructor() { }

  public ngOnInit(): void {
  }

}
