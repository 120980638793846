import { Injectable } from '@angular/core';
import moment from 'moment';

enum EndOfDayTime {
  EndOfNightHour = 6,
  EndOfDayHour = 17,
  EndOfEveningHour = 20,
}

export enum DayTimeLabel {
  Day = 'day',
  Eve = 'eve',
  Night = 'night',
}

@Injectable({
  providedIn: 'root'
})
export class DateService {

  constructor() { }

  public getDayTime(date?: string): DayTimeLabel {
    if (moment(date).isBetween({ hours: EndOfDayTime.EndOfNightHour }, { hours: EndOfDayTime.EndOfDayHour })) {
      return DayTimeLabel.Day;
    }
    if (moment(date).isBetween({ hours: EndOfDayTime.EndOfDayHour }, { hours: EndOfDayTime.EndOfEveningHour })) {
      return DayTimeLabel.Eve;
    }
    return DayTimeLabel.Night;
  }
}
