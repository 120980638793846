import { Component, Input } from '@angular/core';
import { CurrentUserService } from 'src/app/services/current-user.service';

@Component({
  selector: 'app-ziwo-logo',
  templateUrl: './ziwo-logo.component.html',
  styleUrls: ['./ziwo-logo.component.scss']
})
export class ZiwoLogoComponent {

  @Input() public diameter = '35px';
  public type = this.currentUser.get().type;

  constructor(
    private currentUser: CurrentUserService,
  ) { }

}
