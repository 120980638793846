import { AfterContentChecked, ChangeDetectorRef, Component, ElementRef, Input, OnInit, ViewChild } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import _ from 'lodash';
import { Conversation, ConversationCategory, ConversationsService } from '../../conversations.service';

export enum BoundingClientRectType {
  Top = 'top',
  Bottom = 'bottom',
}

const conversationHeight = 90;

@Component({
  selector: 'app-read-and-unread-lists',
  templateUrl: './read-and-unread-lists.component.html',
  styleUrls: ['./read-and-unread-lists.component.scss']
})
export class ReadAndUnreadListsComponent implements OnInit, AfterContentChecked {

  // @ts-ignore
  @ViewChild('unreadListContainer') public unreadListContainer: ElementRef;

  @Input() public isSearchMode = false;
  @Input() public category = '';
  public labelUnreadConvs = '';
  public displayUnreadButtons = { top: false, bottom: false };

  constructor(
    protected translateService: TranslateService,
    private conversationsService : ConversationsService,
    private ref: ChangeDetectorRef
  ) { }

  public ngOnInit(): void {
  }

  public ngAfterContentChecked(): void {
    this.setDisplayUnreadButtons();
    this.ref.detectChanges();
  }

  public getReadConversations(): Conversation[] {
    if (this.category) {
      return this.getReadConversationsByCategory(this.category);
    }
    return this.getReadConversationsAllCategories();
  }

  public getUnreadConversations(): Conversation[] {
    if (this.category) {
      return this.getUnreadConversationsByCategory(this.category);
    }
    return this.getUnreadConversationsAllCategories();
  }

  public getReadConversationsAllCategories(): Conversation[] {
    return this.conversationsService.conversationList.conversations.filter(conversation => !conversation.hasUnseen);
  }

  public getUnreadConversationsAllCategories(): Conversation[] {
    const conversations = this.conversationsService.conversationList.conversations.filter(conversation => conversation.hasUnseen);
    this.labelUnreadConvs = this.getLabelUnreadConvs(conversations.length);
    return conversations;
  }

  public getReadConversationsByCategory(category: string): Conversation[] {
    return this.conversationsService.conversationList.getConversationsOfCategory(category as ConversationCategory)
      .filter(conversation => !conversation.hasUnseen);
  }

  public getUnreadConversationsByCategory(category: string): Conversation[] {
    const conversations = this.conversationsService.conversationList.getConversationsOfCategory(category as ConversationCategory)
      .filter(conversation => conversation.hasUnseen);
    this.labelUnreadConvs = this.getLabelUnreadConvs(conversations.length);
    return conversations;
  }

  public displayNoConversationPlaceholder(): boolean {
    return !this.getReadConversations().length && !this.getUnreadConversations().length;
  }

  public scrollToTopUnread(): void {
    if (this.getUnreadConversations().length) {
      this.getUnreadConversations()[0].scrollTo();
    }
  }

  public scrollToBottomUnread(): void {
    if (this.getUnreadConversations().length) {
      _.last(this.getUnreadConversations()).scrollTo();
    }
  }

  public setDisplayUnreadButtons(): void {
    this.displayUnreadButtons.top = this.getDisplayScrollTopUnreadButton();
    this.displayUnreadButtons.bottom = this.getDisplayScrollBottomUnreadButton();
  }

  public getDisplayScrollTopUnreadButton(): boolean {
    return this.displayScrollDirectionUnreadButton(BoundingClientRectType.Top);
  }

  public getDisplayScrollBottomUnreadButton(): boolean {
    return this.displayScrollDirectionUnreadButton(BoundingClientRectType.Bottom);
  }

  private displayScrollDirectionUnreadButton(direction: BoundingClientRectType): boolean {
    // @ts-ignore unreadListContent is a property of the unreadListContainer element
    const unreadListRef = this.unreadListContainer?.unreadListContent;
    const unreadList = unreadListRef ? unreadListRef.nativeElement : undefined;
    if (this.isContainerNotScrollable(unreadList)) return false;
    const readAndUnreadLists = this.getReadAndUnreadListsElement();
    if (direction === BoundingClientRectType.Top) {
      // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
      return unreadList.getBoundingClientRect()[direction] + conversationHeight <= readAndUnreadLists!.getBoundingClientRect()[direction];
    }
    // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
    return unreadList.getBoundingClientRect()[direction] - conversationHeight >= readAndUnreadLists!.getBoundingClientRect()[direction];
  }

  private isContainerNotScrollable(unreadList: HTMLElement): boolean {
    return this.isSearchMode || !this.getUnreadConversations() || !this.getUnreadConversations().length
      || !unreadList || !this.getReadAndUnreadListsElement();
  }

  private getReadAndUnreadListsElement(): HTMLElement | null {
    const tabContent = document.getElementById(`tab-content-${this.category}`);
    return tabContent ? tabContent.parentElement : null;
  }

  private getLabelUnreadConvs(numberOfUnreadConvs: number): string {
    return `${numberOfUnreadConvs} ${this.translateService.instant('INBOX.CONVERSATIONS_LIST.UNREAD_LABEL')}`;
  }

}
