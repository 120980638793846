import { Injectable } from '@angular/core';

export interface StorageLoginDetails {
  accountName: string;
  email: string;
  remember: boolean;
}

export interface StorageSessionDetails {
  accountName: string;
  token: string;
}

@Injectable({
  providedIn: 'root'
})
export class ZiwoStorageService {
  // eslint-disable-next-line @typescript-eslint/naming-convention
  private readonly ZIWO_LOGIN_DETAILS_KEY = 'ZIWO_LOGIN_DETAILS';
  // eslint-disable-next-line @typescript-eslint/naming-convention
  private readonly ZIWO_SESSION_DETAILS_KEY = 'ZIWO_SESSION_DETAILS';

  private loginDetails!: StorageLoginDetails;
  private sessionDetails!: StorageSessionDetails;

  constructor() {
    this.loadLoginDetails();
    this.loadSessionDetails();
  }

  // #region Login

  public loadLoginDetails(): void {
    const raw = window.localStorage.getItem(this.ZIWO_LOGIN_DETAILS_KEY);
    if (raw) {
      try {
        this.loginDetails = JSON.parse(raw) as StorageLoginDetails;
        return;
      } catch {}
    }

    this.loginDetails = {
      accountName: '',
      email: '',
      remember: false
    };
  }

  public getLoginDetails(): StorageLoginDetails {
    return this.loginDetails;
  }

  public saveLoginDetails(details: StorageLoginDetails): void {
    this.loginDetails = details;
    window.localStorage.setItem(this.ZIWO_LOGIN_DETAILS_KEY, JSON.stringify(details));
  }

  // #endregion

  // #region Session

  public loadSessionDetails(): void {
    const raw = window.localStorage.getItem(this.ZIWO_SESSION_DETAILS_KEY);
    if (raw) {
      try {
        this.sessionDetails = JSON.parse(raw) as StorageSessionDetails;
        return;
      } catch {}
    }

    this.sessionDetails = {
      accountName: '',
      token: ''
    };
  }

  public getSessionDetails(): StorageSessionDetails {
    return this.sessionDetails;
  }

  public saveSessionDetails(details: StorageSessionDetails): void {
    this.sessionDetails = details;
    window.localStorage.setItem(this.ZIWO_SESSION_DETAILS_KEY, JSON.stringify(details));
  }

  public resetSessionDetails(): void {
    this.sessionDetails = {
      accountName: '',
      token: ''
    };
    window.localStorage.removeItem(this.ZIWO_SESSION_DETAILS_KEY);
  }

  // #endregion
}
