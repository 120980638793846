import { Component, Input, OnInit, Output, EventEmitter } from '@angular/core';
import { Conversation } from '../../../../../../agent/inbox/conversations.service';
import { CurrentUserService } from '../../../../../../services/current-user.service';
import { ConversationsModuleService } from '../../../../conversations-module.service';

@Component({
  selector: 'app-conversation-details',
  templateUrl: './conversation-details.component.html',
  styleUrls: ['./conversation-details.component.scss']
})
export class ConversationDetailsComponent implements OnInit {

  @Input() public focusedConversation!: Conversation;
  public showNavigationButton = this.currentUserService.isAdmin();

  constructor(
    private currentUserService: CurrentUserService,
    private conversationsModuleService: ConversationsModuleService,
  ) { }

  public ngOnInit(): void {
  }

  public get expend(): boolean {
    return Boolean(this.focusedConversation);
  }

  public closeConversation(): void {
    this.conversationsModuleService.closeConversation();
  }

}
