import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'app-attachment-badge',
  templateUrl: './attachment-badge.component.html',
  styleUrls: ['./attachment-badge.component.scss']
})
export class AttachmentBadgeComponent implements OnInit {

  @Input() public hasAttachments = false;

  constructor() { }

  public ngOnInit(): void {
  }

}
