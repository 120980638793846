<div class="focused-conversation-log">
    <div class="timestamp" *ngIf="log.direction === 'internal'">
        <app-log-timestamp [log]="log" size="small"></app-log-timestamp>
    </div>
    <div class="focused-conversation-log-profile" *ngIf="log.direction !== 'internal'">
        <div class="focused-conversation-log-date">{{ log.createdAt | tzDateTime : undefined : true }}</div>
        <app-customer-image *ngIf="log.direction === 'inbound'" [customer]="customer"></app-customer-image>
        <div *ngIf="log.direction === 'outbound'">
            <app-customer-image *ngIf="agent.photo" [customer]="agent"></app-customer-image>
            <img *ngIf="!agent.photo" src="assets/images/whatsapp/agent-placeholder.png">
        </div>
        <app-channel [channel]="log.category" [direction]="log.direction" size="badge"></app-channel>
        <div class="focused-conversation-log-author">
            {{ log.direction === 'inbound' ? (conversation | conversationContact) : (agent | fullname) }}
            <!-- TO DO once / if we have CDR module -->
            <!-- <app-navigation-button *ngIf="log.type === 'call'" destination="calls" paramName="callID" [paramValue]="log.content.callID"></app-navigation-button> -->
        </div>
        <div class="column-container" *ngIf="log.type === 'location'">
            <div><span>{{ 'CONVERSATIONS.LOG.LATITUDE' | translate }}</span>{{ ' ' + log.content.latitude }}</div>
            <div><span>{{ 'CONVERSATIONS.LOG.LONGITUDE' | translate }}</span>{{ ' ' + log.content.longitude }}</div>
        </div>
        <div class="focused-conversation-log-contact column-container" *ngIf="log.type === 'contact'">
            <div class="column-container" *ngFor="let content of log.content; trackBy: trackByIndex">
                <app-conversation-contact [contactContent]="content"></app-conversation-contact>
            </div>
        </div>
        <app-text-with-links class="focused-conversation-log-text" *ngIf="displayTextWithLinks()" [text]="text"></app-text-with-links>
        <app-attachment class="focused-conversation-log-attachment" *ngIf="attachment" [attachment]="attachment" [withDownload]="true"></app-attachment>
        <app-conversation-log-recording *ngIf="log.type === 'call'" [log]="log" [small]="true"></app-conversation-log-recording>
    </div>
</div>