import { Injectable } from '@angular/core';
import _ from 'lodash';

@Injectable({
  providedIn: 'root'
})
export class SplittedArrayService {

  constructor() { }

  public splittedArray(array: any[], numberOfSubArrays: number): any[] {
    const splittedArray = _.times(numberOfSubArrays, () => []);
    array.forEach((element, index) => splittedArray[index % numberOfSubArrays].push(element));
    return splittedArray;
  }

  public unsplittedArray(splittedArray: any[][]): any[] {
    const array = Array(_.sumBy(splittedArray, 'length'));
    splittedArray.forEach((subArray, subArrayIndex) =>
      subArray.forEach((element, elementIndex) => array[subArrayIndex + elementIndex*splittedArray.length] = element));
    return array;
  }
}
