import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-current-conversation-panel-placeholder',
  templateUrl: './current-conversation-panel-placeholder.component.html',
  styleUrls: ['./current-conversation-panel-placeholder.component.scss']
})
export class CurrentConversationPanelPlaceholderComponent implements OnInit {

  constructor() { }

  public ngOnInit(): void {
  }

}
