import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable } from 'rxjs';

export interface ZiwoApiResult<T> {
  result: boolean;
  info: any;
  content: T;
}

export class Core {

  private contactCenterName = '';
  private baseUrl = '';
  private headers = new HttpHeaders();
  private token = '';
  // eslint-disable-next-line @typescript-eslint/naming-convention
  private readonly API_PROTOCOL = 'https://';
  // eslint-disable-next-line @typescript-eslint/naming-convention
  private readonly API_PREFIX = '-api.aswat.co';
  // eslint-disable-next-line @typescript-eslint/naming-convention
  private readonly LOCAL_STORAGE_KEY = 'ziwo.auth.storage';

  constructor(
    protected httpClient: HttpClient,
  ) {}

  public get<T>(endpoint: string): Observable<ZiwoApiResult<T>> {
    return this.httpClient.get<ZiwoApiResult<T>>(this.buildFullUrl(endpoint), {
      headers: this.headers
    });
  }

  public post<T>(endpoint: string, payload: any): Observable<ZiwoApiResult<T>> {
    return this.httpClient.post<ZiwoApiResult<T>>(this.buildFullUrl(endpoint), payload, {
      headers: this.headers
    });
  }

  public put<T>(endpoint: string, payload: any): Observable<ZiwoApiResult<T>> {
    return this.httpClient.put<ZiwoApiResult<T>>(this.buildFullUrl(endpoint), payload, {
      headers: this.headers
    });
  }

  public delete<T>(endpoint: string): Observable<ZiwoApiResult<T>> {
    return this.httpClient.delete<ZiwoApiResult<T>>(this.buildFullUrl(endpoint), {
      headers: this.headers
    });
  }

  public getContactCenter(): string {
    return this.contactCenterName;
  }

  public setContactCenter(contactCenterName: string, save = true): void {
    this.contactCenterName = contactCenterName;
    this.baseUrl = `${this.API_PROTOCOL}${contactCenterName}${this.API_PREFIX}`;
    if (save) {
      this.saveInStorage();
    }
  }

  public getToken(): string {
    return this.token;
  }

  public setToken(token: string, save = true): void {
    this.token = token;
    this.headers = this.headers.delete('access_token');
    this.headers = this.headers.append('access_token', token);
    if (save) {
      this.saveInStorage();
    }
  }

  public logout(): void {
    window.localStorage.setItem(this.LOCAL_STORAGE_KEY, '');
  }

  public setup(): void {
    this.loadFromStorage();
  }

  public isSetup(): boolean {
    return !!this.baseUrl && !!this.token && !!this.contactCenterName;
  }

  private buildFullUrl(endpoint: string): string {
    return `${this.baseUrl}${endpoint}${endpoint.indexOf('?') !== -1 ? '&' : '?'}bc=${Date.now()}`;
  }

  private saveInStorage(): void {
    window.localStorage.setItem(this.LOCAL_STORAGE_KEY, JSON.stringify({
      contactCenterName: this.contactCenterName,
      token: this.token,
    }));
  }

  private loadFromStorage(): void {
    const fromStorage = window.localStorage.getItem(this.LOCAL_STORAGE_KEY);
    if (!fromStorage) {
      return;
    }
    let loginDetails: string|any = '';
    try {
      loginDetails = JSON.parse(fromStorage);
    } catch {}
    if (!loginDetails) {
      return;
    }
    if (loginDetails.contactCenterName) {
      this.setContactCenter(loginDetails.contactCenterName, false);
    }
    if (loginDetails.token) {
      this.setToken(loginDetails.token, false);
    }
  }
}
