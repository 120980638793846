import { Component, Input, OnInit } from '@angular/core';
import { Conversation } from '../../../../../agent/inbox/conversations.service';
import { CurrentUserService } from '../../../../../services/current-user.service';
import { ConversationsModuleService } from '../../../conversations-module.service';

@Component({
  selector: 'app-table-module-table-row',
  templateUrl: './conversations-table-module-table-row.component.html',
  styleUrls: ['./conversations-table-module-table-row.component.scss']
})
export class ConversationsTableModuleTableRowComponent implements OnInit {

  @Input() public conversation!: Conversation;

  public isAdmin = this.currentUserService.isAdmin();

  constructor(
    private currentUserService: CurrentUserService,
    private conversationsModuleService: ConversationsModuleService,
  ) { }

  public ngOnInit(): void {
  }

  public get focusedConversation(): Conversation | undefined {
    return this.conversationsModuleService.focusedConversation;
  }

  public toggleConversation(): void {
    this.conversationsModuleService.toggleConversation(this.conversation);
  }

}
