import { Component, Input, OnInit, DoCheck, ViewChild, ElementRef, OnDestroy } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { ConversationContactPipe } from '../../../../../../pipes/conversation-contact.pipe';
import { Conversation, Attachment, ConversationType } from '../../../../conversations.service';
import PhoneNumber from 'awesome-phonenumber';
import _ from 'lodash';
import { RegexService } from '../../../../../../services/regex.service';
import { InboxSocketService } from '../../../../inbox.socket.service';
import { UploaderService } from '../../../../../../services/uploader.service';

interface SmsBalanceData {
  result: boolean;
  content: SmsBalanceDataContent;
}

interface SmsBalanceDataContent {
  alert: boolean;
  warning: boolean;
}
@Component({
  selector: 'app-messenger',
  templateUrl: './messenger.component.html',
  styleUrls: ['./messenger.component.scss']
})
export class MessengerComponent implements OnInit, DoCheck, OnDestroy {

  @ViewChild('messengerTextarea') public messengerTextarea!: ElementRef;
  @ViewChild('messengerContainer') public messengerContainer!: ElementRef;
  @ViewChild('messengerElement') public messengerElement!: ElementRef;
  @Input() public conversation!: Conversation;

  public maxLength?: number;
  public maxLengthMessage = '';
  public isDraggingOver = false;
  public previousConversation!: Conversation;
  public hasTextAreaBeenInitialized = false;
  private messengerPaddingHeight = 21;
  private messengerLineHeight = 17;
  private maxNumberOfVisibleLines = 18;
  private minMessengerHeight = 41;
  private lowerBoxHeight = 50;
  private messengerBorder = 3;
  private roundBorderCount = 4;

  private listenerForLiveSmsBalance = {
    eventName: 'LiveSmsBalance',
    handling: (data: SmsBalanceDataContent): void => this.setLiveSmsBalance(data),
    listener: (eventData: SmsBalanceData): void => {},
  };
  private smsBalanceAlert = false;

  constructor(
    private inboxSocketService: InboxSocketService,
    protected translateService: TranslateService,
    private contactPipe: ConversationContactPipe,
    private regexService: RegexService,
    private uploaderService: UploaderService,
  ) { }

  public ngOnInit(): void {
    this.listenToLiveSmsBalance();
    this.init();
  }

  public ngDoCheck(): void {
    if (this.isConversationDifferent() || this.isConversationUpdated()) {
      this.init();
    }
  }

  public ngOnDestroy(): void {
    this.stopListenningToLiveSmsBalance();
  }

  public canSendMessage(): boolean {
    return Boolean(this.conversation.draft.text || this.conversation.draft.attachments.length)
      && !this.conversation.isSending && this.canSendSms();
  }

  public canSendSms(): boolean {
    if (![ConversationType.Call, ConversationType.Sms].includes(this.conversation.type)) {
      return Boolean(this.conversation.expireTime && this.conversation.expireTime > 0);
    }
    return this.isMobile(this.conversation.phone) && this.hasEnoughCredit();
  }

  public getMaxLengthMessage(): string {
    return `${this.getNumberOfCharacters()} / ${this.maxLength + this.maxLengthMessage}`;
  }

  public withAttachements(): boolean {
    return this.conversation && ![ConversationType.Call, ConversationType.Sms].includes(this.conversation.type);
  }

  public send(): void {
    if (!this.canSendMessage()) {
      return;
    }
    this.conversation.isSending = true;
    this.conversation.sendMessage().then((res: any) => {
      this.conversation.isSending = false;
      this.setMessengerBoxHeight(this.conversation.draft.text);
    }).catch(() => {});
  }

  public placeholderText(): string {
    if (this.canSendSms()) {
      // eslint-disable-next-line max-len
      return `${this.translateService.instant('INBOX.CURRENT_CONVERSATION.MESSENGER.PLACEHOLDER')} ${this.contactPipe.transform(this.conversation)}`;
    }
    if (![ConversationType.Call, ConversationType.Sms]
      .includes(this.conversation.type) && (!this.conversation.expireTime || this.conversation.expireTime <= 0)) {
      return this.translateService.instant('INBOX.CURRENT_CONVERSATION.MESSENGER.CONVERSATION_EXPIRED');
    }
    if (!this.isMobile(this.conversation.phone)) return this.translateService.instant('INBOX.CURRENT_CONVERSATION.MESSENGER.SMS_LANDLINE');
    return this.translateService.instant('INBOX.CURRENT_CONVERSATION.MESSENGER.SMS_CREDIT');
  }

  public trackByIndex(index: number, attachment: Attachment): number {
    return index;
  }

  public attach(files: FileList | File[]): void {
    if (!files || !files.length) return;
    this.conversation.draft.attachFiles(Array.from(files));
  }

  public removeAttachment(event: any, index: number): void {
    this.conversation.draft.removeAttachmentAt(index);
  }

  public onAttachSuccess(event: any): void {
    this.attach(event.target.files);
  }

  public onDragLeave(event: any): void {
    this.isDraggingOver = false;
  }

  public onDragOver(event: any): void {
    this.isDraggingOver = true;
    if (!this.withAttachements()) {
      return;
    }
    event.preventDefault();
  }

  public onDropSuccess(event: any): void {
    this.isDraggingOver = false;
    event.preventDefault();
    const files = this.uploaderService.getFilesFromDataTransfer(event.dataTransfer);
    this.attach(files);
  }

  public setMessengerBoxHeight(newText: string): void {
    if (!this.messengerTextarea || !this.messengerTextarea.nativeElement) return;
    const numberOfLines = this.getNumberOfLines(newText);
    const totalTextHeight = this.messengerLineHeight * numberOfLines;
    const maxMessengerTextHeight = this.messengerLineHeight * this.maxNumberOfVisibleLines;

    const currentMessengerTextHeight = this.maxNumberOfVisibleLines < numberOfLines ? maxMessengerTextHeight : totalTextHeight;
    let messengerHeight = currentMessengerTextHeight + this.messengerPaddingHeight;
    if (messengerHeight < this.minMessengerHeight) messengerHeight = this.minMessengerHeight;

    this.messengerTextarea.nativeElement.style.height = `${messengerHeight}px`;
    this.setMessengerContainersHeight(messengerHeight);
    this.hasTextAreaBeenInitialized = true;
  }

  public enterClicked(event: any): void {
    event.preventDefault();
    this.send();
  }

  public displayReturnUseMessageText(): boolean {
    return this.conversation.draft.text.length >= 3;
  }

  private init(): void {
    this.previousConversation = _.cloneDeep(this.conversation);
    window.setTimeout(() => this.focusTextArea(), 0);
    this.initMaxLength();
    if (this.messengerTextarea) this.setMessengerBoxHeight(this.conversation.draft.text);
    else window.setTimeout(() => this.setMessengerBoxHeight(this.conversation.draft.text), 0);
  }

  private focusTextArea(): void {
    if (this.messengerTextarea && this.messengerTextarea.nativeElement) {
      this.messengerTextarea.nativeElement.focus();
    }
  }

  private initMaxLength(): void {
    if (this.conversation.type === ConversationType.WhatsApp) {
      this.maxLength =  2000;
      this.maxLengthMessage = '';
    } else {
      this.maxLength =  640;
      this.maxLengthMessage = ' ' + this.translateService.instant('INBOX.CURRENT_CONVERSATION.MESSENGER.MAX_LENGTH_MESSAGE');
    }
  }

  private hasEnoughCredit(): boolean {
    return !this.smsBalanceAlert;
  }

  private getNumberOfCharacters(): number {
    return this.conversation.draft.text && this.conversation.draft.text.length || 0;
  }

  private isMobile(phoneNumberValue: string): boolean {
    return new PhoneNumber(phoneNumberValue).isMobile();
  }

  private isConversationDifferent(): boolean {
    return this.conversation.id !== this.previousConversation.id;
  }

  private isConversationUpdated(): boolean {
    return this.conversation.expireTime !== this.previousConversation.expireTime;
  }

  private getNumberOfLines(text: string): number {
    return text.split(this.regexService.newLine).length;
  }

  private setMessengerContainersHeight(messengerTextAreaHeight: number): void {
    const containerHeight = messengerTextAreaHeight + this.lowerBoxHeight + this.roundBorderCount * this.messengerBorder;

    if (this.messengerContainer && this.messengerContainer.nativeElement) {
      this.messengerContainer.nativeElement.style.height = `${containerHeight}px`;
    }
    if (this.messengerElement && this.messengerElement.nativeElement) {
      this.messengerElement.nativeElement.style.height = `${containerHeight}px`;
    }
  }

  private listenToLiveSmsBalance(): void {
    this.inboxSocketService.refreshEvent(this.listenerForLiveSmsBalance.eventName);
    this.listenerForLiveSmsBalance.listener =
      (eventData: SmsBalanceData): void => this.listenerForLiveSmsBalance.handling(eventData.content);
    // @ts-ignore until inboxSocketService is refactored
    this.inboxSocketService['on' + this.listenerForLiveSmsBalance.eventName](this.listenerForLiveSmsBalance.listener);
  }

  private stopListenningToLiveSmsBalance(): void {
    // @ts-ignore until inboxSocketService is refactored
    this.inboxSocketService['off' + this.listenerForLiveSmsBalance.eventName](this.listenerForLiveSmsBalance.listener);
  }

  private setLiveSmsBalance(data: SmsBalanceDataContent): void {
    this.smsBalanceAlert = data.alert;
  }
}
