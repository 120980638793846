import { Component, OnDestroy, OnInit } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
// import { DialpadCoreService } from '../../services/dialpad-core.service';
import { InfoboxService } from '../infobox/infobox.service';
// import { WrappedSocket } from 'ngx-socket-io/src/socket-io.service';

@Component({
  selector: 'app-offline-alert',
  templateUrl: './offline-alert.component.html',
  styleUrls: ['./offline-alert.component.scss']
})
export class OfflineAlertComponent implements OnInit, OnDestroy {
  // private agentSocket?: WrappedSocket;
  private onReconnectListener!: () => void;

  constructor(
    private infoboxService: InfoboxService,
    private translateService: TranslateService,
    // private dialpadCoreService: DialpadCoreService,
  ) { }

  public async ngOnInit(): Promise<void> {
    this.onReconnectListener = ():void => this.alertReconnect();
    window.addEventListener('online', this.onReconnectListener);
    // this.agentSocket = await this.getSocket();
    // this.agentSocket.on('connect', this.onReconnectListener);
  }

  public ngOnDestroy(): void {
    window.removeEventListener('online', this.onReconnectListener);
    // if(this.agentSocket) this.agentSocket.removeListener('connect', this.onReconnectListener);
  }

  public isConnected(): boolean {
    return this.isNavigatorOnline();
    // && this.isSocketConnected();
  }

  public getAlertTitle(): string {
    return this.isNavigatorOnline()
      ? this.translateService.instant('OFFLINE_ALERT.LOST')
      : this.translateService.instant('OFFLINE_ALERT.NO_CONNECTION');
  }

  private isNavigatorOnline(): boolean {
    return window.navigator.onLine;
  }

  // private isSocketConnected(): boolean {
  //   return !this.agentSocket || this.agentSocket.ioSocket.connected;
  // }

  private alertReconnect(): void {
    if (this.isConnected()) this.infoboxService.longInfo(this.translateService.instant('OFFLINE_ALERT.CONNECTED'));
  }

  // private async getSocket(): Promise<WrappedSocket> {
  //   await this.dialpadCoreService.getCore();
  //   return this.dialpadCoreService.agentSocket;
  // }
}
