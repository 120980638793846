<div class="read-and-unread-lists">
    <app-more-unreads-button class="more-unreads-button more-unreads-button-top" *ngIf="displayUnreadButtons.top" (click)="scrollToTopUnread()" id="scroll-to-top-unread"></app-more-unreads-button>
    <div class="unread-title" *ngIf="getUnreadConversations() && getUnreadConversations().length">{{ labelUnreadConvs }}</div>
    <app-list-of-conversations #unreadListContainer [conversations]="getUnreadConversations()" [withStatus]="isSearchMode"></app-list-of-conversations>

    <div class="separator" *ngIf="getReadConversations() && getReadConversations().length && getUnreadConversations() && getUnreadConversations().length"></div>
    
    <app-list-of-conversations [conversations]="getReadConversations()" [withStatus]="isSearchMode"></app-list-of-conversations>
    <app-no-conversation-placeholder [isSearchMode]="isSearchMode" [noConversation]="displayNoConversationPlaceholder()" [mode]="category"></app-no-conversation-placeholder>
    <app-more-unreads-button class="more-unreads-button more-unreads-button-bottom" direction="down" *ngIf="displayUnreadButtons.bottom" (click)="scrollToBottomUnread()" id="scroll-to-bottom-unread"></app-more-unreads-button>
</div>