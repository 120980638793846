<div class="alert-title">
    <h2 mat-dialog-title>{{ 'ALERT.TITLE' | translate }}</h2>
    <mat-dialog-content>
        <div>{{ data.content }}</div>
    </mat-dialog-content>
</div>

<mat-dialog-actions>
    <button mat-button (click)="close()">{{ 'ALERT.NO' | translate }}</button>
    <button mat-button (click)="confirm()" cdkFocusInitial>{{ 'ALERT.YES' | translate }}</button>
</mat-dialog-actions>
