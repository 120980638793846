import { Component, Input, OnInit } from '@angular/core';
import { Conversation } from '../../agent/inbox/conversations.service';

@Component({
  selector: 'app-conversation-channel',
  templateUrl: './conversation-channel.component.html',
  styleUrls: ['./conversation-channel.component.scss']
})
export class ConversationChannelComponent implements OnInit {

  @Input() public conversation!: Conversation;
  @Input() public size = 'medium';
  @Input() public withName = false;

  constructor() { }

  public ngOnInit(): void {
  }

}
