import { Component, Input, OnInit } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { RouterService } from '../../../../../services/router.service';

@Component({
  selector: 'app-check-inbox',
  templateUrl: './check-inbox.component.html',
  styleUrls: ['./check-inbox.component.scss', '../../connexion/connexion.component.scss', '../forgot-account/forgot-account.component.scss']
})
export class CheckInboxComponent implements OnInit {

  @Input() public mode = 'password';
  @Input() public username = '';
  public checkInboxForm = new FormGroup({});

  constructor(
    private router: RouterService,
  ) { }

  public ngOnInit(): void {
    this.checkInboxForm = this.buildForm();
  }

  public onQuit(): void {
    this.router.navigate(['login']);
  }

  private buildForm(): FormGroup {
    return new FormGroup({});
  }

}
