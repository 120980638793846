import { DatePipe, getLocaleFirstDayOfWeek, getLocaleId } from '@angular/common';
import { Component, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges } from '@angular/core';
import { MatDialog, MatDialogConfig, MatDialogRef } from '@angular/material/dialog';
import { TranslateService } from '@ngx-translate/core';
import _ from 'lodash';
import moment from 'moment';
import { Moment } from 'moment';
import { InfoService, TimezoneInfos } from '../../services/info.service';
import { SplittedArrayService } from '../../services/splitted-array.service';
import { TimezoneService } from '../../services/timezone.service';
import { DateTimePickerPanelComponent } from './date-time-picker-panel/date-time-picker-panel.component';

export interface PanelDateRange {
  name: string;
  title: string;
  onlyWhenRollingToRange?: boolean;
}

export interface DateRange {
  from: Date | null;
  to: Date | null;
  rollingDate: string;
}

interface RollingDateRange {
  begin: Moment;
  end: Moment;
}

@Component({
  selector: 'app-date-time-picker',
  templateUrl: './date-time-picker.component.html',
  styleUrls: ['./date-time-picker.component.scss']
})
export class DateTimePickerComponent implements OnInit, OnChanges {

  @Input() public type = '';
  @Input() public rollingToRange = false;
  @Input() public filterModel = '';
  @Input() public currentDateRange!: DateRange;
  @Input() public fromLabel = '';
  @Input() public toLabel = '';
  @Input() public timeWithDate = false;
  @Input() public title = '';
  @Input() public onlyCustom = false;
  @Input() public noReset = false;
  @Input() public dateTimezone = '';
  @Input() public titleLabel = '';
  @Output() public handleChange = new EventEmitter<void>();
  @Output() public filterModelChange = new EventEmitter<string>();
  @Output() public titleLabelChange = new EventEmitter<string>();

  public timezone!: TimezoneInfos;
  public timezoneOffset!: string;
  private dialogRef?: MatDialogRef<DateTimePickerPanelComponent>;
  private readonly rollingDateLabels: { [key: string]: string } = {
    today: this.getRollingDateTranslation('TODAY'),
    sinceToday: this.getRollingDateTranslation('S_TODAY'),
    yesterday: this.getRollingDateTranslation('YESTERDAY'),
    sinceYesterday: this.getRollingDateTranslation('S_YESTERDAY'),
    thisWeek: this.getRollingDateTranslation('T_WEEK'),
    sinceThisWeek: this.getRollingDateTranslation('S_T_WEEK'),
    lastWeek: this.getRollingDateTranslation('L_WEEK'),
    sinceLastWeek: this.getRollingDateTranslation('S_L_WEEK'),
    thisMonth: this.getRollingDateTranslation('T_MONTH'),
    sinceThisMonth: this.getRollingDateTranslation('S_T_MONTH'),
    lastMonth: this.getRollingDateTranslation('L_MONTH'),
    sinceLastMonth: this.getRollingDateTranslation('S_L_MONTH'),
    thisYear: this.getRollingDateTranslation('T_YEAR'),
    sinceThisYear: this.getRollingDateTranslation('S_T_YEAR'),
    lastYear: this.getRollingDateTranslation('L_YEAR'),
    sinceLastYear: this.getRollingDateTranslation('S_L_YEAR')
  };



  constructor(
    private infoService: InfoService,
    private timezoneService: TimezoneService,
    private datePipe: DatePipe,
    private dialog: MatDialog,
    private splittedArrayService: SplittedArrayService,
    private translateService: TranslateService,
  ) { }

  public ngOnInit(): void {
    this.initFromType();
    this.setInitialValues();
  }

  public ngOnChanges(changes: SimpleChanges): void {
    if (changes.dateTimezone) this.initTimezone();
  }

  // NOTE: API considers default toTime as last second of the given toDate.
  // Since client is not passing explicit toTime for rollingDate filters,
  // client has to adjust the to toDate accordingly.
  // Ex: For `today` filter, begin and end should be same date(today).
  // server then considers toTime as the end of today.
  public getTodayRange(): RollingDateRange {
    return {
      begin: this.getTimezoneMoment().startOf('day'),
      end: this.getTimezoneMoment().endOf('day')
    };
  }
  public getYesterdayRange(): RollingDateRange {
    return {
      begin: this.getTimezoneMoment().startOf('day').subtract(1, 'day'),
      end: this.getTimezoneMoment().endOf('day').subtract(1, 'day')
    };
  }
  public getCurrentWeekRange(): RollingDateRange {
    return {
      begin: this.getTimezoneMoment().startOf('week'),
      end: this.getTimezoneMoment().endOf('week')
    };
  }
  public getLastWeekRange(): RollingDateRange {
    return {
      begin: this.getTimezoneMoment().startOf('week').subtract(1, 'week'),
      end: this.getTimezoneMoment().endOf('week').subtract(1, 'week')
    };
  }
  public getCurrentMonthRange(): RollingDateRange {
    return {
      begin: this.getTimezoneMoment().startOf('month'),
      end: this.getTimezoneMoment().endOf('month')
    };
  }
  public getLastMonthRange(): RollingDateRange {
    return {
      begin: this.getTimezoneMoment().startOf('month').subtract(1, 'month'),
      end: this.getTimezoneMoment().endOf('month').subtract(1, 'month')
    };
  }
  public getCurrentYearRange(): RollingDateRange {
    return {
      begin: this.getTimezoneMoment().startOf('year'),
      end: this.getTimezoneMoment().endOf('year')
    };
  }
  public getLastYearRange(): RollingDateRange {
    return {
      begin: this.getTimezoneMoment().startOf('year').subtract(1, 'year'),
      end: this.getTimezoneMoment().endOf('year').subtract(1, 'year')
    };
  }

  public onPanelChange(newDateRange: DateRange): void {
    this.formatFilter(newDateRange);
    window.setTimeout(() => {
      this.filterModelChange.emit(this.filterModel);
      this.handleChange.emit();
    }, 0);
  };

  public getRollingLabel(rollingDate: string): string {
    if (/last(\d+)days/.test(rollingDate)) {
      // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
      return this.translateService.instant('DATE_TIME_PICKER.LAST_N_DAYS', {number: /last(\d+)days/.exec(rollingDate)![1]});
    }
    return this.rollingDateLabels[rollingDate];
  }

  public openPanel(): void {
    const rollingDates = [
      { name: 'today', title: this.getRollingDateTranslation('TODAY')},
      { name: 'thisWeek', title: this.getRollingDateTranslation('T_WEEK')},
      { name: 'thisMonth', title: this.getRollingDateTranslation('T_MONTH')},
      { name: 'thisYear', title: this.getRollingDateTranslation('T_YEAR'), onlyWhenRollingToRange: true},
      { name: 'yesterday', title: this.getRollingDateTranslation('YESTERDAY')},
      { name: 'lastWeek', title: this.getRollingDateTranslation('L_WEEK')},
      { name: 'lastMonth', title: this.getRollingDateTranslation('L_MONTH')},
      { name: 'lastYear', title: this.getRollingDateTranslation('L_YEAR'), onlyWhenRollingToRange: true},
    ].filter((dateRange: PanelDateRange) =>
      this.rollingToRange || !dateRange.onlyWhenRollingToRange );

    const dateRangesColumns = this.splittedArrayService.splittedArray(rollingDates, rollingDates.length / 2);
    const sinceDateRanges = _.cloneDeep(dateRangesColumns).flat().map((dateRange: PanelDateRange) => {
      dateRange.name = 'since' + _.upperFirst(dateRange.name);
      return dateRange;
    });
    const maximumDateRangeDays = this.rollingToRange ? null : 62;

    const dialogConfig = new MatDialogConfig();
    dialogConfig.disableClose = false;
    dialogConfig.autoFocus = false;
    dialogConfig.data = {
      currentDateRange: this.currentDateRange,
      onPanelChange: (newDateRange: DateRange): void => this.onPanelChange(newDateRange),
      onlyCustom: this.onlyCustom,
      dateRangesColumns: dateRangesColumns,
      sinceDateRanges: sinceDateRanges,
      maximumDateRangeDays: maximumDateRangeDays,
      noReset: this.noReset,
    };
    dialogConfig.width = '296px';
    this.dialogRef = this.dialog.open(DateTimePickerPanelComponent, dialogConfig);
  }

  private getRollingDateTranslation(toTranslate: string): string {
    return this.translateService.instant(`DATE_TIME_PICKER.ROLLING_DATES.${toTranslate}`);
  }

  private initFromType(): void {
    if (this.type === 'dateRange') {
      this.title = this.translateService.instant('DATE_TIME_PICKER.DATE');
      this.fromLabel = 'fromDate';
      this.toLabel='toDate';
    }
  }

  private setInitialValues(): void {
    this.filterModel = this.filterModel || '';
    this.initTimezone();

    const filtersByName = _.fromPairs(
      this.filterModel.split('&').filter(string => string.includes('='))
        .map(filterString => [filterString.split('=')[0], filterString.split('=')[1]])
    );
    const fromDate = filtersByName[this.fromLabel]
      ? new Date(
        filtersByName[this.fromLabel] + 'T' + (filtersByName.fromTime || (moment().startOf('day').utc().format('HH:mm:ss')) + '.000Z')
      )
      : null;
    const toDate = filtersByName[this.toLabel]
      ? new Date(filtersByName[this.toLabel] + 'T' + (filtersByName.toTime || (moment().endOf('day').utc().format('HH:mm:ss')) + '.000Z'))
      : null;
    const rollingDate = filtersByName['rollingDate'];

    this.formatFilter({ from: fromDate, to: toDate, rollingDate: rollingDate });
  }

  private initTimezone(): void {
    this.timezone = this.dateTimezone ? this.infoService.getTimezoneById(this.dateTimezone) : this.timezoneService.getTimezone();
    this.timezoneOffset = this.timezone.offset;
  }

  private rangeForRollingDate(rollingDate: string): RollingDateRange {
    switch (rollingDate) {
      case 'today': case'sinceToday': return this.getTodayRange();
      case 'yesterday': case'sinceYesterday': return this.getYesterdayRange();
      case 'thisWeek': case'sinceThisWeek': return this.getCurrentWeekRange();
      case 'lastWeek': case'sinceLastWeek': return this.getLastWeekRange();
      case 'thisMonth': case'sinceThisMonth': return this.getCurrentMonthRange();
      case 'lastMonth': case'sinceLastMonth': return this.getLastMonthRange();
      case 'thisYear': case'sinceThisYear': return this.getCurrentYearRange();
      case 'lastYear': case'sinceLastYear': return this.getLastYearRange();
      default: return this.getTodayRange();
    }
  }

  private formatFilter(newDateRange: DateRange): void {
    this.formatParams(newDateRange);
    this.currentDateRange = newDateRange;
  }

  private formatParams(newDateRange: DateRange): void {
    if (newDateRange.rollingDate) {
      if (this.rollingToRange) this.formatParamsRollingToRange(newDateRange);
      else this.filterModel = 'rollingDate=' + newDateRange.rollingDate;
    } else if (newDateRange.from || newDateRange.to) {
      // API ignores date filter rage unless both end of the range are specified
      const fromMoment = newDateRange.from ? moment(newDateRange.from) : this.getTimezoneMoment(0);
      const toMoment = newDateRange.to ? moment(newDateRange.to) : this.getTimezoneMoment();

      if (this.timeWithDate) {
        this.filterModel = this.fromLabel + '=' + fromMoment.format('YYYY-MM-DD') + 'T' + fromMoment.format('HH:mm:ss') + '.000Z&';
        this.filterModel += this.toLabel + '=' + toMoment.format('YYYY-MM-DD') + 'T' + toMoment.format('HH:mm:ss') + '.000Z&';
      } else {
        this.filterModel = this.fromLabel + '=' + fromMoment.format('YYYY-MM-DD') + '&';
        this.filterModel += 'fromTime=' + fromMoment.format('HH:mm:ss') + '&';
        this.filterModel += this.toLabel + '=' + toMoment.format('YYYY-MM-DD') + '&';
        this.filterModel += 'toTime=' + toMoment.format('HH:mm:ss');
      }
      if (this.titleLabel !== undefined) {
        this.titleLabel = newDateRange.from && newDateRange.to
          ? this.datePipe.transform(newDateRange.from, 'short') + ' - ' + this.datePipe.transform(newDateRange.to, 'short')
          : (newDateRange.from
            ? 'Since ' +this.datePipe.transform(newDateRange.from, 'short')
            : 'Until ' + this.datePipe.transform(newDateRange.to, 'short'));
      }
    } else {
      this.filterModel = '';
      if (this.titleLabel !== undefined) this.titleLabel = '';
    };
    this.addTimezoneId();
  }

  private formatParamsRollingToRange(newDateRange: DateRange): void {
    let fromDate;
    let fromTime;
    let toDate;
    let toTime;

    if (/last(\d+)days/.test(newDateRange.rollingDate)) {
      // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
      const fromMoment = this.getTimezoneMoment().subtract(/^last(\d+)days$/.exec(newDateRange.rollingDate)![1], 'days');
      fromDate = fromMoment.format('YYYY-MM-DD');
      fromTime = fromMoment.format('HH:mm:ss');
      toDate = this.getTimezoneMoment().format('YYYY-MM-DD');
      toTime = this.getTimezoneMoment().format('HH:mm:ss');
    } else {
      const range = this.rangeForRollingDate(newDateRange.rollingDate);
      const from = range.begin;
      if (newDateRange.from) {
        const fromMoment = moment(newDateRange.from);
        from.hour(Number(fromMoment.format('HH'))).minutes(Number(fromMoment.format('mm'))).seconds(Number(fromMoment.format('ss')));
      };
      const to = newDateRange.rollingDate.includes('since') ? this.getTimezoneMoment() : range.end;

      fromDate = from.format('YYYY-MM-DD');
      fromTime = from.format('HH:mm:ss');
      toDate = to.format('YYYY-MM-DD');
      toTime = to.format('HH:mm:ss');
    }

    this.filterModel = this.fromLabel + '=' + fromDate + '&';
    this.filterModel += 'fromTime=' + fromTime + '&';
    this.filterModel += this.toLabel + '=' + toDate + '&';
    this.filterModel += 'toTime=' + toTime;
    if (this.titleLabel !== undefined) this.titleLabel = this.getRollingLabel(newDateRange.rollingDate);
  }

  private getTimezoneMoment(date?: number): Moment {
    return moment(date).utcOffset(this.timezoneOffset);
  }

  private addTimezoneId(): void {
    if (this.timezone.local && this.filterModel) this.filterModel += '&timezoneId=' + this.timezone.id;
  }

}

