<div class="conversation-log-recording" [ngClass]="{ 'small': small }">
    <app-audio class="app-audio" *ngIf="recordingFile" [recordingFile]="recordingFile" [duration]="recordingDuration" [disableDownload]="isAgent" [small]="small"></app-audio>
    <div *ngIf="!recordingFile">
        <div *ngIf="!log.content.endedAt" class="live recording-placeholder">
                <mat-icon>phone_in_talk</mat-icon>
                <span>{{ 'INBOX.CURRENT_CONVERSATION.LOGS.CALL.IN_PROGRESS' | translate }}</span>
        </div>
        <div *ngIf="log.content.endedAt" class="placeholder recording-placeholder">
                <mat-icon>phone_disabled</mat-icon>
                <span>{{ 'INBOX.CURRENT_CONVERSATION.LOGS.CALL.NO_REC' | translate }}</span>
        </div>
    </div>
</div>