import { Component, Input, OnInit, OnDestroy } from '@angular/core';
import { FormGroup, FormControl, Validators, ValidatorFn, AbstractControl } from '@angular/forms';
import { Subscription } from 'rxjs';
import { RegexService } from 'src/app/services/regex.service';
import { ActivatedRoute, ParamMap } from '@angular/router';
import { first } from 'rxjs/operators';
import { LoginService } from '../../login.service';
import { UsernameService } from 'src/app/services/username.service';
import { AccountService } from '../../../../services/account.service';

@Component({
  selector: 'app-set-password',
  templateUrl: './set-password.component.html',
  styleUrls: ['./set-password.component.scss', '../connexion/connexion.component.scss']
})
export class SetPasswordComponent implements OnInit, OnDestroy {

  @Input() public mode = 'creation';

  public loading = false;
  public passwordForm = new FormGroup({});
  public accountName = 'account';
  public username = '';

  private loginSub = Subscription.EMPTY;
  private token = '';

  constructor(
    private regexService: RegexService,
    private route: ActivatedRoute,
    private account: AccountService,
    private usernameService: UsernameService,
    private login: LoginService,
  ) {}

  public ngOnInit(): void {
    this.passwordForm = this.buildForm();

    this.accountName = this.account.accountName || '';
    this.username = this.usernameService.username || '';
    this.route.paramMap.pipe(first()).subscribe((params : ParamMap) => {
      this.token = params.get('token') || '';
    });
  }

  public get formControls(): { [key: string]: AbstractControl } {
    return this.passwordForm.controls;
  }

  public ngOnDestroy(): void {
    this.loginSub.unsubscribe();
  }

  public onSent(): void {
    if (this.passwordForm.valid) {
      this.login.updatePassword(this.accountName, this.username, this.token, this.formControls.password.value);
    }
  }

  private buildForm(): FormGroup {
    return new FormGroup({
      password: new FormControl('', [
        Validators.required,
        Validators.pattern(this.regexService.lowercase),
        Validators.pattern(this.regexService.uppercase),
        Validators.pattern(this.regexService.digit),
        Validators.pattern(this.regexService.special),
        Validators.pattern(this.regexService.eightChars),
      ]),
      confirmPassword: new FormControl('', [Validators.required]),
    }, this.checkMatchValidator());
  }

  private checkMatchValidator(): ValidatorFn {
    return (control: AbstractControl): {[key: string]: any} | null => {
      const controlsPassword = this.passwordForm && this.formControls.password;
      const matchingControlsPassword = this.passwordForm && this.formControls.confirmPassword;
      let error = null;
      if (!controlsPassword || !matchingControlsPassword || matchingControlsPassword.errors && !matchingControlsPassword.errors.match) {
        return error;
      }
      if (controlsPassword.value !== matchingControlsPassword.value) {
        error = { match: true };
      }
      matchingControlsPassword.setErrors(error);
      return error;
    };
  }
}
