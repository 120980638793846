import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class RegexService {
  // eslint-disable-next-line max-len
  public readonly email = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
  public readonly url = /^(https?:\/\/)[a-z0-9]+([\-\.]{1}[a-z0-9]+)*\.[a-z]{2,5}(:[0-9]{1,5})?(\/.*)?$/;
  public readonly num = /^[0-9]+$/;
  public readonly alpha = /^[\u0600-\u06FFÀ-ÿA-Za-z-' ]+$/;
  public readonly alphaNum = /^[\u0600-\u06FF0-9À-ÿA-Za-z-' ]+$/;
  public readonly internationalPhone = /^\+\d+$/;
  public readonly phone = /^\+?\d+$/;
  public readonly lowercase = /^(?=.*?[a-z])/;
  public readonly uppercase = /^(?=.*?[A-Z])/;
  public readonly eightChars = /^.{8,}$/;
  public readonly maxChars = /^.{0,255}$/;
  public readonly special = /^(?=.*?[#?!@$%^&*=_.\-])/;
  public readonly digit = /^(?=.*?[0-9])/;
  public readonly newLine = /\r\n|\r|\n/;
}
