import { Component, Input, OnInit } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'app-expire-info',
  templateUrl: './expire-info.component.html',
  styleUrls: ['./expire-info.component.scss']
})
export class ExpireInfoComponent implements OnInit {

  @Input() public expireHours: number | null = null;
  @Input() public withBorders = false;
  @Input() public size = '';

  constructor(
    protected translateService: TranslateService,
  ) { }

  public ngOnInit(): void {
  }

  public getExpireLabelBeginning(): string {
    if (this.expireHours === null) {
      return '';
    }
    if (this.expireHours <= 0) {
      return this.translateService.instant('CONVERSATION.EXPIRE.LABEL_EXPIRED_BEGIN');
    }
    return this.translateService.instant('CONVERSATION.EXPIRE.LABEL_BEGIN');
  }

  public getExpireLabelEnd(): string {
    if (this.expireHours === null) {
      return '';
    }
    if (this.expireHours <= 0) {
      return this.translateService.instant('CONVERSATION.EXPIRE.LABEL_EXPIRED_END');
    }
    const labelEnd = this.expireHours === 1 ? 'CONVERSATION.EXPIRE.LABEL_END_SINGULAR' : 'CONVERSATION.EXPIRE.LABEL_END_PLURAL';
    return `${this.expireHours} ${this.translateService.instant(labelEnd)}`;
  }

  public getExpireColor(): string {
    if (this.expireHours === null) {
      return '';
    }
    if (this.expireHours <= 6) {
      return 'alert';
    };
    if (this.expireHours <= 12) {
      return 'warning';
    }
    return '';
  }
}
