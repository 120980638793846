import { Pipe, PipeTransform } from '@angular/core';
import { InfoCategory, InfoService, TimezoneInfos } from '../services/info.service';
import { TzDatePipe } from './tz-date.pipe';
import { TzTimePipe } from './tz-time.pipe';

@Pipe({
  name: 'tzDateTime'
})
export class TzDateTimePipe implements PipeTransform {

  public timezone!: any;

  constructor(
    private tzDatePipe: TzDatePipe,
    private tzTimePipe: TzTimePipe,
    private infoService: InfoService,
  ) {
    this.timezone = this.infoService.getTimezoneInfos();
  }

  public transform(date?: string | Date, filterTimezone: any = this.timezone, seconds = false): string {
    return date ? `${this.tzDatePipe.transform(date, filterTimezone)} ${this.tzTimePipe.transform(date, seconds, filterTimezone)}` : '';
  }

}
