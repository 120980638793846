<mat-form-field class="hidden-input">
    <!-- Hidden but used for Chrome to save username in credentials -->
    <input matInput placeholder="Username" email autocomplete="username">
</mat-form-field>
<mat-form-field class="hidden-input">
    <!-- Hidden but used for Chrome to save password in credentials -->
    <input matInput placeholder="Password" type="password" autocomplete="new-password">
</mat-form-field>

<div class="calls-filters">
    <div class="calls-filters-row first-row">
        <div class="calls-filters-row-container initial first-filters">
            <app-enum-filter (handleChange)="handleChange.emit()" [(filterModel)]="selectedFilters.channel" filterName="type" [allOption]="true"
                title="Channel" [list]="channels"></app-enum-filter>
        </div>
        <div class="calls-filters-row-container initial first-filters">
            <app-date-time-picker (handleChange)="handleChange.emit()" [(filterModel)]="selectedFilters.dateRange" [rollingToRange]="true"
                type="dateRange"></app-date-time-picker>
        </div>
        <div class="calls-filters-row-container initial first-filters">
            <app-input-filter filterName="phone" placeholderLabel="Customer Phone" [(filterModel)]="selectedFilters.from" (handleChange)="handleChange.emit()"></app-input-filter>
        </div>
        <div class="calls-filters-row-container initial first-filters">
            <app-enum-filter (handleChange)="handleChange.emit()" filterName="status" [multiple]="true"
                [(filterModel)]="selectedFilters.status" title="Status" [list]="statusList"></app-enum-filter>
        </div>
        <div class="calls-filters-row-container initial first-filters" *ngIf="isAdmin">
            <app-agents-filter filterName="userId" [(filterModel)]="selectedFilters.agents" (handleChange)="handleChange.emit()"></app-agents-filter>
        </div>
        <div class="calls-filters-row-container initial first-filters">
            <app-input-filter filterName="search" placeholderLabel="Last Message" [(filterModel)]="selectedFilters.lastMessage" (handleChange)="handleChange.emit()"></app-input-filter>
        </div>
        <div class="calls-filters-row-container initial first-filters">
            <app-input-filter filterName="id" placeholderLabel="Conversation ID" [(filterModel)]="selectedFilters.conversationId" (handleChange)="handleChange.emit(true)" [isId]="true"></app-input-filter>
        </div>
        <div class="calls-filters-row-more-text reset-filters">
            <span *ngIf="hasFilters()">
                <button (click)="resetFilters.emit()" mat-icon-button [matTooltip]="'Reset Filters' | translate">
                    <mat-icon>settings_backup_restore</mat-icon>
                </button>
            </span>
        </div>
    </div>
</div>

