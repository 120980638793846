import { Component, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges } from '@angular/core';
import { AbstractControl, FormControl, FormGroup, Validators } from '@angular/forms';
import { RegexService } from '../../services/regex.service';
import { patient } from 'src/app/decorators/patient';

@Component({
  selector: 'app-input-filter',
  templateUrl: './input-filter.component.html',
  styleUrls: ['./input-filter.component.scss']
})
export class InputFilterComponent implements OnInit, OnChanges {

  @Input() public filterModel = '';
  @Input() public filterName = '';
  @Input() public placeholderLabel = '';
  @Input() public isId = false;
  @Output() public handleChange = new EventEmitter<void>();
  @Output() public filterModelChange = new EventEmitter<string>();
  public inputFilterForm = new FormGroup({});
  private filterParameterName = '';

  constructor(
    private regexService: RegexService,
  ) { }

  @patient()
  private patientHandleChanges(): void {
    this.filterModelChange.emit(this.filterModel);
    this.handleChange.emit();
  }

  public ngOnInit(): void {
    this.inputFilterForm = this.buildForm();
    this.setInitialValues();
  }

  public ngOnChanges(changes: SimpleChanges): void {
    if (changes.filterModel) this.checkForNgModelResetOrInit();
  }

  public get formControls(): { [key: string]: AbstractControl } {
    return this.inputFilterForm.controls;
  }

  public formatFilter(): void {
    this.filterModel = this.formControls.inputValue.value && !this.formControls.inputValue.errors
      ? this.filterParameterName + encodeURIComponent(this.formControls.inputValue.value)
      : '';
    window.setTimeout(() => this.patientHandleChanges(), 0);
  }

  private buildForm(): FormGroup {
    const validators = this.filterName === 'phone'
      ? [Validators.pattern(this.regexService.phone)]
      : (this.isId ?  [Validators.maxLength(36),  Validators.pattern(this.regexService.alphaNum)] : undefined);
    return new FormGroup({
      inputValue: new FormControl(this.filterModel || '', validators),
    });
  }

  private checkForNgModelResetOrInit(): void {
    if (!this.filterModel && this.formControls.inputValue?.value && !this.formControls.inputValue?.errors) this.setInitialValues();
    else if (this.isId && this.filterModel) this.setInitialValues();
  }

  private setInitialValues(): void {
    this.filterParameterName = this.filterName + '=';
    this.filterModel = this.filterModel || '';
    this.formControls.inputValue.setValue(this.filterModel.replace(this.filterParameterName,''));
  }

}
