import { Component, OnInit, OnDestroy } from '@angular/core';
import { FormGroup, FormControl, Validators, AbstractControl } from '@angular/forms';
import { Router } from '@angular/router';
import { ActivatedRoute } from '@angular/router';
import { Subscription } from 'rxjs';
import { RegexService } from 'src/app/services/regex.service';
import { LoginService } from '../../login.service';
import { UsernameService } from '../../../../services/username.service';
import { AnalyticsService } from 'src/app/services/analytics.service';
import { AccountService } from '../../../../services/account.service';

@Component({
  selector: 'app-connexion',
  templateUrl: './connexion.component.html',
  styleUrls: ['./connexion.component.scss']
})
export class ConnexionComponent implements OnInit, OnDestroy {
  public loading = false;
  public loginForm = new FormGroup({});
  public premiseAccount = false;

  private loginSub = Subscription.EMPTY;

  constructor(
    private regexService: RegexService,
    private router: Router,
    private route: ActivatedRoute,
    private account: AccountService,
    private login: LoginService,
    private usernameService: UsernameService,
    private analytics: AnalyticsService,
  ) {
    this.premiseAccount = this.account.isOnPremise();
  }

  public ngOnInit(): void {
    this.loginForm = this.buildForm();
  }

  public get formControls(): { [key: string]: AbstractControl } {
    return this.loginForm.controls;
  }

  public ngOnDestroy(): void {
    this.loginSub.unsubscribe();
  }

  public onSent(): void {
    if (this.loginForm.valid) {
      this.login.login(this.formControls.account.value, this.formControls.username.value, this.formControls.password.value);
    }
  }

  public navigateToWhatIsAccount(): void {
    this.navigateToForgotCredentials('what-account');
  }

  public navigateToForgotAccount(): void {
    this.navigateToForgotCredentials('forgot-account');
  }

  public navigateToForgotPassword(): void {
    this.navigateToForgotCredentials('forgot-password');
  }

  public onTermsConsult(): void {
    this.trackLegalConsult('terms');
  }

  public onPrivacyConsult(): void {
    this.trackLegalConsult('privacy');
  }

  private buildForm(): FormGroup {
    return new FormGroup({
      account: new FormControl({ value: this.account.accountName || '',
        disabled: this.premiseAccount }, [Validators.required, Validators.pattern(this.regexService.alphaNum), Validators.maxLength(50)]),
      username: new FormControl(this.usernameService.username || '', [Validators.required, Validators.pattern(this.regexService.email)]),
      password: new FormControl('', [Validators.required, Validators.pattern(this.regexService.eightChars)]),
    });
  }

  private navigateToForgotCredentials(destination: string): void {
    this.router.navigate([destination], { relativeTo: this.route, queryParamsHandling: 'merge' });
  }

  private trackLegalConsult(section: string): void {
    this.analytics.track('clickedLegalConsultFromLoginPage', { section: section });
  }
}
