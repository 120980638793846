import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { Attachment } from '../../agent/inbox/conversations.service';

@Component({
  selector: 'app-attachment',
  templateUrl: './attachment.component.html',
  styleUrls: ['./attachment.component.scss']
})
export class AttachmentComponent implements OnInit {

  @Input() public attachment!: Attachment;
  @Input() public displayDate = false;
  @Input() public withDownload = false;
  @Input() public withRemove = false;
  @Input() public noColor = false;
  @Output() public remove = new EventEmitter<{removing: Attachment}>();

  constructor() { }

  public ngOnInit(): void {
  }

  public onRemove(): void {
    this.remove.emit({removing: this.attachment});
  }

}
