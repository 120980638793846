import { Component, Input, OnChanges, OnInit, SimpleChanges } from '@angular/core';
import { InboxService } from '../../agent/inbox/inbox.service';

@Component({
  selector: 'app-audio',
  templateUrl: './audio.component.html',
  styleUrls: ['./audio.component.scss']
})
export class AudioComponent implements OnInit, OnChanges {

  @Input() public duration = '';
  @Input() public recordingFile = '';
  @Input() public disableDownload = false;
  @Input() public small = false;
  public displayAudioElement = false;

  constructor(private inboxService: InboxService) { }

  public ngOnInit(): void {
  }

  public ngOnChanges(changes: SimpleChanges): void {
    if (changes.recordingFile) {
      this.displayAudioElement = false;
    }
  }

  public loadAudio(): void {
    this.getAudioElement().load();
    this.displayAudio();
  }

  public play(event: any): void {
    event.stopPropagation();
    this.getAudioElement().play();
    this.displayAudio();
  }

  public mute(): void {
    this.getAudioElement().muted = !this.getAudioElement().muted;
  }

  public isAudioMuted(): boolean {
    return this.getAudioElement().muted;
  }

  public downloadRecording(): void {
    this.inboxService.getFile(this.recordingFile);
    this.loadAudio();
  }

  private displayAudio(): void {
    window.setTimeout(() => this.displayAudioElement = true, 200);
  }

  private getAudioElement(): HTMLAudioElement {
    return <HTMLAudioElement> document.getElementById(this.recordingFile);
  }

}
