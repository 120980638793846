import { Injectable } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { environment } from 'src/environments/environment';
import { StorageService } from '../storage.service';
import { AccountService } from './account.service';

@Injectable({
  providedIn: 'root'
})
export class RouterService {

  constructor(
    private router: Router,
    private account: AccountService,
    private storage: StorageService,
    private activatedRoute: ActivatedRoute,
  ) { }

  public navigate(segments: string[]): Promise<boolean> {
    return this.router.navigate(segments, {queryParamsHandling: 'merge'});
  }

  public goToSuperman(): void {
    this.goToExternalUrl(this.getSupermanUrl() + this.getAutologinPath());
  }

  public goToDesk(): void {
    this.goToExternalUrl(`https://desk.ziwo.io?mode=desk&domain=${this.account.accountName}&token=${this.storage.local.getAccessToken()}`);
  }

  public updateUrlIDParameter(newParameter?: string, paramKey = 'id'): void {
    if (newParameter) this.updateUrlParameter(newParameter.substring(newParameter.indexOf('=') + 1), paramKey);
    else this.updateUrlParameter(null, paramKey);
  }

  protected goToExternalUrl(url: string): void {
    location.href = url;
  }

  private getSupermanUrl(): string {
    return `${location.protocol}//${environment.supermanSubDomain}.${location.hostname}:${environment.supermanPort}`;
  }

  private getAutologinPath(): string {
    return `/#/auto-login/${this.account.accountName}/${this.storage.local.getAccessToken()}`;
  }

  private updateUrlParameter(newParameter: string | null, paramKey = 'id'): void {
    const params: { [key: string]: string | null } = {};
    params[paramKey] = newParameter;
    this.router.navigate([], {
      relativeTo: this.activatedRoute,
      queryParams: params,
      queryParamsHandling: 'merge',
    });
  }
}
