import { Component, Input, OnInit } from '@angular/core';
import { RegexService } from 'src/app/services/regex.service';
import { FormGroup, FormControl, AbstractControl } from '@angular/forms';

@Component({
  selector: 'app-password-check',
  templateUrl: './password-check.component.html',
  styleUrls: ['./password-check.component.scss']
})
export class PasswordCheckComponent implements OnInit {

  @Input() public form: FormGroup = new FormGroup({password: new FormControl('')});
  @Input() public modelName = 'password';

  constructor(
    private regexService: RegexService
  ) { }

  public ngOnInit(): void {
  }

  public get formControl(): AbstractControl {
    return this.form.controls[this.modelName];
  }

  public hasLowercase(): boolean {
    return this.regexService.lowercase.test(this.formControl.value);
  }

  public hasUppercase(): boolean {
    return this.regexService.uppercase.test(this.formControl.value);
  }

  public hasEight(): boolean {
    return this.regexService.eightChars.test(this.formControl.value);
  }

  public hasSpecial(): boolean {
    return this.regexService.special.test(this.formControl.value);
  }

  public hasNumber(): boolean {
    return this.regexService.digit.test(this.formControl.value);
  }

}
