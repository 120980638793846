<div class="conversations-list">
  <div class="search-icon" [ngClass]="{ 'search-icon-searching': isSearching }">
    <button class="search-icon-button" [ngClass]="{ 'search-icon-button-searching': isSearching }" mat-icon-button
      (click)="searching()" id="conv-list-search">
      <mat-icon>{{ isSearching ? 'close' : 'search' }}</mat-icon>
    </button>
  </div>
  <div class="conversations-searched" [hidden]="!isSearching">
    <div class="conversations-search-input">
      <mat-form-field class="conversations-search-input-field">
        <mat-label>{{ 'INBOX.CONVERSATIONS_LIST.SEARCH' | translate }}</mat-label>
        <input matInput type="text" [(ngModel)]="searchText" (ngModelChange)="filterConversations(searchText)"
          id="search-conversations-list-input" #searchConversationsListInput />
      </mat-form-field>
    </div>
    <div class="conversation-container">
      <app-read-and-unread-lists [isSearchMode]="true"></app-read-and-unread-lists>
    </div>
  </div>

  <mat-tab-group class="conversations-tabs" *ngIf="!isSearching" (selectedTabChange)="switchCategory($event)"
    [selectedIndex]="selectedIndex">
    <mat-tab *ngFor="let category of categoriesNames; index as currentIndex; trackBy: trackByIndex">
      <ng-template mat-tab-label>
        <div class="tab-label">
          <mat-icon *ngIf="iconsByCategory[category].iconPath" [svgIcon]="this.iconsByCategory[category].label">
          </mat-icon>
          <mat-icon *ngIf="iconsByCategory[category].icon">{{ iconsByCategory[category].icon }}</mat-icon>
          <span>{{ getCategoryLabel(category) }}</span>
          <app-notification class="tab-notification" [notificationValue]="getNumberOfUnreadConversations(category)">
          </app-notification>
        </div>
      </ng-template>
      <ng-template class="tab-content" matTabContent>
        <app-read-and-unread-lists [id]="'tab-content-' + category" [category]="category"></app-read-and-unread-lists>
      </ng-template>
    </mat-tab>
  </mat-tab-group>
</div>