<div class="customer-image {{ size }}">
    <img *ngIf="customer.photo" [src]="storageUrl + customer.photo" />
    <div *ngIf="!customer.photo">
        <img *ngIf="!displayNameLetter" [src]="imageSource">
        <div class="customer-image-placeholder" [ngClass]="{ 'is-agent': isAgent }" *ngIf="displayNameLetter"><span>{{ nameLetter }}</span></div>
    </div>
    <div class="customer-image-status" *ngIf="withStatus">
        <div class="customer-image-status-dot background-dark-agent-{{getCssStatus()}}-status" [matTooltip]="getStatusTooltip()"></div>
    </div>
</div>
