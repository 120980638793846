import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';

@Component({
  selector: 'app-agents-filter',
  templateUrl: './agents-filter.component.html',
  styleUrls: ['./agents-filter.component.scss']
})
export class AgentsFilterComponent implements OnInit {

  @Input() public filterModel = '';
  @Input() public filterName = '';
  @Output() public handleChange = new EventEmitter<void>();
  @Output() public filterModelChange = new EventEmitter<string>();

  constructor() { }

  public ngOnInit(): void {
  }

}
