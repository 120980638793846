<div class="cy-date-filter-content">
  <div class="cy-date-filter-container">
      <div class="cy-date-filter-container-content">
        <mat-list class="flex-100">
          <div class="cy-date-filter-content-abstract-ranges">
            <div *ngIf="!data.onlyCustom">
              <div *ngFor="let dateRangesRow of data.dateRangesColumns; trackBy: trackByIndex">
                <div class="layout-row">
                  <div class="layout-row flex-100" *ngFor="let dateRange of dateRangesRow; index as currentIndex; trackBy: trackByIndex">
                    <mat-list-item  class="rolling-date-container flex-100" [ngClass]="{ 'cy-date-is-active': isFocused(dateRange.name), 'first-item': currentIndex === 0 }"
                      (click)="onRollingDateChange(dateRange.name)">
                      <button mat-button class="mat-list-item-text text-center">{{ dateRange.title }}</button>
                    </mat-list-item>
                    <mat-divider></mat-divider>
                  </div>
                </div>
                <mat-divider></mat-divider>
              </div>
            </div>
            <mat-list-item [ngClass]="{'cy-date-is-active': isFocused('custom')}">
              <div class="cy-date-filter-content-custom-range layout-column layout-fill">
                <div class="cy-date-filter-content-custom-range-title">{{ 'DATE_TIME_PICKER.PANEL.CUSTOM_DATE_RANGE' | translate }}</div>
                <div class="layout-row">
                  <div class="cy-date-filter-content-custom-range-content layout-column flex-50">
                    <span class="cy-date-filter-content-custom-range-content-title">{{ 'DATE_TIME_PICKER.PANEL.FROM' | translate }}</span>
                    <div class="cy-date-filter-content-custom-range-content-date">
                      <button mat-icon-button disableRipple (click)="toggleFromDateFocus();">
                        <mat-icon>date_range</mat-icon>
                      </button>
                      <app-date-time-picker-input inputId="fromDate" [(filterModel)]="fromDate" [inputMaxDate]="toDate" (handleChange)="onManualRangeChange('from')" 
                        [focusOn]="fromDateFocus" [date]="true" [inputPlaceholder]="'DATE_TIME_PICKER.PANEL.DATE' | translate">
                      </app-date-time-picker-input>
                    </div>
                    <div class="cy-date-filter-content-custom-range-content-time layout-row">
                      <button mat-icon-button disableRipple (click)="toggleFromTimeFocus()">
                        <mat-icon>access_time</mat-icon>
                      </button>
                      <app-date-time-picker-input inputId="fromTime" [(filterModel)]="fromTime" (handleChange)="onManualRangeChange('from')" 
                        [focusOn]="fromTimeFocus" [inputPlaceholder]="'DATE_TIME_PICKER.PANEL.TIME' | translate">
                      </app-date-time-picker-input>
                    </div>
                  </div>
                  <mat-divider></mat-divider>
                  <div class="cy-date-filter-content-custom-range-content layout-column flex-50">
                    <span class="cy-date-filter-content-custom-range-content-title">{{ 'DATE_TIME_PICKER.PANEL.TO' | translate }}</span>
                    <div class="cy-date-filter-content-custom-range-content-date">
                      <button mat-icon-button disableRipple (click)="toggleToDateFocus()">
                        <mat-icon>date_range</mat-icon>
                      </button>
                      <app-date-time-picker-input inputId="toDate" [(filterModel)]="toDate" [inputMinDate]="fromDate" (handleChange)="onManualRangeChange('to')" 
                        [focusOn]="toDateFocus" [date]="true" [inputPlaceholder]="'DATE_TIME_PICKER.PANEL.DATE' | translate">
                      </app-date-time-picker-input>
                    </div>
                    <div class="cy-date-filter-content-custom-range-content-time layout-row">
                      <button mat-icon-button disableRipple (click)="toggleToTimeFocus()">
                        <mat-icon>access_time</mat-icon>
                      </button>
                      <app-date-time-picker-input inputId="toTime" [(filterModel)]="toTime" (handleChange)="onManualRangeChange('to')" 
                        [focusOn]="toTimeFocus" [inputPlaceholder]="'DATE_TIME_PICKER.PANEL.TIME' | translate">
                      </app-date-time-picker-input>
                    </div>
                  </div>
                </div>
                <div class="datepicker-range-warning" *ngIf="manualRangeWarning">{{ manualRangeWarning }}</div>
              </div>
            </mat-list-item>
            <mat-divider></mat-divider>

            <div *ngIf="!data.onlyCustom">
              <mat-list-item [ngClass]="{'cy-date-is-active': isFocused('since')}">
                <div class="cy-date-filter-content-abstract-ranges-custom layout-column">
                  <span class="cy-date-filter-content-abstract-ranges-custom-title">{{ 'DATE_TIME_PICKER.PANEL.SINCE' | translate }}</span>
                  <div class="cy-date-filter-content-abstract-ranges-custom-since">
                    <mat-icon>date_range</mat-icon>
                    <mat-select [(ngModel)]="sinceRange" (selectionChange)="onSinceRollingDateChange()" [placeholder]="'DATE_TIME_PICKER.PANEL.DATE' | translate">
                      <mat-option *ngFor="let dateRange of data.sinceDateRanges" [value]="dateRange.name"><span>{{ dateRange.title | translate }}</span></mat-option>
                  </mat-select>
                  </div>
                </div>
              </mat-list-item>
            <mat-divider></mat-divider>
            <mat-list-item [ngClass]="{'cy-date-is-active': isFocused('lastNDays')}">
              <div class="cy-date-filter-content-abstract-ranges-custom layout-column">
                <span class="cy-date-filter-content-abstract-ranges-custom-title">{{ 'DATE_TIME_PICKER.PANEL.LAST_DAYS' | translate }}</span>
                <div class="cy-date-filter-content-abstract-ranges-custom-time layout-row">
                  <button mat-icon-button disableRipple (click)="focusLastNDays()">
                    <mat-icon>date_range</mat-icon>
                  </button>
                  <mat-form-field>
                    <input matInput type="number" [(ngModel)]="lastNDays" (ngModelChange)="onLastNDaysRollingDateChange()"
                      id="last-n-days" [placeholder]="'DATE_TIME_PICKER.PANEL.NUMBER_OF_DAYS' | translate" [appFocusOn]="lastNDaysFocus" />
                  </mat-form-field>
                </div>
                <div class="datepicker-range-warning" *ngIf="rollingDateWarning">{{ rollingDateWarning }}</div>
              </div>
            </mat-list-item>
            </div>

            <mat-divider></mat-divider>
            <mat-list-item>
              <div class="mat-list-item-text reset-button" *ngIf="!data.noReset">
                <button mat-raised-button (click)="reset()">{{ 'DATE_TIME_PICKER.PANEL.RESET' | translate }}</button>
              </div>
            </mat-list-item>
          </div>
        </mat-list>
      </div>
  </div>
</div>