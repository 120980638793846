import { Injectable, OnDestroy } from '@angular/core';
import _ from 'lodash';
import { Subject, Subscription } from 'rxjs';
import { Conversation } from '../../agent/inbox/conversations.service';
import { ApiService } from '../../api/api.service';
import { CurrentUserService } from '../../services/current-user.service';

export interface SortingColumn {
  field?: string;
  desc?: boolean;
}

export interface ConfigFilters {
  [key: string]: string;
}

export interface RequestConfig {
  skip: number;
  limit: number;
  filters: ConfigFilters;
  sort: SortingColumn;
}

@Injectable({
  providedIn: 'root'
})
export class ConversationsModuleService implements OnDestroy {

  public readonly defaultSortingColumn: SortingColumn = { field: 'createdAt', desc: true };
  public sortingColumn: SortingColumn = _.cloneDeep(this.defaultSortingColumn);
  public focusedConversation?: Conversation;
  private isAdmin = this.currentUserService.isAdmin();
  private userType = this.currentUserService.get().type;
  private readonly defaultOrder = '~createdAt';
  private agentsById: { [key: string]: any } = {};
  private adminsById: { [key: string]: any } = {};
  private resetFiltersSubject = new Subject();

  constructor(
    private apiService: ApiService,
    private currentUserService: CurrentUserService,
  ) {
    this.initSortingColumn();
    this.loadAgentsByIds().catch(error => {});
    this.loadAdminsByIds().catch(error => {});
  }

  public ngOnDestroy(): void {
    this.resetFiltersSubject.unsubscribe();
  }

  public toggleConversation(conversation: Conversation): void {
    if (this.focusedConversation && this.focusedConversation.id === conversation.id) return this.closeConversation();
    this.focusedConversation = conversation;
    conversation.getFormattedLogs();
  }

  public closeConversation(): void {
    delete this.focusedConversation;
  }

  public getConversationsCount(filters: ConfigFilters = {}): Promise<any> {
    return this.apiService.getRequest(`/${this.userType}/conversations/count?${this.queryParamsFromFilters(filters)}`);
  }

  public getConversations({skip, limit, filters, sort}: RequestConfig): Promise<any> {
    const order = this.convertOrderToString(sort);
    return this.apiService.getRequest(
      `/${this.userType}/conversations/?order=${order}${this.queryParamsFromFiltersAdditional(filters)}&skip=${skip}&limit=${limit}`
    );
  }

  public initSortingColumn(): void {
    this.sortingColumn = _.cloneDeep(this.defaultSortingColumn);
  }

  public getAgentsByIds(): { [key: string]: any } {
    return this.agentsById;
  }

  public getAdminsByIds(): { [key: string]: any } {
    return this.adminsById;
  }

  public broadcastResetFilters(): void {
    this.resetFiltersSubject.next();
  }

  public subscribeToResetFilters(callback: (payload: any) => void): Subscription {
    return this.resetFiltersSubject.subscribe(callback);
  }

  private loadAgentsByIds(): Promise<void> {
    return this.apiService.getRequest(`/${this.isAdmin ? 'callHistory' : 'agents/channels/calls'}/listAgents`).then((res: any) => {
      if (res.result) res.content.forEach((agent:any) => this.agentsById[agent.id] = agent);
    });
  }

  private loadAdminsByIds(): Promise<void> {
    if (!this.isAdmin) return Promise.resolve();
    return this.apiService.getRequest('/admin/users').then((res: any) => {
      if (res.result) res.content.forEach((admin:any) => this.adminsById[admin.id] = admin);
    });
  }

  private convertOrderToString(sort: SortingColumn): string {
    if (sort.field) return (sort.desc ? '~' : '') + sort.field;
    return this.defaultOrder;
  }

  private queryParamsFromFiltersAdditional(filters: {[key: string]: string}): string {
    const params = this.queryParamsFromFilters(filters);
    return params ? '&' + params : '';
  }

  private queryParamsFromFilters(filters: {[key: string]: string}): string {
    return _.values(filters).filter(Boolean).join('&');
  }
}
