<div @enterParentTrigger class="current-conversation-info" *ngIf="conversation">
    <app-current-conversation-info-title [conversation]="conversation"></app-current-conversation-info-title>
    <div class="content" (scroll)="onScroll($event)">
        <div class="section">
            <div class="section-title">
                <div>
                    <span class="content-title">{{ 'INBOX.CURRENT_CONVERSATION.INFO.CUSTOMER_TITLE' | translate }}</span>
                    <button class="current-conversation-info-edit smallest-fab-button" (click)="openProfileEditForm()" mat-mini-fab aria-label="edit customer profile" type="button" id="edit-customer-profile-button">
                        <mat-icon>edit</mat-icon>
                    </button>
                </div>
                <app-expend-button class="section-expend" [expend]="hideProfileDetails" (click)="hideProfileDetailsSection()" id="hide-profile-details-section"></app-expend-button>
            </div>
            <div @collapseTrigger class="section-info" *ngIf="!hideProfileDetails">
                <span class="content-subtitle">{{ 'INBOX.CURRENT_CONVERSATION.INFO.PHONE_SUBTITLE' | translate }}</span>
                <span class="content-content">{{ (conversation.customer.phone | customerNumber)  || '-' }}</span>
                <span class="content-subtitle">{{ 'INBOX.CURRENT_CONVERSATION.INFO.EMAIL_SUBTITLE' | translate }}</span>
                <span class="content-content">{{ conversation.customer.email || '-' }}</span>
                <span class="content-subtitle">{{ 'INBOX.CURRENT_CONVERSATION.INFO.TITLE_SUBTITLE' | translate }}</span>
                <span class="content-content capitalize">{{ conversation.customer.title || '-' }}</span>
                <span class="content-subtitle">{{ 'INBOX.CURRENT_CONVERSATION.INFO.GENDER_SUBTITLE' | translate }}</span>
                <span class="content-content capitalize">{{ conversation.customer.gender || '-' }}</span>
                <span class="content-subtitle">{{ 'INBOX.CURRENT_CONVERSATION.INFO.DOB_SUBTITLE' | translate }}</span>
                <span class="content-content">{{ (conversation.customer.dob | tzDate) || '-' }}</span>
                <app-copy-info [label]="copyLabel" [value]="conversation.customer.id"></app-copy-info>
            </div>
        </div>
    
        <div class="section">
            <div class="section-title">
                <span class="content-title">{{ 'INBOX.CURRENT_CONVERSATION.INFO.CONVERSATION_TITLE' | translate }}</span>
                <app-expend-button class="section-expend" [expend]="hideConversationDetails" (click)="hideConversationDetailsSection()" id="hide-conversation-details-section"></app-expend-button>
            </div>
            <div @collapseTrigger class="section-info" *ngIf="!hideConversationDetails">
                <span class="content-subtitle">{{ 'INBOX.CURRENT_CONVERSATION.INFO.CONVERSATION_PHONE_SUBTITLE' | translate }}</span>
                <span class="content-content">{{ (conversation.phone | customerNumber) || '-' }}</span>
                <span class="content-subtitle">{{ 'INBOX.CURRENT_CONVERSATION.INFO.CHANNEL_SUBTITLE' | translate }}</span>
                <span class="content-content capitalize {{ conversation.type }}">{{ conversation.type }}</span>
                <span class="content-subtitle">{{ 'INBOX.CURRENT_CONVERSATION.INFO.STATUS_SUBTITLE' | translate }}</span>
                <span class="content-content capitalize">{{ conversation.status }}</span>
            </div>
        </div>
    
        <div *ngIf="conversation.hasAttachments">
            <div class="section">
                <div class="section-title">
                    <span class="content-title">{{ 'INBOX.CURRENT_CONVERSATION.INFO.ATTACHMENTS_TITLE' | translate }}</span>
                    <app-expend-button class="section-expend" [expend]="hideAttachmentsDetails" (click)="hideAttachmentsDetailsSection()" id="hide-attachment-details-section"></app-expend-button>
                </div>
                <div @collapseTrigger class="section-info" *ngIf="!hideAttachmentsDetails">
                    <app-attachment class="content-attachment" [attachment]="attachment" [withDownload]="true" [displayDate]="true" *ngFor="let attachment of conversation.attachments; trackBy: trackByAttachmentUrl"></app-attachment>
                </div>
            </div>
            <div class="progress-bar-container" *ngIf="conversation.areAttachmentsLoading">
                <mat-progress-bar mode="indeterminate"></mat-progress-bar>
            </div>
        </div>
    
        <div class="section conversation-history-section">
            <div class="section-title">
                <span class="content-title">{{ 'INBOX.CURRENT_CONVERSATION.INFO.HISTORY_TITLE' | translate }}</span>
                <app-expend-button class="section-expend" [expend]="hideHistoryDetails" (click)="hideHistoryDetailsSection()" id="hide-history-details-section"></app-expend-button>
            </div>
            <div @collapseTrigger class="section-info conversation-history" *ngIf="!hideHistoryDetails">
                <app-search class="current-conversation-info-search-bar" (callback)="searchConversationHistory($event)" [needCallback]="true" [clearOnChanges]="conversation.mainConversation.id" inputId="history-search"></app-search>
                <div *ngIf="conversation.customer.conversationHistory && conversation.customer.conversationHistory.length">
                    <app-conversation *ngFor="let conversation of conversation.customer.conversationHistory; trackBy: trackById"
                        [conversation]="conversation" [relativeDate]="true"
                        (click)="selectConversation(conversation)" [withScroll]="true" [scrollingId]="'history-' + conversation.id" [displayChannel]="true" size="history">
                    </app-conversation>
                </div>
                <mat-progress-bar *ngIf="conversation.customer.areConversationsLoading" mode="indeterminate"></mat-progress-bar>
                <app-no-conversation-placeholder [isSearchMode]="true" [noConversation]="!conversation.customer.conversationHistory || !conversation.customer.conversationHistory.length"></app-no-conversation-placeholder>
            </div>
        </div>
    </div>
</div>