<div class="conversation {{ size }}" [ngClass]="{ 'is-selected': isSelectedConversation(conversation), 'is-unread': conversation.hasUnseen }" [id]="withScroll && !withStatus ? (scrollingId || conversation.id) : 'no-scroll-container'">
    <div class="conversation-content">
        <div class="conversation-content-main-content" [ngClass]="{ 'short' : relativeDate }">
            <div class="conversation-content-main-content-channel">
                <app-conversation-channel [conversation]="conversation" [size]="size"></app-conversation-channel>
            </div>
            <div class="conversation-content-main-content-log">
                <div class="conversation-content-main-content-title">
                    <span class="conversation-content-main-content-big" [ngClass]="{ 'has-unread': conversation.hasUnseen }">{{ displayChannel ? getChannelLabel() : (conversation | conversationContact) }}</span>
                    <span class="conversation-content-main-content-status {{ conversation.status }}" *ngIf="withStatus">{{ conversation.status }}</span>
                </div>
                <div class="conversation-content-main-content-preview preview-text with-status">
                    <app-log-status class="log-status" *ngIf="displayStatus()" [status]="conversation.lastMessageStatus" [category]="conversation.type"></app-log-status>
                    <span class="preview-text-content">{{ conversation | conversationPreview }}</span>
                </div>
                <span class="conversation-content-main-content-preview">{{ getLastInteractionAtWithPipe() }}</span>
            </div>
        </div>
        <div>
            <div class="conversation-content-information">
                <span class="relative-date" *ngIf="relativeDate">{{ lastInteractionAtFromNow }}</span>
                <app-transfer-badge class="transfer-info" *ngIf="!relativeDate" [hasUnrespondedTransfer]="conversation.hasUnrespondedTransfer" [matTooltip]="'CONVERSATION.TRANSFER_TOOLTIP' | translate" matTooltipPosition="above"></app-transfer-badge>
                <app-attachment-badge class="attachment" [ngClass]="{ 'attachment-with-notification': conversation.hasUnseen || conversation.unseenCount, 'with-relative-date': relativeDate }" [hasAttachments]="conversation.hasAttachments"></app-attachment-badge>
                <app-notification class="notification" [ngClass]="{ 'with-relative-date': relativeDate }" [notificationValue]="conversation.unseenCount" [notificationDot]="conversation.hasUnseen && !conversation.unseenCount"></app-notification>
                <app-expire-info class="expire-info" *ngIf="!relativeDate" [expireHours]="conversation.expireTime" [withBorders]="true" size="small"></app-expire-info>
            </div>
        </div>
    </div>
</div>