import { Component, OnInit, Output, EventEmitter } from '@angular/core';
import { FormGroup, FormControl, Validators, AbstractControl } from '@angular/forms';
import { Router } from '@angular/router';
import { RegexService } from 'src/app/services/regex.service';
import { RouterService } from '../../../../../services/router.service';
import { UsernameService } from '../../../../../services/username.service';
import { LoginService } from '../../../login.service';

@Component({
  selector: 'app-forgot-account',
  templateUrl: './forgot-account.component.html',
  styleUrls: ['./forgot-account.component.scss', '../../connexion/connexion.component.scss']
})
export class ForgotAccountComponent implements OnInit {

  @Output() public forgotAccountNavigation = new EventEmitter<{origin: string; email: string}>();
  public forgotAccountForm = new FormGroup({});

  constructor(
    private regexService: RegexService,
    private router: RouterService,
    private login: LoginService,
    private usernameService: UsernameService,
  ) {}

  public ngOnInit(): void {
    this.forgotAccountForm = this.buildForm();
  }

  public get formControls(): { [key: string]: AbstractControl } {
    return this.forgotAccountForm.controls;
  }

  public onSent(): void {
    if (this.forgotAccountForm.valid) {
      this.login.forgotAccount(this.formControls.username.value)
        .then(() => this.forgotAccountNavigation.emit({origin: 'forgot-account', email: this.formControls.username.value}))
        .catch(() => {});
    }
  }

  public onQuit(): void {
    this.router.navigate(['login']);
  }

  private buildForm(): FormGroup {
    return new FormGroup({
      username: new FormControl(this.usernameService.username || '', [Validators.required, Validators.pattern(this.regexService.email)]),
    });
  }
}
