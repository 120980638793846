import { Injectable } from '@angular/core';
import { environment } from '../../environments/environment';
import { StorageService } from '../storage.service';

@Injectable({
  providedIn: 'root'
})
export class AccountService {
  constructor(
    private storage: StorageService,
  ) {
    this.setPremiseAccount();
  }

  public get accountName(): string {
    return this.storage.local.getAccountName();
  }

  public set accountName(name:string) {
    this.storage.local.setAccountName((name || '').toLowerCase());
  }

  public isOnPremise(): boolean {
    return Boolean(environment.premiseAccount);
  }

  private setPremiseAccount(): void {
    // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
    if (environment.premiseAccount) this.accountName = environment.premiseAccount!;
  }
}
