import { Component, Input, OnChanges, OnInit, SimpleChanges } from '@angular/core';
import _ from 'lodash';
import { Customer } from '../../agent/inbox/conversations.service';
import { CurrentUserService } from '../../services/current-user.service';
import { InfoCategory, InfoService, StorageInfos } from '../../services/info.service';

@Component({
  selector: 'app-customer-image',
  templateUrl: './customer-image.component.html',
  styleUrls: ['./customer-image.component.scss']
})
export class CustomerImageComponent implements OnInit, OnChanges {

  @Input() public customer!: any;
  @Input() public isAgent = false;
  @Input() public withStatus = false;
  @Input() public size = '';
  @Input() public displayNameLetter?: boolean;
  public storageUrl = '';
  public nameLetter = '';
  public imageSource = '';

  constructor(
    private infoService: InfoService,
    private currentUserService: CurrentUserService,
  ) {
    this.storageUrl = this.infoService.getStorageInfos().endpoint;
  }

  public ngOnInit(): void {
    if (this.displayNameLetter === undefined) {
      this.displayNameLetter = true;
      this.displayNameLetter = this.isAgent ? this.customer.id !== this.currentUserService.get().id : Boolean(this.customer.firstName);
    }
    this.imageSource = `/assets/images/whatsapp/${this.isAgent ? 'agent-placeholder' : 'profile'}.png`;
  }

  public ngOnChanges(changes: SimpleChanges): void {
    if (changes.customer && changes.customer.currentValue.firstName) this.nameLetter = changes.customer.currentValue.firstName[0];
  }

  public getCssStatus(): string {
    if (!_.isEmpty(this.customer.liveInfo)) {
      return this.cssClassOfStatus(this.customer.liveInfo.status);
    }
    return 'logged-out';
  }

  public getStatusTooltip(): string {
    return _.upperFirst(this.getCssStatus());
  }

  private cssClassOfStatus(status: string): string {
    switch (status) {
      case 'Available': return 'available';
      case 'On Break': return 'on-break';
      case 'Meeting': return 'meeting';
      case 'Outgoing': return 'outgoing';
      case 'Logged Out': return 'logged-out';
      default: return '';
    }
  }

}
