import _ from 'lodash';
import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class StorageService {
  public local: { [key: string]: Function } = {};
  private storageNameByItemName = {
    // eslint-disable-next-line @typescript-eslint/naming-convention
    'AccountName': 'ziwo-account-name',
    // eslint-disable-next-line @typescript-eslint/naming-convention
    'AccessToken': 'ziwo-access-token',
    // eslint-disable-next-line @typescript-eslint/naming-convention
    'SessionTime': 'ziwo-session-time',
    // eslint-disable-next-line @typescript-eslint/naming-convention
    'CurrentConversationId': 'current-conversation-id',
    // eslint-disable-next-line @typescript-eslint/naming-convention
    'DraftsByConversationId': 'drafts-by-conversation-id',
  };

  constructor() {
    _.forEach(this.storageNameByItemName, (storageName: string, itemName: string) => this.generateHandlersForItem(itemName, storageName));
  }

  private generateHandlersForItem(itemName: string, storageName: string): void {
    this.local['get'+itemName] = (): string | null => this.getItem(storageName);
    this.local['set'+itemName] = (itemValue: string): string => this.setItem(storageName, itemValue);
    this.local['remove'+itemName] = (): void => this.removeItem(storageName);
  }

  private getItem(storageName: string): string | null {
    const itemValue: any = localStorage.getItem(storageName);
    // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
    return JSON.parse(itemValue + '');
  }

  private setItem(storageName: string, itemValue: string): string {
    localStorage.setItem(storageName, JSON.stringify(itemValue));
    return itemValue;
  }

  private removeItem(storageName: string): void {
    localStorage.removeItem(storageName);
  }
}
