import { Component, Input, OnInit } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { ConversationLog, LogType } from '../../agent/inbox/conversations.service';
import { InboxService } from '../../agent/inbox/inbox.service';
import { FullnamePipe } from '../../pipes/fullname.pipe';
import { TzDateTimePipe } from '../../pipes/tz-date-time.pipe';

@Component({
  selector: 'app-log-timestamp',
  templateUrl: './log-timestamp.component.html',
  styleUrls: ['./log-timestamp.component.scss']
})
export class LogTimestampComponent implements OnInit {

  @Input() public size = '';
  @Input() public log!: ConversationLog;

  constructor(
    private translateService: TranslateService,
    private tzDateTimePipe: TzDateTimePipe,
    private fullnamePipe: FullnamePipe,
    private inboxService: InboxService,
  ) { }

  public ngOnInit(): void {
  }

  public getTimestampText(): string {
    const at = `${this.getTranslation('AT')} ${this.getFormattedDate(this.log.createdAt)}`;
    switch(this.log.type) {
      case LogType.Create: return `${this.getTranslation('CREATED')} ${at}`;
      case LogType.Open: return `${this.getTranslation('OPENED')} ${this.getByWho()} ${at}`;
      case LogType.Close: return `${this.getTranslation('CLOSED')} ${this.getByWho()} ${at}`;
      case LogType.Reopen: return `${this.getTranslation('REOPENED')} ${at}`;
      case LogType.Transfer:
        // eslint-disable-next-line max-len
        return `${this.getTranslation('TRANSFERRED')} ${this.getTransferredFrom()} ${this.getTranslation('TO')} ${this.getTransferredTo()} ${at}`;
      case LogType.Assign: return `${this.getTranslation('ASSIGNED')} ${this.getToWho()} ${at}`;
      case LogType.Unassign: return `${this.getTranslation('UNASSIGNED')} ${at}`;
      default: return '';
    }
  }

  private getByWho(): string {
    return this.fullnamePipe.transform(this.inboxService.getAgentsByIds()[this.log.userId]);
  }

  private getToWho(): string {
    return this.fullnamePipe.transform(this.inboxService.getAgentsByIds()[this.log.content.userId || this.log.userId]);
  }

  private getTransferredFrom(): string {
    return this.fullnamePipe.transform(this.inboxService.getAgentsByIds()[this.log.content.sourceUserId]);
  }

  private getTransferredTo(): string {
    return this.fullnamePipe.transform(this.inboxService.getAgentsByIds()[this.log.content.targetUserId]);
  }

  private getTranslation(logText: string): string {
    return this.translateService.instant(`LOG_TIMESTAMP.${logText}`);
  }

  private getFormattedDate(date: string): string {
    return this.tzDateTimePipe.transform(date);
  }
}
