import { Component, Input, OnChanges, OnInit, SimpleChanges } from '@angular/core';

@Component({
  selector: 'app-expend-button',
  templateUrl: './expend-button.component.html',
  styleUrls: ['./expend-button.component.scss']
})
export class ExpendButtonComponent implements OnInit, OnChanges {

  @Input() public iconSize = '';
  @Input() public isHorizontal = false;
  @Input() public expend = false;

  public expendChanged = false;
  constructor() { }

  public ngOnInit(): void {
  }

  public ngOnChanges(changes: SimpleChanges): void {
    if (changes.expend && changes.expend.previousValue !== undefined) {
      this.expendChanged = true;
    }
  }

  public displayClassHide(): string {
    if (this.expendChanged) {
      return this.expend ? 'hide-button' : 'show-button';
    }
    if (!this.expend) {
      return 'show-button-no-animation';
    }
    return 'hide-button-no-animation';
  }

  public displayClassExpend(): string {
    if (this.expendChanged) {
      return !this.expend ? 'hide-button' : 'show-button';
    }
    if (this.expend) {
      return 'show-button-no-animation';
    }
    return 'hide-button-no-animation';
  }
}
