<div class="connexion forgot-account forgot-password">
    <form [formGroup]="forgotPasswordForm" (ngSubmit)="onSent()">
      <h1 class="title">{{ 'LOGIN.FORGOT_ACCOUNT_PASSWORD.FORGOT_PASSWORD_TITLE' | translate }}</h1>
      <div class="login-message grey-message">{{ 'LOGIN.FORGOT_ACCOUNT_PASSWORD.SEND_MESSAGE_PASSWORD' | translate }}</div>
      
      <mat-form-field class="account-input">
        <mat-label>{{ 'LOGIN.ACCOUNT' | translate }}</mat-label>
        <input matInput id="account" type="text" formControlName="account" required>
      </mat-form-field>
      <div *ngIf="formControls.account.errors && (formControls.account.dirty || formControls.account.touched)" class="text-danger">
        <span *ngIf="formControls.account.errors.required">{{ 'LOGIN.ACCOUNT_REQUIRED' | translate }}</span>
        <span *ngIf="formControls.account.errors.pattern">{{ 'LOGIN.ACCOUNT_PATTERN' | translate }}</span>
        <span *ngIf="formControls.account.errors.maxlength">{{ 'LOGIN.ACCOUNT_LENGTH' | translate }}</span>
      </div>
      
      <mat-form-field class="email-input">
        <mat-label>{{ 'LOGIN.EMAIL' | translate }}</mat-label>
        <input matInput id="username-input" type="text" formControlName="username" required>
      </mat-form-field>
      <div *ngIf="formControls.username.errors && (formControls.username.dirty || formControls.username.touched)" class="text-danger">
        <span *ngIf="formControls.username.errors.required">{{ 'LOGIN.EMAIL_REQUIRED' | translate }}</span>
        <span *ngIf="formControls.username.errors.pattern">{{ 'LOGIN.EMAIL_PATTERN' | translate }}</span>
      </div>
      
      <app-text-button class="login-button" [text]="'LOGIN.FORGOT_ACCOUNT_PASSWORD.REQUEST_LINK' | translate" size="big" [disabled]="forgotPasswordForm.invalid" id="forgot-password-request-link"></app-text-button>
      
      <div class="navigation-link">
        <div class="link-container"><span class="link" (click)="onQuit()" id="forgot-password-navigation-back"><mat-icon class="link-icon">arrow_back</mat-icon>{{ 'LOGIN.FORGOT_ACCOUNT_PASSWORD.BACK_LOGIN' | translate }}</span></div>
      </div>
    </form>
</div>