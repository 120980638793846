import { Component, Input, OnInit } from '@angular/core';
import { Conversation, ConversationLog, Customer, LogAttachment } from '../../../../../../agent/inbox/conversations.service';

@Component({
  selector: 'app-conversation-log',
  templateUrl: './conversation-log.component.html',
  styleUrls: ['./conversation-log.component.scss']
})
export class ConversationLogComponent implements OnInit {

  @Input() public conversation!: Conversation;
  @Input() public log!: ConversationLog;
  @Input() public agent!: any;
  @Input() public customer!: Customer;
  public attachment?: LogAttachment;
  public text = '';

  constructor() { }

  public ngOnInit(): void {
    if (this.log.canCreateAttachment()) {
      this.attachment = LogAttachment.fromDbConversationAttachmentLog(this.log);
    }
    this.text = this.log.category === 'sms' ? this.log.content : this.log.content.text;
  }

  public displayTextWithLinks(): boolean {
    return !['document', 'call'].includes(this.log.type);
  }

  public trackByIndex(index: number): number {
    return index;
  }

}
