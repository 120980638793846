import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';

@Component({
  selector: 'app-count-placeholder',
  templateUrl: './count-placeholder.component.html',
  styleUrls: ['./count-placeholder.component.scss']
})
export class CountPlaceholderComponent implements OnInit {

  @Input() public count?: number | string;
  @Output() public getCount = new EventEmitter();

  constructor() { }

  public ngOnInit(): void {
  }

  public showCount(): boolean {
    return Boolean(this.count || this.count === 0);
  }

}
