import { Injectable } from '@angular/core';
import _ from 'lodash';
import moment, { Moment } from 'moment';
import { InfoService, TimezoneInfos } from './info.service';

@Injectable({
  providedIn: 'root'
})
export class TimezoneService {

  constructor(
    private infoService: InfoService,
  ) { }

  public getTzMoment(date?: string): Moment {
    return moment(date).utcOffset(this.getTimezone().offset);
  }

  public getTimezone(): TimezoneInfos {
    return this.infoService.getTimezoneInfos().name === 'UTC' ? this.getLocalTimezone() : this.infoService.getTimezoneInfos();
  }

  public getLocalTimezone(): TimezoneInfos {
    const localTimezone = _.cloneDeep(
      this.infoService.getTimezonesInfos().find(timezone => timezone.name === Intl.DateTimeFormat().resolvedOptions().timeZone)
    );
    localTimezone.local = true;
    return localTimezone;
  }
}
