<div class="list-top list logs-list">
    <div class="list-row list-row-title">
        <div class="list-row-content logs-list border-title">
            <div class="list-row-main-content layout-fill">
                <div class="container" [ngClass]="{ 'flex-40': columnName === 'Last Message', 'flex-10': columnName === 'Channel', 'flex-20': columnName === 'Date',
                    'flex-15': !['Last Message', 'Channel', 'Date'].includes(columnName) }"
                    *ngFor="let columnName of columnNames; trackBy: trackByIndex" [hidden]="!isAdmin && columnName === 'Agent'">
                    <div class="list-row-block list-row-block-generic"
                        [ngClass]="{ 'customer-phone': columnName === 'Customer Phone', 'date-info': columnName === 'Date', 'status-info': columnName === 'Status' }">
                        <div class="list-row-block-generic-name">
                            <span>{{ columnName | translate }}</span>
                            <mat-icon (click)="sortByColumn(columnName)">
                                {{ sortingColumn.field === getFieldFromColumnName(columnName) && !sortingColumn.desc ? 'keyboard_arrow_up' : 'keyboard_arrow_down' }}
                            </mat-icon>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>