import { Component, Input, OnInit } from '@angular/core';
import { Conversation } from '../../../conversations.service';

@Component({
  selector: 'app-current-conversation-info-title',
  templateUrl: './current-conversation-info-title.component.html',
  styleUrls: ['./current-conversation-info-title.component.scss']
})
export class CurrentConversationInfoTitleComponent implements OnInit {

  @Input() public conversation!: Conversation;

  constructor() { }

  public ngOnInit(): void {
  }

}
