import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { TranslateService } from '@ngx-translate/core';
import { HasPropertyPipe } from '../../../../../pipes/has-property.pipe';
import { Conversation } from '../../../conversations.service';
import { InboxService } from '../../../inbox.service';

@Component({
  selector: 'app-transfer-dialog',
  templateUrl: './transfer-dialog.component.html',
  styleUrls: ['./transfer-dialog.component.scss']
})
export class TransferDialogComponent implements OnInit {

  @Input() public conversation!: Conversation;
  @Input() public agentsList: any[] = [];
  @Input() public isLoading = false;
  @Input() public displayAgentsListErrorPlaceholder = false;
  @Output() public refreshAgentsList = new EventEmitter();

  public isTransferring = false;
  public profileForm: FormGroup = this.getForm();
  public selectedAgentId = '';
  public availableFilter = true;
  public searchAgentsText?: string;

  constructor(
    private fb: FormBuilder,
    private hasPropertyPipe: HasPropertyPipe,
    private translateService: TranslateService,
  ) {
  }

  public ngOnInit(): void {
  }

  public transfer(): void {
    if (this.disableTransfer()) {
      return;
    }
    this.isTransferring = true;
    this.conversation.transferTo(this.selectedAgentId).then((res: any) => {
      this.isTransferring = false;
    }).catch(() => {});
  }

  public trackById(index: number, agent: any): string {
    return agent.id;
  }

  public disableTransfer(): boolean {
    return !Boolean(this.selectedAgentId);
  }

  public searchAgents(searchText?: string): void {
    this.selectedAgentId = '';
    this.searchAgentsText = searchText;
  }

  public selectAgent(agentId: string): void {
    this.selectedAgentId = agentId;
  }

  public isAgentSelected(agentId: string): boolean {
    return agentId === this.selectedAgentId;
  }

  public displayAgentsListPlaceholder(): boolean {
    return this.availableFilter
      && !this.hasPropertyPipe.transform(this.agentsList, 'liveInfo.status',  'Available', this.availableFilter).length;
  }

  public getPlaceholderImageSource(): string {
    const imageName = this.displayAgentsListErrorPlaceholder ? 'unable-load' : 'no-available-agent';
    return `/assets/images/whatsapp/${imageName}.png`;
  }

  public getPlaceholderTitle(): string {
    const titleName = this.displayAgentsListErrorPlaceholder ? 'ERROR_TITLE' : 'WARNING_AVAILABLE_TITLE';
    return this.translateService.instant(`INBOX.CURRENT_CONVERSATION.INFO.TRANSFER_FORM.${titleName}`);
  }

  public getPlaceholderDescription(): string {
    const descriptionName = this.displayAgentsListErrorPlaceholder ? 'ERROR_DESCRIPTION' : 'WARNING_AVAILABLE_DESCRIPTION';
    return this.translateService.instant(`INBOX.CURRENT_CONVERSATION.INFO.TRANSFER_FORM.${descriptionName}`);
  }

  private getForm(): FormGroup {
    return this.fb.group({});
  }
}
