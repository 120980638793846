import { Component, Input, OnInit } from '@angular/core';
import { Conversation } from '../conversations.service';

@Component({
  selector: 'app-current-conversation',
  templateUrl: './current-conversation.component.html',
  styleUrls: ['./current-conversation.component.scss']
})
export class CurrentConversationComponent implements OnInit {

  @Input() public conversation?: Conversation;
  public hideInfo = false;

  constructor() {}

  public ngOnInit(): void {
  }

  public hideInfoPanel(): void {
    this.hideInfo = !this.hideInfo;
  }

}
