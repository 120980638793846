import { Component, Input, OnChanges, SimpleChanges } from '@angular/core';

@Component({
  selector: 'app-copy-info',
  templateUrl: './copy-info.component.html',
  styleUrls: ['./copy-info.component.scss']
})
export class CopyInfoComponent implements OnChanges {

  @Input() public label = 'Copy ID';
  @Input() public value = '';
  @Input() public displayValue = false;
  public recentlyCopied = false;
  public readonly timeToKeepCopiedIcon = 1000;
  private scheduledResetRecentlyCopied?: number;

  constructor() { }

  public ngOnChanges(changes: SimpleChanges): void {
    if (changes.value) {
      window.clearTimeout(this.scheduledResetRecentlyCopied);
      this.resetRecentlyCopied();
    }
  }

  public onCopy(): void {
    this.recentlyCopied = true;
    this.scheduleReset();
  }

  private scheduleReset(): void {
    this.scheduledResetRecentlyCopied = window.setTimeout(() => this.resetRecentlyCopied(), this.timeToKeepCopiedIcon);
  }

  private resetRecentlyCopied(): void {
    this.recentlyCopied = false;
  }

}
