import { Injectable } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { Conversation, ConversationLog, ConversationType } from '../agent/inbox/conversations.service';
import { InboxService } from '../agent/inbox/inbox.service';
import { InboxSocketService } from '../agent/inbox/inbox.socket.service';
import { ConversationContactPipe } from '../pipes/conversation-contact.pipe';

interface ListenerHandler {
  eventName: string;
  handling: Function;
  listener?: any;
}

interface EventData {
  result: boolean;
  content: any;
}

enum NotificationIconPath {
  WhatsApp = '/assets/images/whatsapp/notification.png',
}

@Injectable({
  providedIn: 'root'
})
export class NotificationService {
  private whatsAppMessageSound = new Audio('assets/audios/whatsapp_notification.mp3');
  private listenerHandlers: ListenerHandler[] = [
    {
      eventName: 'NewConversationLog',
      handling: (conversationLog: ConversationLog): Promise<Notification | null> => this.notifyNewConversationLog(conversationLog)
    },
    {
      eventName: 'AssignedConversation',
      handling: (conversation: Conversation): Notification | null => this.notifyAssignedConversation(conversation)
    },
  ];
  private attachmentHandlers: { logType: string; conversationPreview: string; translation: string }[] = [
    {
      logType: 'image',
      conversationPreview: 'Photo',
      translation: 'INBOX.NOTIFICATION.ATTACHMENT.IMAGE'
    },
    {
      logType: 'video',
      conversationPreview: 'Video',
      translation: 'INBOX.NOTIFICATION.ATTACHMENT.VIDEO'
    },
    {
      logType: 'audio',
      conversationPreview: 'Audio',
      translation: 'INBOX.NOTIFICATION.ATTACHMENT.AUDIO'
    },
    {
      logType: 'voice',
      conversationPreview: 'Vocal Message',
      translation: 'INBOX.NOTIFICATION.ATTACHMENT.VOICE'
    },
  ];

  constructor(
    private translateService: TranslateService,
    private conversationContactPipe: ConversationContactPipe,
    private inboxService: InboxService,
    private inboxSocketService: InboxSocketService,
  ) {
    Notification.requestPermission();
  }

  public start(): void {
    this.stop();
    this.listenerHandlers.forEach(listenerHandler => {
      const listener = (eventData: EventData): void => listenerHandler.handling(eventData.content);
      // @ts-ignore until inboxSocketService is refactored
      this.inboxSocketService['on' + listenerHandler.eventName](listener);
      listenerHandler.listener = listener;
    });
  }

  public stop(): void {
    this.listenerHandlers.forEach(listenerHandler => {
      // @ts-ignore until inboxSocketService is refactored
      if (listenerHandler.listener) this.inboxSocketService['off' + listenerHandler.eventName](listenerHandler.listener);
      delete listenerHandler.listener;
    });
  }

  protected playWAMessageSound(): void{
    this.whatsAppMessageSound.play();
  }

  private async notifyNewConversationLog(conversationLog: ConversationLog): Promise<Notification | null> {
    if (conversationLog.direction !== 'inbound' || conversationLog.category !== ConversationType.WhatsApp) return null;
    const conversation = await this.inboxService.getConversation(conversationLog.conversationId).then(res => res.content); // BE WORKAROUND
    return this.notifyWAConversation(conversation, this.getNotificationFromConversationLog(conversationLog));
  }

  private notifyAssignedConversation(conversation: Conversation): Notification | null {
    if (conversation.type !== ConversationType.WhatsApp) return null;
    return this.notifyWAConversation(conversation, this.getNotificationFromConversation(conversation));
  }

  private getNotificationFromConversationLog(conversationLog: ConversationLog): string {
    const matchingAttachmentHandler = this.attachmentHandlers.find(handler => handler.logType === conversationLog.type);
    return matchingAttachmentHandler ? this.translateService.instant(matchingAttachmentHandler.translation) : conversationLog.content.text;
  }

  private getNotificationFromConversation(conversation: Conversation): string {
    const matchingAttachmentHandler = this.attachmentHandlers.find(handler => handler.conversationPreview === conversation.preview);
    return matchingAttachmentHandler ? this.translateService.instant(matchingAttachmentHandler.translation) : conversation.preview;
  }

  private notifyWAConversation(conversation: Conversation, message = ''): Notification {
    this.playWAMessageSound();
    return this.notify(
      this.translateService.instant('INBOX.NOTIFICATION.WA_MESSAGE'),
      `${this.conversationContactPipe.transform(conversation)}: ${message}`,
      NotificationIconPath.WhatsApp
    );
  }

  private notify(title: string, body: string, iconPath: NotificationIconPath): Notification {
    const notification = new Notification(
      title,
      {
        body: body,
        icon: `${location.origin}/${iconPath}`,
      }
    );
    notification.onclick = (): void => {
      window.focus();
      notification.close();
    };
    return notification;
  }
}
