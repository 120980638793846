import { Injectable } from '@angular/core';
import _ from 'lodash';
declare global {
  interface Window {
    grecaptcha: {
      ready: (callback: any) => void;
      execute: (siteKey: string, config: any) => Promise<string>;
    };
  }
}

@Injectable({
  providedIn: 'root'
})
export class RecaptchaService {
  public loadingScriptId = 'recaptcha-load-script';
  private siteKey = '6LfFITsaAAAAAKIsaej5Jgv2TaCJ0G7GBTPrISBW';
  private isGrecaptchaLoaded = false;
  constructor() {}

  public load(): void {
    const recaptchaScript = document.createElement('script');
    _.merge(recaptchaScript, {
      id: this.loadingScriptId,
      src: 'https://www.google.com/recaptcha/api.js?hl=en&render=' + this.siteKey,
      onload: () => this.setGrepcatchaAsLoaded(),
    });
    document.head.appendChild(recaptchaScript);
  }

  public async getToken(): Promise<string> {
    await this.checkGrepcaptchaReady();
    return await window.grecaptcha.execute(this.siteKey, { action: 'homepage' });
  }

  private async checkGrepcaptchaReady(): Promise<boolean> {
    await this.checkGrepcaptchaLoaded();
    return new Promise(resolve => window.grecaptcha.ready(() => resolve(true)));
  }

  private async checkGrepcaptchaLoaded(): Promise<boolean> {
    if (this.isGrecaptchaLoaded) return true;

    const recaptchaLoadingScript = document.getElementById(this.loadingScriptId);
    // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
    return new Promise(resolve => recaptchaLoadingScript!.onload = (): void => {
      this.setGrepcatchaAsLoaded();
      resolve(true);
    });
  }

  private setGrepcatchaAsLoaded(): void {
    this.isGrecaptchaLoaded = true;
  }
}
