import { animate, style, transition, trigger } from '@angular/animations';
import { Component, Input, OnInit } from '@angular/core';
import { MatDialog, MatDialogConfig, MatDialogRef } from '@angular/material/dialog';
import { TranslateService } from '@ngx-translate/core';
import { patient } from 'src/app/decorators/patient';
import { Conversation, Attachment, ConversationsService } from '../../conversations.service';
import { CustomerProfileDialogComponent } from './customer-profile-dialog/customer-profile-dialog.component';

@Component({
  animations: [
    trigger('enterParentTrigger', [
      transition(':enter', [])
    ]),
    trigger('collapseTrigger', [
      transition(':enter', [
        style({ height: 0 }),
        animate('300ms', style({ height: '*' })),
      ]),
      transition(':leave', [
        animate('300ms', style({ height: 0 })),
      ])
    ]),
  ],
  selector: 'app-current-conversation-info',
  templateUrl: './current-conversation-info.component.html',
  styleUrls: ['./current-conversation-info.component.scss']
})
export class CurrentConversationInfoComponent implements OnInit {

  @Input() public conversation!: Conversation;
  public hideProfileDetails = false;
  public hideConversationDetails = false;
  public hideAttachmentsDetails = false;
  public hideHistoryDetails = false;
  public searchFilter = '';
  public readonly copyLabel = this.translateService.instant('INBOX.CURRENT_CONVERSATION.INFO.COPY_CUSTOMER_ID');

  private dialogRef?:MatDialogRef<CustomerProfileDialogComponent>;

  constructor(
    private translateService: TranslateService,
    private dialog: MatDialog,
    private conversationsService: ConversationsService,
  ) { }

  @patient()
  private filterConversationHistories(searchText: string): void {
    this.conversation.customer.filterConversationHistories(searchText);
  }

  public ngOnInit(): void {
  }

  public openProfileEditForm(): void {
    const dialogConfig = new MatDialogConfig();
    dialogConfig.disableClose = true;
    dialogConfig.autoFocus = false;
    dialogConfig.data = { customer: this.conversation.customer };
    this.dialogRef = this.dialog.open(CustomerProfileDialogComponent, dialogConfig);
  }

  public closeProfileEditForm(): void {
    if (this.dialogRef) {
      this.dialogRef.close();
      delete this.dialogRef;
      this.dialogRef = undefined;
    }
  }

  public searchConversationHistory(searchText: string): void {
    this.searchFilter = searchText;
    this.filterConversationHistories(this.searchFilter);
  }

  public isConversationHistorySelected(conversation: Conversation): boolean {
    return conversation.id === this.conversation.id;
  }

  public selectConversation(conversation: Conversation): void {
    if (!this.conversationsService.isSelectedConversation(conversation)) this.conversationsService.selectHistoryConversation(conversation);
  }

  public hideProfileDetailsSection(): void {
    this.hideProfileDetails = !this.hideProfileDetails;
  }

  public hideConversationDetailsSection(): void {
    this.hideConversationDetails = !this.hideConversationDetails;
  }

  public hideAttachmentsDetailsSection(): void {
    this.hideAttachmentsDetails = !this.hideAttachmentsDetails;
  }

  public hideHistoryDetailsSection(): void {
    this.hideHistoryDetails = !this.hideHistoryDetails;
  }

  public trackByAttachmentUrl(index: number, attachment: Attachment): string {
    return attachment.url;
  }

  public trackById(index: number, conversation: Conversation): string {
    return conversation.id;
  }

  public onScroll(event: any): void {
    // visible height + pixel scrolled === total height
    if (event.target.offsetHeight + event.target.scrollTop === event.target.scrollHeight) {
      this.scrollMore();
    }
  }

  private scrollMore(): void {
    if (this.conversation.customer.scrollConversationHistory
      && !this.conversation.customer.areConversationsLoading
      && (this.conversation.customer.canScrollConversationHistory
        || this.searchFilter !== this.conversation.customer.previousSearchFilter)) {
      this.conversation.customer.scrollConversationHistory(this.searchFilter);
    }
  }
}
