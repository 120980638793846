
<div [formGroup]="form">
    <mat-form-field [class]="passwordWidth">
        <mat-label>{{ inputLabel | translate }}</mat-label>
        <input class="input-with-visibility" matInput id="password-component-input" [type]="passwordVisible ? 'text' : 'password'" [formControlName]="modelName" [required]="passwordRequired">
    </mat-form-field>
    <button class="password-visibility" (click)="togglePassword()" mat-icon-button aria-label="Password visibility button" tabindex="-1" type="button" id="password-input-toggle-visibility">
        <mat-icon>{{ passwordVisible ? 'visibility_off' : 'visibility' }}</mat-icon>
    </button>
    <div *ngIf="displayErrors()" class="text-danger">
        <span *ngIf="displayRequiredError()">{{ 'LOGIN.PASSWORD_REQUIRED' | translate }}</span>
        <span *ngIf="displayPatternError()">{{ 'LOGIN.PASSWORD_PATTERN' | translate }}</span>
        <span *ngIf="displayMatchError()">{{ 'LOGIN.PASSWORD_MATCH' | translate }}</span>
    </div>
    <app-password-check [form]="form" [modelName]="modelName" *ngIf="displayPasswordCheck"></app-password-check>
</div>