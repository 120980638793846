<div class="connexion">
    <form [formGroup]="passwordForm" (ngSubmit)="onSent()">
      <h1 class="title">{{ (mode === 'creation' ? 'LOGIN.SET_PASSWORD.SET_PASSWORD' : 'LOGIN.SET_PASSWORD.RESET_PASSWORD') | translate }}</h1>
      <span class="login-message">
        <span>{{ (mode === 'creation' ? 'LOGIN.SET_PASSWORD.SET_PASSWORD_INFO' : 'LOGIN.SET_PASSWORD.RESET_PASSWORD_INFO') | translate }}</span>
        <span class="login-message-account">- {{ accountName }}</span>
      </span>

      <mat-form-field class="hidden-input"><!-- Hidden but used for Chrome to save username in credentials -->
        <input matInput type="email" autocomplete="email" [value]="username" id="set-password-input" required>
      </mat-form-field>

      <app-password-input [form]="passwordForm" [displayPasswordCheck]="true" [hideError]="true" autocomplete="new-password"></app-password-input>
      <app-password-input [form]="passwordForm" modelName="confirmPassword" [hideError]="true" autocomplete="new-password"></app-password-input>

      <app-text-button class="login-button" [text]="(mode === 'creation' ? 'LOGIN.SET_PASSWORD.SET_PASSWORD_BUTTON' : 'LOGIN.SET_PASSWORD.RESET_PASSWORD_BUTTON') | translate" [disabled]="passwordForm.invalid" size="big" id="set-password-login"></app-text-button>
    </form>
</div>