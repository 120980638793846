import { Component, Input, OnInit } from '@angular/core';
import { Customer } from '../../../../conversations.service';

@Component({
  selector: 'app-transfer-dialog-agent',
  templateUrl: './transfer-dialog-agent.component.html',
  styleUrls: ['./transfer-dialog-agent.component.scss']
})
export class TransferDialogAgentComponent implements OnInit {

  @Input() public agent: any;
  @Input() public isSelected = false;

  constructor() { }

  public ngOnInit(): void {
  }

}
