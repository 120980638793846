<div class="conversation-contact">
    <div class="title">
      <span *ngIf="contactContent.name && contactContent.name.formattedName">{{ contactContent.name.formattedName }}</span>
      <span *ngIf="showOrg">{{ organisation }}</span>
    </div>
    <span class="section" *ngIf="showUrls">
      <span class="subtitle">URLs:</span>
      <div *ngFor="let url of urls; trackBy: trackByIndex"><span>{{ url }}</span></div>
    </span>
    <span class="section" *ngIf="showPhones">
      <span class="subtitle">Phones:</span>
      <div *ngFor="let phone of phones; trackBy: trackByIndex"><span>{{ phone }}</span></div>
    </span>
    <span class="section" *ngIf="showEmails">
      <span class="subtitle">Emails:</span>
      <div *ngFor="let email of emails; trackBy: trackByIndex"><span>{{ email }}</span></div>
    </span>
    <span class="section" [ngClass]="{ 'with-subsection': addresses && addresses.length }" *ngIf="showAddresses">
      <span class="subtitle">Addresses:</span>
      <div class="subsection" *ngFor="let address of addresses; trackBy: trackByIndex"><span>{{ address }}</span></div>
    </span>
    <span class="section" *ngIf="contactContent.birthday"><span class="subtitle">Birthday:</span>{{ ' ' + contactContent.birthday }}</span>
</div>