import { Pipe, PipeTransform } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import _ from 'lodash';


@Pipe({
  name: 'fullname',
  pure: false
})
export class FullnamePipe implements PipeTransform {

  constructor(
    private translateService: TranslateService,
  ) {
  }

  public transform(object: {[key: string]: any}): string {
    if (!object) {
      return '';
    }
    return _.trim(`${_.upperFirst(object.firstName)} ${_.upperFirst(object.lastName)}`)
      || _.upperFirst(this.translateService.instant('SHARED.GENERAL.UNKNOWN'));
  }

}
