import { Injectable } from '@angular/core';
import _ from 'lodash';
import { Observable } from 'rxjs';
import { ApiService } from '../api/api.service';
import { InfoboxService } from '../components/infobox/infobox.service';
import { LoadingScreenService } from '../components/loading-screen/loading-screen.service';
import { InfoService } from './info.service';

@Injectable({
  providedIn: 'root'
})
export class UploaderService {

  constructor(
    private apiService: ApiService,
    private infoboxService: InfoboxService,
    private infoService: InfoService,
    private loadingScreenService: LoadingScreenService
  ) { }

  public getFilesFromDataTransfer(dataTransfer: DataTransfer): File[] {
    return _.filter(dataTransfer.files, (file: File, fileIndex: number) => {
      const currentItem = dataTransfer.items[fileIndex];
      return currentItem.webkitGetAsEntry().isFile;
    }) as File[];
  }

  public fileFromBlob(blob: Blob, name: string): File {
    return new File([blob], name, { type: blob.type });
  }

  public async uploadFile(type: string, file: File, moduleName = 'uploader'): Promise<any> {
    try {
      this.checkFileForStorage(type, file);
    } catch (storageError: any) {
      this.infoboxService.error(storageError);
      return { status: false, errorMessage: storageError };
    }
    this.loadingScreenService.show();
    const uploadRes = await this.upload(moduleName, type, file);
    this.loadingScreenService.hide();
    if (!uploadRes.result) this.infoboxService.error(uploadRes.errorMessage || uploadRes.error.error.message);
    return uploadRes;
  }

  private checkFileForStorage(type: string, file: File): void {
    // @ts-ignore the type is a MimeType
    if (!type || !this.infoService.getStorageMimeTypesInfos()[type].includes(file.type)) {
      throw new Error(`This type of file is not allowed to upload for ${type}s`);
    }
    // @ts-ignore the type is a MimeType
    const sizeLimits = this.infoService.getStorageUploadSizeLimitsInfos()[type];
    if ((file.size / 1000) > sizeLimits) {
      throw new Error(`The file you are triyng to upload is too large. Maximum size allowed for a ${type} is ${sizeLimits}KB.`);
    }
  }

  private upload(moduleName: string, type: string, file: File): Promise<any> {
    const formData: FormData = new FormData();
    formData.append('file', file, file.name);
    const result = this.apiService.postRequest(`/${moduleName}/storage/${type}`, formData);
    return result;
  }
}
