import { Component, OnDestroy, OnInit } from '@angular/core';
import { StorageService } from '../../storage.service';
import { Conversation, ConversationsService } from './conversations.service';

@Component({
  selector: 'app-inbox',
  templateUrl: './inbox.component.html',
  styleUrls: ['./inbox.component.scss']
})
export class InboxComponent implements OnInit, OnDestroy {

  constructor(
    private conversationsService : ConversationsService,
  ) { }

  public ngOnInit(): void {
    window.addEventListener('unload', (): void => {
      this.beforeLeavingInbox();
    });
  }

  public ngOnDestroy(): void {
    this.beforeLeavingInbox();
  }

  public get currentConversation(): Conversation | undefined {
    return this.conversationsService.currentConversation;
  }

  private beforeLeavingInbox(): void {
    this.conversationsService.saveDrafts();
  }
}
