import { Component, Input, OnInit } from '@angular/core';
import { MatIconRegistry } from '@angular/material/icon';
import { DomSanitizer } from '@angular/platform-browser';
import _ from 'lodash';
import { ConversationType } from '../../agent/inbox/conversations.service';
import { MappingsByChannelInterface } from '../channel/channel.component';

@Component({
  selector: 'app-log-status',
  templateUrl: './log-status.component.html',
  styleUrls: ['./log-status.component.scss']
})
export class LogStatusComponent implements OnInit {

  @Input() public status?: string | null;
  @Input() public category = '';

  public smsIconByCategory: MappingsByChannelInterface = {
    pending: { icon: 'pending', label: 'sms-pending' },
    sent: { icon: 'sms', label: 'sms-sent' },
    delivered: { icon: 'delivered', label: 'sms-delivered' },
    failed: { icon: 'failed', label: 'sms-failed' },
  };
  public iconsByStatus: MappingsByChannelInterface = {
    pending: { icon: 'schedule', label: 'WhatsApp-pending' },
    sent: { icon: 'done', label: 'WhatsApp-sent' },
    delivered: { icon: 'done_all', label: 'WhatsApp-delivered' },
    seen: { icon: 'done_all', label: 'WhatsApp-seen' },
    failed: { icon: 'close', label: 'WhatsApp-failed' },
  };

  constructor(
    private matIconRegistry: MatIconRegistry,
    private domSanitizer: DomSanitizer
  ) {
    _.map(this.smsIconByCategory, (mapping:any) => {
      this.matIconRegistry.addSvgIcon(
        mapping.label,
        this.domSanitizer.bypassSecurityTrustResourceUrl(`/assets/images/sms/${mapping.icon}.svg`)
      );
    });
  }

  public ngOnInit(): void {
    if (this.category === ConversationType.Sms) {
      this.iconsByStatus = this.smsIconByCategory;
    }
  }

  public isStatusSms(): boolean {
    return status === ConversationType.Sms;
  }

}
