import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import _ from 'lodash';
import { ConversationCategory } from '../../../../agent/inbox/conversations.service';
import { CurrentUserService } from '../../../../services/current-user.service';
import { ConfigFilters, ConversationsModuleService } from '../../conversations-module.service';

@Component({
  selector: 'app-table-module-filters',
  templateUrl: './conversations-table-module-filters.component.html',
  styleUrls: ['./conversations-table-module-filters.component.scss']
})
export class ConversationsTableModuleFiltersComponent implements OnInit {

  @Output() public resetFilters = new EventEmitter<void>();
  @Output() public handleChange = new EventEmitter<boolean | undefined>();
  @Input() public selectedFilters: ConfigFilters = {};
  @Input() public channels: string[] = [];
  public isAdmin = this.currentUserService.isAdmin();
  public readonly statusList = [ConversationCategory.New, ConversationCategory.Open, ConversationCategory.Closed];

  constructor(
    private conversationsModuleService: ConversationsModuleService,
    private currentUserService: CurrentUserService,
  ) { }

  public ngOnInit(): void {
  }

  public hasFilters(): boolean {
    return _.some(this.selectedFilters, (value: any): boolean => Boolean(value));
  }

}
