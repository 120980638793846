import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { CurrentUserService } from '../../../../../services/current-user.service';
import { ConversationsModuleService, SortingColumn } from '../../../conversations-module.service';

@Component({
  selector: 'app-table-module-table-title',
  templateUrl: './conversations-table-module-table-title.component.html',
  styleUrls: ['./conversations-table-module-table-title.component.scss']
})
export class ConversationsTableModuleTableTitleComponent implements OnInit {

  @Input() public columns: {[key: string]: string} = {};
  @Output() private getNextConversations = new EventEmitter();

  public isAdmin = this.currentUserService.isAdmin();
  public columnNames: string[] = [];

  constructor(
    private currentUserService: CurrentUserService,
    private conversationsModuleService: ConversationsModuleService,
  ) { }

  public ngOnInit(): void {
    this.columnNames = Object.keys(this.columns);
  }

  public get sortingColumn(): SortingColumn {
    return this.conversationsModuleService.sortingColumn;
  }

  public set sortingColumn(sortingColumn: SortingColumn) {
    this.conversationsModuleService.sortingColumn = sortingColumn;
  }

  public sortByColumn(columnName: string): void {
    if (this.sortingColumn.field === this.getFieldFromColumnName(columnName)) {
      this.sortingColumn.desc = !this.sortingColumn.desc;
    } else this.sortingColumn = {
      field: this.getFieldFromColumnName(columnName),
      desc: !this.conversationsModuleService.defaultSortingColumn.desc
    };
    this.getNextConversations.emit();
  }

  public getFieldFromColumnName(columnName: string): string {
    return this.columns[columnName];
  }

  public trackByIndex(index: number): number {
    return index;
  }
}
