<div class="focused-conversation-details">
    <div class="focused-conversation-details-title">
        <app-expend-button class="focused-conversation-details-title-close" [expend]="expend" (click)="closeConversation()" [isHorizontal]="true"></app-expend-button>
        <span *ngIf="focusedConversation.customer.firstName">{{ focusedConversation.customer | fullname }}&nbsp;|&nbsp;</span>
        <span>{{ focusedConversation.phone | customerNumber }}</span>
        <app-navigation-button *ngIf="showNavigationButton" destination="customerProfile" paramName="id" [paramValue]="focusedConversation.customer.id"></app-navigation-button>
    </div>
    <div class="focused-conversation-details-summary">
        <app-conversation-channel [conversation]="focusedConversation" size="big"></app-conversation-channel>
        <div class="focused-conversation-details-summary-info">
            <span [hidden]="!focusedConversation.userId">{{ 'CONVERSATIONS.DETAILS.ASSIGNED' | translate }}</span>
            <span class="subtitle" [hidden]="!focusedConversation.userId">{{ focusedConversation.agentDisplayed }}</span>
            <span class="subtitle unassigned" [hidden]="focusedConversation.userId">{{ 'CONVERSATIONS.DETAILS.UNASSIGNED' | translate }}</span>
        </div>
    </div>
    <div class="focused-conversation-details-identification">
        <div class="subtitle">{{ 'CONVERSATIONS.DETAILS.ID:' | translate }}</div>
        <app-copy-info [value]="focusedConversation.id" [label]="'CONVERSATIONS.DETAILS.ID' | translate" [displayValue]="true"></app-copy-info>
    </div>
    <div class="focused-conversation-details-info flex-100-vertical">
        <div class="focused-conversation-details-info-column">
            <div class="focused-conversation-details-info-column-content flex-100">
                <div class="first-content column-content">
                    <span class="subtitle flex-50">{{ 'CONVERSATIONS.DETAILS.CREATED' | translate }}</span>
                    <span class="value flex-50">{{ (focusedConversation.createdAt | tzDateTime : undefined : true) || '-' }}</span>
                </div>
                <div class="first-content column-content">
                    <span class="subtitle flex-50">{{ 'CONVERSATIONS.DETAILS.FIRST_RESPONSE' | translate }}</span>
                    <span class="value flex-50">{{ (focusedConversation.firstResponse | tzDateTime : undefined : true) || '-' }}</span>
                </div>
                <div class="first-content column-content">
                    <span class="subtitle flex-50">{{ 'CONVERSATIONS.DETAILS.CLOSED_AT' | translate }}</span>
                    <span class="value flex-50">{{ focusedConversation.status === 'closed' ? (focusedConversation.closedAt | tzDateTime : undefined : true) : '-' }}</span>
                </div>
                <div class="first-content column-content flex-100-vertical"></div>
            </div>
        </div>
        <div class="focused-conversation-details-info-column">
            <div class="focused-conversation-details-info-column-content flex-100">
                <div class="column-content">
                    <span class="subtitle flex-50">{{ 'CONVERSATIONS.DETAILS.STATUS' | translate }}</span>
                    <span class="value flex-50">{{ focusedConversation.status || '-' }}</span>
                </div>
                <div class="column-content">
                    <span class="subtitle flex-50">{{ 'CONVERSATIONS.DETAILS.TOTAL_RESPONSE' | translate }}</span>
                    <span class="value flex-50">{{ (focusedConversation.totalResponseTime | secondsToTime) || '-' }}</span>
                </div>
                <div class="column-content">
                    <span class="subtitle flex-50">{{ 'CONVERSATIONS.DETAILS.TOTAL_RESOLUTION' | translate }}</span>
                    <span class="value flex-50">{{ (focusedConversation.totalResolutionTime | secondsToTime) || '-' }}</span>
                </div>
            </div>
        </div>
    </div>
</div>