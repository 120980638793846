import { Component, OnInit } from '@angular/core';
import { MatIconRegistry } from '@angular/material/icon';
import { DomSanitizer } from '@angular/platform-browser';
import { TranslateService } from '@ngx-translate/core';
import { LayoutComponent, SideMenu } from '../components/layout/layout.component';
import { LoginService } from '../public/login/login.service';
import { CurrentUserService } from '../services/current-user.service';
import { RouterService } from '../services/router.service';

@Component({
  selector: 'app-admin',
  templateUrl: '../components/layout/layout.component.html',
  styleUrls: ['./admin.component.scss', '../components/layout/layout.component.scss']
})
export class AdminComponent extends LayoutComponent {

  constructor(
    public currentUserService: CurrentUserService,
    protected router: RouterService,
    protected translateService: TranslateService,
    protected loginService: LoginService,
    protected matIconRegistry: MatIconRegistry,
    protected domSanitizer: DomSanitizer,
  ) {
    super(currentUserService, router, translateService, loginService, matIconRegistry, domSanitizer);
  }

  public getProfileRoute(): string {
    return '/admin/profile';
  }

  protected getMenu(): SideMenu {
    return {
      items: [
        {
          icon: 'dialpad',
          title: this.translateService.instant('LAYOUT.TOOLTIP.DIALPAD'),
          route: '/admin/home',
        },
        {
          icon: 'analytics',
          title: this.translateService.instant('LAYOUT.TOOLTIP.DASHBOARD'),
          route: '/admin/dashboard',
        }
      ],
      more: [
        {
          icon: '',
          title: 'More menu title',
          route: ''
        },
        {
          icon: 'lens',
          title: 'More menu item',
          route: '/admin/home'
        }
      ],
    };
  }

}
