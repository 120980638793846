import { Component, Input, OnInit } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import _ from 'lodash';

interface ContentObject {
  [key: string]: any;
}

interface ContactContent {
  [key: string]: any;
  name?: ContentObject;
  org?: ContentObject;
  urls?: ContentObject[];
  phones?: ContentObject[];
  emails?: ContentObject[];
  addresses?: ContentObject[];
  birthday?: string;
}

@Component({
  selector: 'app-conversation-contact',
  templateUrl: './conversation-contact.component.html',
  styleUrls: ['./conversation-contact.component.scss']
})
export class ConversationContactComponent implements OnInit {

  @Input() public contactContent: ContactContent = {};
  public showOrg = false;
  public showUrls = false;
  public showPhones = false;
  public showEmails = false;
  public showAddresses = false;
  public organisation = '';
  public urls: string[] = [];
  public phones: string[] = [];
  public emails: string[] = [];
  public addresses: string[] = [];

  constructor(
    protected translateService: TranslateService,
  ) { }

  public ngOnInit(): void {
    this.showOrg = _.some(this.contactContent.org, (orgValue: ContentObject) => Boolean(orgValue));
    this.showUrls = Boolean(this.contactContent.urls && this.contactContent.urls.length);
    this.showPhones = Boolean(this.contactContent.phones && this.contactContent.phones.length);
    this.showEmails = Boolean(this.contactContent.emails && this.contactContent.emails.length);
    this.showAddresses = Boolean(this.contactContent.addresses && this.contactContent.addresses.length);

    this.organisation = this.getOrgInfo();
    this.urls = this.getURLsInfo();
    this.phones = this.getPhonesInfo();
    this.emails = this.getEmailsInfo();
    this.addresses = this.getAddressesInfo();
  }

  public getOrgInfo(): string {
    if (!this.contactContent.org) {
      return '';
    }
    let _orgInfo = (this.contactContent.org.title || '') + ' ';
    if (this.contactContent.org.department) {
      _orgInfo += this.translate('DEPARTMENT') + ' ' + this.contactContent.org.department + ' ';
    }
    if (this.contactContent.org.company) {
      _orgInfo += this.translate('AT') + ' ' + this.contactContent.org.company;
    }
    return _orgInfo;
  }

  public getURLsInfo(): string[] {
    if (!this.contactContent.urls) {
      return [];
    }
    return this.contactContent.urls.map(url => (url.type ? _.capitalize(url.type) + ': ' : '') + (url.url || url.phone));
  }

  public getPhonesInfo(): string[] {
    if (!this.contactContent.phones) {
      return [];
    }
    return this.contactContent.phones.map(phone => (phone.type ? phone.type + ': ' : '') + phone.phone);
  }

  public getEmailsInfo(): string[] {
    if (!this.contactContent.emails) {
      return [];
    }
    return this.contactContent.emails.map(email => (email.type ? email.type + ': ' : '') + email.email);
  }

  public getAddressesInfo(): string[] {
    if (!this.contactContent.addresses) {
      return [];
    }
    return this.contactContent.addresses.map(address => {
      let _address = address.type ? address.type + ': ' : '';
      _address += address.street || '';
      _address += address.city ? (_address ? ', ' : '') + address.city : '';
      _address += address.country ? (_address ? ', ' : '') + address.country : '';
      if (address.zip) {
        _address += (_address ? '\n' : '') + this.translate('ZIP') + ' ' + address.zip;
      }
      if (address.countryCode) {
        _address += (_address ? '\n' : '') + this.translate('COUNTRY_CODE') + ' ' + address.countryCode;
      }
      return _address;
    });
  }

  public trackByIndex(index: number, item: string): number {
    return index;
  }

  private translate(translatedText: string): string {
    return this.translateService.instant(`INBOX.CURRENT_CONVERSATION.LOGS.CONTACT.${translatedText}`);
  }

}
