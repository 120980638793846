<div class="profile">
    <mat-toolbar class="administration-toolbar">
        <div class="administration-title">{{ 'PROFILE.TITLE' | translate }}</div>
    </mat-toolbar>

    <div class="module-content profile-content">
        <form class="administration-form" [formGroup]="profileForm">
            <mat-form-field class="hidden-input">
                <!-- Hidden but used for Chrome to save username in credentials -->
                <input matInput placeholder="Username" email autocomplete="username">
            </mat-form-field>
            <mat-form-field class="hidden-input">
                <!-- Hidden but used for Chrome to save password in credentials -->
                <input matInput placeholder="Password" type="password" autocomplete="new-password">
            </mat-form-field>

            <div class="administration-form-head" #imageSelectorElement [ngClass]="{ 'dragover-dark': isDraggingOver }"
                (dragover)="onDragOver($event)" (dragleave)="onDragLeave($event)" (drop)="onDropSuccess($event)">
                <div *ngIf="!imageSelector.image" class="administration-form-head-image">
                    <app-user-picture></app-user-picture>
                </div>
                <div class="administration-form-head-image-cropper" *ngIf="imageSelector.image">
                    <image-cropper
                        [imageChangedEvent]="imageChangedEvent"
                        [maintainAspectRatio]="true"
                        [aspectRatio]="1 / 1"
                        format="png"
                        (imageCropped)="imageCropped($event)"
                        [roundCropper] = true>
                    </image-cropper>
                </div>
                <div>
                    <div class="administration-form-head-text">{{ currentUser | fullname }}</div>
                    <div class="administration-form-head-text administration-form-head-text-no-height">
                        <span class="administration-form-head-text-title">Your Account Name:&nbsp;</span>
                        <span class="administration-form-head-text-subtext">{{ instanceName }}</span>
                    </div>
                </div>
                <div class="administration-form-head-upload">
                    <input hidden type="file" #imageUploader (change)="selectFromEvent($event)" accept="image/*" id="uploader-input"/>
                    <button class="uploader-button" mat-fab (click)="imageUploader.click()" id="uploader-button"[matTooltip]="'PROFILE.UPLOAD_TOOLTIP' | translate">
                        <mat-icon>image</mat-icon>
                    </button>
                </div>
            </div>

            <mat-dialog-content>
                <div class="dialog-content">
                    <h3>Personal Info</h3>

                    <mat-form-field class="field-title">
                      <mat-label>{{ 'PROFILE.FIRST_NAME' | translate }}</mat-label>
                      <input matInput formControlName="firstName" required>
                    </mat-form-field>
                    <div *ngIf="displayMessages('firstName')" class="text-danger">
                      <span *ngIf="formControls.firstName && formControls.firstName.errors?.required">{{ 'PROFILE.ERROR.FIRST_NAME_REQUIRED' | translate }}</span>
                      <span *ngIf="formControls.firstName && formControls.firstName.errors?.pattern">{{ 'PROFILE.ERROR.FIRST_NAME_PATTERN' | translate }}</span>
                    </div>

                    <mat-form-field class="field-title">
                      <mat-label>{{ 'PROFILE.LAST_NAME' | translate }}</mat-label>
                      <input matInput formControlName="lastName" required>
                    </mat-form-field>
                    <div *ngIf="displayMessages('lastName')" class="text-danger">
                      <span *ngIf="formControls.lastName && formControls.lastName.errors?.required">{{ 'PROFILE.ERROR.LAST_NAME_REQUIRED' | translate }}</span>
                      <span *ngIf="formControls.lastName && formControls.lastName.errors?.pattern">{{ 'PROFILE.ERROR.LAST_NAME_PATTERN' | translate }}</span>
                    </div>

                    <mat-form-field class="field-title">
                      <mat-label>{{ 'PROFILE.CONTACT_NUMBER' | translate }}</mat-label>
                      <input matInput formControlName="contactNumber">
                    </mat-form-field>
                    <div *ngIf="displayMessages('contactNumber')" class="text-danger">
                      <span *ngIf="formControls.contactNumber && formControls.contactNumber.errors?.pattern">{{ 'PROFILE.ERROR.CONTACT_NUMBER_PATTERN' | translate }}</span>
                    </div>

                    <h3>Change Password</h3>
                    
                    <app-password-input [form]="profileForm" modelName="oldPassword" [hideError]="false" autocomplete="new-password" [passwordRequired]="false" inputLabel="PROFILE.CURRENT_PASSWORD"></app-password-input>
                    <div *ngIf="displayMessages('oldPassword')" class="text-danger password-error">
                      <span *ngIf="formControls.oldPassword?.errors?.conditionalRequired">{{ 'LOGIN.PASSWORD_PATTERN' | translate }}</span>
                    </div>
                    <app-password-input [form]="profileForm" modelName="newPassword" [hideError]="true" autocomplete="new-password" [passwordRequired]="false" inputLabel="PROFILE.NEW_PASSWORD"></app-password-input>
                    <app-password-input [form]="profileForm" modelName="confirmPassword" [hideError]="true" autocomplete="new-password" [passwordRequired]="false" inputLabel="PROFILE.CONFIRM_PASSWORD"></app-password-input>
                    <app-password-check [form]="profileForm" modelName="newPassword" *ngIf="displayPasswordCheck()"></app-password-check>
                </div>
            </mat-dialog-content>
            
            <div class="profile-save">
                <app-text-button [text]="'PROFILE.SAVE' | translate" [disabled]="profileForm.invalid" size="medium" (click)="onSave()"></app-text-button>
            </div>
        </form>
    </div>
</div>