<div class="administration-form profile-form">
  <mat-toolbar mat-dialog-title>
    <div class="title">{{ getFormTitle() }}</div>
    <button class="close-button" mat-icon-button (click)="close()" id="close-customer-profile-dialog">
      <mat-icon>close</mat-icon>
    </button>
  </mat-toolbar>

  <mat-dialog-content [formGroup]="profileForm">
    <div class="dialog-content profile-form-content">
      <div class="fields-row">
        <div class="field-title">
          <mat-form-field [hidden]="isTitleCustom">
            <mat-label>{{ getTranslatedText('TITLE') }}</mat-label>
            <mat-select class="profile-form-content-select" formControlName="title" #selectCustomerProfileTitle>
              <mat-option [value]="" class="profile-form-content-select" (click)="titleIsCustom()" id="make-title-custom">
                {{ getTranslatedText('CUSTOM') }}</mat-option>
              <mat-option [value]="title" *ngFor="let title of titleList; trackBy: trackByIndex"
                class="profile-form-content-select">{{ title }}</mat-option>
              <mat-option [value]="''" class="profile-form-content-select special-option">
                {{ getTranslatedText('NONE') }}</mat-option>
            </mat-select>
          </mat-form-field>

          <mat-form-field [hidden]="!isTitleCustom">
            <mat-label>{{ getTranslatedText('TITLE') }}</mat-label>
            <input matInput formControlName="titleInput" id="customer-profile-title-input" #customerProfileTitleInput>
            <mat-icon class="select-titles" (click)="titleIsChosen()" id="make-title-selected">arrow_drop_down</mat-icon>
          </mat-form-field>
          <div *ngIf="displayMessages('titleInput')" class="text-danger field-title-error">
            <span *ngIf="hasRegexErrors(regexService.maxChars, 'titleInput')">{{ getTranslatedText('TITLE_INPUT_MAX_CHARS') }}</span>
          </div>
        </div>

        <div class="field-first-name">
          <mat-form-field>
            <mat-label>{{ getTranslatedText('FIRST_NAME') }}</mat-label>
            <input matInput formControlName="firstName" id="customer-profile-firstname-input" required>
          </mat-form-field>

          <div *ngIf="displayMessages('firstName')" class="text-danger">
            <span *ngIf="formControls.firstName && formControls.firstName.errors?.required">{{ getTranslatedText('FIRST_NAME_REQUIRED') }}</span>
            <span *ngIf="hasRegexErrors(regexService.alphaNum, 'firstName')">{{ getTranslatedText('FIRST_NAME_PATTERN') }}</span>
            <span *ngIf="hasRegexErrors(regexService.maxChars, 'firstName')">{{ getTranslatedText('FIRST_NAME_MAX_CHARS') }}</span>
          </div>
        </div>

        <div class="field-last-name">
          <mat-form-field>
            <mat-label>{{ getTranslatedText('LAST_NAME_LABEL') }}</mat-label>
            <input matInput formControlName="lastName" id="customer-profile-lastname-input">
          </mat-form-field>

          <div *ngIf="displayMessages('lastName')" class="text-danger">
            <span *ngIf="hasRegexErrors(regexService.alphaNum, 'lastName')">{{ getTranslatedText('LAST_NAME_PATTERN') }}</span>
            <span *ngIf="hasRegexErrors(regexService.maxChars, 'lastName')">{{ getTranslatedText('LAST_NAME_MAX_CHARS') }}</span>
          </div>
        </div>
      </div>

      <div class="fields-row">
        <mat-form-field class="field-gender">
          <mat-label>{{ getTranslatedText('GENDER') }}</mat-label>
          <mat-select formControlName="gender" class="profile-form-content-select">
            <mat-option [value]="gender" class="profile-form-content-select"
              *ngFor="let gender of genderList; trackBy: trackByIndex">{{ gender }}</mat-option>
          </mat-select>
        </mat-form-field>

        <mat-form-field class="field-dob">
          <mat-label>{{ getTranslatedText('DOB') }}</mat-label>
          <input matInput formControlName="dob" [matDatepicker]="datepicker"
            [max]="today" readonly (click)="datepicker.open()" id="customer-profile-dob-input">
          <mat-datepicker-toggle matSuffix [for]="datepicker"></mat-datepicker-toggle>
          <mat-datepicker #datepicker></mat-datepicker>
        </mat-form-field>
      </div>

      <div class="administration-form-checkbox-content is-open-content"
        *ngFor="let contactType of formCustomer.contactTypes; trackBy: trackByIndex">
        <div class="administration-form-checkbox-content-multiple">
          <div class="administration-form-checkbox-content-multiple-top contacts-head">
            <span>{{ getHeadText(contactType) }}</span>
            <div class="administration-form-checkbox-content-multiple-top-buttons">
              <app-expend-button [expend]="hideSlot[contactType]" (click)="toggleHideSlot(contactType)" id="toggle-hide-slot">
              </app-expend-button>
            </div>
          </div>

          <div class="administration-form-checkbox-content-multiple-detail"
            [ngClass]="{'is-open': !hideSlot[contactType]}">
            <div class="administration-form-checkbox-content-multiple-detail-container"
              *ngFor="let contact of getCustomerContactsInfoArrayForType(contactType); index as currentIndex; trackBy: trackByControlIndex">
              <div class="profile-form-content-head" [ngClass]="{ 'primary': getIsPrimary(contactType, contact.controlIndex) }"
                (click)="toggleContactOpen(contactType, currentIndex)">
                <div class="contact-icon">
                  <mat-icon>{{ contactType }}</mat-icon>
                </div>
                <span class="profile-form-content-head-text">{{ getContactTitle(contact.controlIndex, contactType) }}</span>
                <mat-icon class="action-icon">{{ contact.isOpen ? 'check' : 'edit' }}</mat-icon>
                <mat-icon class="action-icon" (click)="deleteContact(contactType, contact.controlIndex, currentIndex)" id="toggle-contact-open">delete</mat-icon>
              </div>

              <div class="profile-form-content-edit" [hidden]="!contact.isOpen">
                <mat-form-field class="edit-container" [ngSwitch]="contactType"
                  [hidden]="!canEditContactValue(contactType, contact.isNew)">
                  <mat-label class="capitalize">{{ contactType }}</mat-label>
                  <div>
                    <input matInput [formControl]="formControls[contactType + contact.controlIndex]" (change)="onUpdateContact(contactType)" id="customer-profile-contact-input" required>
                  </div>
                </mat-form-field>
                <div *ngIf="displayMessages(contactType + contact.controlIndex)" class="text-danger">
                  <span *ngIf="formControls[contactType + contact.controlIndex] && formControls[contactType + contact.controlIndex].errors?.required">{{ getTranslatedText(contactType === 'phone' ? 'PHONE_REQUIRED' : 'EMAIL_REQUIRED') }}</span>
                  <span *ngIf="isContactPatternInvalid(contactType, contact.controlIndex)">{{ getTranslatedText(contactType === 'phone' ? 'PHONE_INVALID' : 'EMAIL_INVALID') }}</span>
                  <span *ngIf="isContactLengthInvalid(contactType, contact.controlIndex)">{{ getTranslatedText('EMAIL_INVALID_LENGTH') }}</span>
                </div>
                <div class="text-danger" *ngIf="getWarning(contactType, contact.controlIndex)">
                  {{ getTranslatedText(contactType === 'phone' ? 'DUPLICATE_PHONE' : 'DUPLICATE_EMAIL') }}</div>
                <div class="profile-form-content-edit-primary">
                  <input hidden [formControl]="formControls['isPrimary' + contactType + contact.controlIndex]" type="checkbox" (change)="onPrimaryToggle(contactType, contact.controlIndex)" #primaryContactCheckbox/> 
                  <mat-checkbox [checked]="formControls['isPrimary' + contactType + contact.controlIndex].value" id="toggle-contact-primary" (click)="primaryContactCheckbox.click(); $event.preventDefault()">
                    <div class="profile-form-content-edit-primary-text">{{ getMakePrimaryText(contactType) }}</div>
                  </mat-checkbox>
                </div>
              </div>
            </div>

            <div class="add-contact-container">
              <button class="add-contact mini-fab-button" mat-mini-fab id="add-new-contact"
                [matTooltip]="addContactTootip(contactType)" (click)="addNewContact(contactType)">
                <mat-icon>add</mat-icon>
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  </mat-dialog-content>

  <mat-dialog-actions class="bottom-buttons">
    <button class="dialog-save-button" mat-raised-button [disabled]="disableSave()" id="customer-profile-dialog-save"
      (click)="save()">{{ getTranslatedText('SAVE') }}</button>
  </mat-dialog-actions>
</div>