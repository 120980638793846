import { Component, Input, OnInit } from '@angular/core';
import { Conversation } from '../../conversations.service';

@Component({
  selector: 'app-current-conversation-panel',
  templateUrl: './current-conversation-panel.component.html',
  styleUrls: ['./current-conversation-panel.component.scss']
})
export class CurrentConversationPanelComponent implements OnInit {

  @Input() public conversation?: Conversation;

  constructor() { }

  public ngOnInit(): void {
  }

}
