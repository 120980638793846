import { Component, Input, OnInit } from '@angular/core';

interface SplittedTextElement {
  value: string;
  isLink: boolean;
}

@Component({
  selector: 'app-text-with-links',
  templateUrl: './text-with-links.component.html',
  styleUrls: ['./text-with-links.component.scss']
})
export class TextWithLinksComponent implements OnInit {

  @Input() public text = '';
  @Input() public repliedText = false;
  public urlRegEx = /(https?:\/\/[^\s]+)|(www[.][^\s]+)/g;
  public splittedText: SplittedTextElement[] = [];

  constructor() { }

  public ngOnInit(): void {
    if (!this.text) {
      this.splittedText = [];
      return;
    }
    const splittedTextValues = this.text.split(this.urlRegEx);
    this.splittedText = splittedTextValues.map((textValue: string) => ({ value: textValue, isLink: this.textMatchesRegex(textValue) }));
  }

  public goToLink(textPart: {value: string; isLink: boolean}): void {
    if (!textPart.isLink) {
      return;
    }
    let url = textPart.value;
    if (!url.match(/^https?:\/\//i)) {
      url = 'http://' + url;
    }
    window.open(url, '_blank'); // Open the link in a new tab
  }

  public trackByIndex(index: number, splittedTextElement: SplittedTextElement): number {
    return index;
  }

  private textMatchesRegex(text: string): boolean {
    return Boolean(text && text.match(this.urlRegEx));
  }

}
