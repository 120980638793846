<div class="audio-component" [ngClass]="{ 'small': small }" *ngIf="recordingFile">
    <div class="audio-component-placeholder-container">
        <div class="audio-component-audio audio-placeholder" [ngClass]="{ 'without-download': disableDownload }" *ngIf="!displayAudioElement" (click)="loadAudio()" id="audio-placeholder-component">
            <mat-icon class="play-button" (click)="play($event)" id="audio-placeholder-play">play_arrow</mat-icon>
            <span class="audio-duration">0:00 / {{ duration || '0:00' }}</span>
            <div class="audio-bar"></div>
            <mat-icon class="audio-volume" (click)="mute()" id="audio-placeholder-mute">{{ isAudioMuted() ? 'volume_off' : 'volume_up' }}</mat-icon>
        </div>
    </div>
    <div class="audio-component-audio-container">
        <audio id="{{ recordingFile }}" class="audio-component-audio" [src]="recordingFile" controls
          preload="none" controlsList="{{ disableDownload ? 'nodownload' : '' }}">{{ 'SHARED.GENERAL.AUDIO_NO_SUPPORT' | translate }}</audio>
    </div>
    <div class="audio-component-placeholder-container end-button" *ngIf="!disableDownload">
        <button class="download-button" mat-icon-button (click)="downloadRecording()" id="audio-placeholder-download"><mat-icon>get_app</mat-icon></button>
    </div>
    <div class="audio-component-placeholder-container end-button" *ngIf="small && displayAudioElement && disableDownload">
        <div class="download-button cover-options"></div>
    </div>
</div>