import { Component, Input, OnInit } from '@angular/core';
import { MatIconRegistry } from '@angular/material/icon';
import { DomSanitizer } from '@angular/platform-browser';
import _ from 'lodash';
import { MappingsByChannelInterface } from '../../channel/channel.component';

@Component({
  selector: 'app-file-icon',
  templateUrl: './file-icon.component.html',
  styleUrls: ['./file-icon.component.scss']
})
export class FileIconComponent implements OnInit {

  @Input() public iconType = 'default';
  @Input() public noColor = false;


  public fileIconByType: MappingsByChannelInterface = {
    image: { icon: 'file-image', label: 'image' },
    music: { icon: 'file-music', label: 'music' },
    video: { icon: 'file-video', label: 'video' },
    pdf: { icon: 'file-pdf', label: 'pdf' },
    word: { icon: 'file-word', label: 'word' },
    excel: { icon: 'file-excel', label: 'excel' },
    powerpoint: { icon: 'file-powerpoint', label: 'powerpoint' },
  };

  constructor(
    private matIconRegistry: MatIconRegistry,
    private domSanitizer: DomSanitizer
  ) {
    _.map(this.fileIconByType, (mapping:any) => {
      this.matIconRegistry.addSvgIcon(
        mapping.label,
        this.domSanitizer.bypassSecurityTrustResourceUrl(`/assets/images/whatsapp/${mapping.icon}.svg`)
      );
    });
  }

  public ngOnInit(): void {
  }

}
