import { Component, Input, OnInit, Output, EventEmitter } from '@angular/core';
import { ConversationsModuleService } from '../../components/conversations/conversations-module.service';

@Component({
  selector: 'app-enum-filter',
  templateUrl: './enum-filter.component.html',
  styleUrls: ['./enum-filter.component.scss']
})
export class EnumFilterComponent implements OnInit {

  @Input() public multiple = false;
  @Input() public allOption = false;
  @Input() public filterModel = '';
  @Input() public filterName = '';
  @Input() public title = '';
  @Input() public list: string[] = [];
  @Output() public handleChange = new EventEmitter<void>();
  @Output() public filterModelChange = new EventEmitter<string>();
  public selectedElements: string[] = [];

  constructor(
    private conversationsModuleService: ConversationsModuleService,
  ) { }

  public ngOnInit(): void {
    this.setInitialValues();
    this.conversationsModuleService.subscribeToResetFilters(() => this.resetFilter());
  }

  public onHandleMultipleChanges(): void {
    this.formatFilter();
    this.onHandleChanges();
  }

  public onHandleChanges(): void {
    this.filterModelChange.emit(this.filterModel);
    this.handleChange.emit();
  }

  private formatFilter(): void {
    this.filterModel = this.selectedElements.join('&');
  }

  private setInitialValues(): void {
    this.selectedElements = this.filterModel ? this.filterModel.split('&') : [];
  }

  private resetFilter(): void {
    window.setTimeout(() => this.setInitialValues(), 0);
  }
}
