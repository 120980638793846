import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'app-more-unreads-button',
  templateUrl: './more-unreads-button.component.html',
  styleUrls: ['./more-unreads-button.component.scss']
})
export class MoreUnreadsButtonComponent implements OnInit {

  @Input() public isPreviousMessages = false;
  @Input() public direction = '';

  constructor() { }

  public ngOnInit(): void {
  }

}
