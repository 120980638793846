import { Component, Input, OnInit, Output, EventEmitter } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { RouterService } from '../../../../../services/router.service';

@Component({
  selector: 'app-what-account',
  templateUrl: './what-account.component.html',
  styleUrls: ['./what-account.component.scss', '../../connexion/connexion.component.scss']
})
export class WhatAccountComponent implements OnInit {

  @Output() public whatAccountNavigation = new EventEmitter<{origin: string; email: string}>();
  public forgotForm = new FormGroup({});

  constructor(
    private router: RouterService,
  ) {}

  public ngOnInit(): void {
    this.forgotForm = this.buildForm();
  }

  public navigate(): void {
    this.router.navigate(['login/forgot-account']);
  }

  public onQuit(): void {
    this.router.navigate(['login']);
  }

  private buildForm(): FormGroup {
    return new FormGroup({});
  }

}
