import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-login-right-pane',
  templateUrl: './login-right-pane.component.html',
  styleUrls: ['./login-right-pane.component.scss']
})
export class LoginRightPaneComponent implements OnInit {

  constructor() { }

  public ngOnInit(): void {
  }

}
