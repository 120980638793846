import { Component, Input, OnInit } from '@angular/core';
import _ from 'lodash';
import { ConversationLog, ConversationType, Customer } from '../../../../conversations.service';
import { InboxService } from '../../../../inbox.service';

@Component({
  selector: 'app-current-conversation-log',
  templateUrl: './current-conversation-log.component.html',
  styleUrls: ['./current-conversation-log.component.scss']
})
export class CurrentConversationLogComponent implements OnInit {

  @Input() public log!: ConversationLog;
  @Input() public contact = {};
  @Input() public customer!: Customer;

  public isFullScreen = false;
  public text = '';
  public agent: any;
  public contactContent = [];

  constructor(private inboxService: InboxService) { }

  public ngOnInit(): void {
    this.agent = this.inboxService.getAgentsByIds()[this.log.userId];
    // @ts-ignore
    this.text = (this.log.category === ConversationType.Sms ? this.log.content : this.log.content.text) || '';
    this.contactContent = _.map(this.log.content);
  }

  public toggleFullscreen(): void {
    this.isFullScreen = !this.isFullScreen;
  }

  public trackByIndex(index: number, trackedObject: any): number {
    return index;
  }

  public repliedAgent(): any {
    if (!this.log.repliedLog) {
      return;
    }
    return this.inboxService.getAgentsByIds()[this.log.repliedLog.userId];
  }

  public scrollToReplied(): void {
    if (this.log.repliedLog && this.log.repliedLog.scrollTo) {
      this.log.repliedLog.scrollTo();
    }
  }

  public displayReplyText(): boolean {
    return Boolean(this.log.repliedLog && this.log.repliedLog.type !== 'document' && this.getRepliedText());
  }

  public getRepliedText(): string {
    if (!this.log.repliedLog) return '';
    if (['contact', 'location'].includes(this.log.repliedLog.type)) return _.upperFirst(this.log.repliedLog.type);
    return this.log.repliedLog.content.text;
  }

}
