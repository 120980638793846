import { Directive, ElementRef, Input, OnChanges } from '@angular/core';

@Directive({
  selector: '[appFocusOn]'
})
export class FocusOnDirective implements OnChanges {

  @Input('appFocusOn') public isFocused = false;

  constructor(
    private hostElement: ElementRef,
  ) { }

  public ngOnChanges(): void {
    if(this.isFocused) this.hostElement.nativeElement.focus();
    else this.hostElement.nativeElement.blur();
  }

}
