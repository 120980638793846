import { Pipe, PipeTransform } from '@angular/core';
import moment from 'moment';

@Pipe({
  name: 'secondsToTime'
})
export class SecondsToTimePipe implements PipeTransform {

  public transform(seconds?: number | string, short?: boolean): string {
    if (!seconds && seconds !== 0) {
      return '';
    }
    const duration = moment.duration(seconds, 'seconds');
    const secondsText = ':' + this.clockDigits(duration.seconds());
    if (short) {
      return Math.floor(duration.asMinutes()) + secondsText ;
    }
    return this.clockDigits(Math.floor(duration.asHours())) + ':' + this.clockDigits(duration.minutes()) + secondsText;
  }

  private clockDigits(duration: number): string {
    return duration < 10 ? '0'+duration : duration.toString();
  }

}
