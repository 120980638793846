import { Injectable } from '@angular/core';
import _ from 'lodash';
import { ApiService } from '../api/api.service';
import { InfoService } from './info.service';

export enum AgentStatus {
  Available = 'Available',
  Break = 'On-Break',
  Meeting = 'Meeting',
  Outgoing = 'Outgoing',
  LoggedOff = 'Logged-Out',
}

export enum UserType {
  Agent = 'agent',
  Admin = 'admin',
}

export enum AccountPlan {
  Desk = 'desk',
  Regular = 'regular',
}

export interface User {
  id: number;
  firstName: string;
  lastName: string;
  username: string;
  plan: AccountPlan;
  type: UserType;
  ccLogin?: string;
  status?: AgentStatus;
  outboundRoaming?: boolean;
  contactNumber?: string;
  profileType: string;
}

export interface FormUser {
  firstName: string;
  lastName: string;
  contactNumber?: string;
  currentPassword?: string;
  password?: string;
  profileType: string;
  photo?: string;
}

class MissingUserError extends Error {
  constructor(){
    super('User not yet set, function can\'t be called here');
  }
}

@Injectable({
  providedIn: 'root'
})
export class CurrentUserService {

  private user: User | null = null;

  constructor(
    private infoService: InfoService,
    private apiService: ApiService,
  ) { }

  public get(): User {
    if (!this.user) throw new MissingUserError();
    return this.user;
  }

  public set(user: User | null): void {
    this.user = user;
  }

  public isAdmin(): boolean {
    return this.get().type === UserType.Admin;
  }

  public isAgent(): boolean {
    return this.get().type === UserType.Agent;
  }

  public isDeskUser(): boolean {
    return this.get().plan === AccountPlan.Desk;
  }

  public is360User(): boolean {
    return this.get().plan === AccountPlan.Regular
      && ( // Will be unecesseray once we have proper "voice" and "360" plan separation in BE
        !this.infoService.getInterfaceInfos().oldAgentInterface
        || this.infoService.getWAInfos().enabledForMe
      );
  }

  public updateUser(user: FormUser): Promise<any> {
    return this.apiService.putRequest('/' + user.profileType, user).then((res: any) => {
      if (res.result) this.set(_.merge(this.get(), res.content));
      return res;
    });
  }
}
