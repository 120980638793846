<div class="list-row-content logs-list">
    <div class="list-row-main-content layout-fill" (click)="toggleConversation()"
        [ngClass]="{'is-selected-log': conversation === focusedConversation}">
        <span class="list-row-block conversation-channel-column flex-10">
            <app-conversation-channel [conversation]="conversation"></app-conversation-channel>
        </span>
        <div class="list-row-block date-info flex-20">{{ conversation.createdAt | tzDateTime : undefined : true }}</div>
        <span class="list-row-block status-info flex-15">
            <div class="conversation-status {{ conversation.status }}">
            <div>{{ conversation.status | translate }}</div>
        </div></span>
        <div class="list-row-block flex-15">{{ conversation | conversationContact }}</div>
        <div class="list-row-block customer-phone flex-15">{{ conversation.phone | customerNumber }}</div>
        <div class="list-row-block flex-15" *ngIf="isAdmin">{{ conversation.agentDisplayed }}</div>
        <div class="list-row-block flex-40">{{ conversation.preview }}</div>
    </div>
</div>