import { Injectable } from '@angular/core';
import { environment } from '../../environments/environment';
import _ from 'lodash';
import { NavigationEnd, Router } from '@angular/router';
import { version } from '../../../package.json';

declare global {
  interface Window { rudderanalytics: any }
}

@Injectable({
  providedIn: 'root'
})
export class AnalyticsService {
  private writeKey = environment.rudderstackWriteKey;
  private dataPlaneUrl = 'https://analytics-staging.ziwo.io';
  private rudderanalytics: any = window.rudderanalytics = [];
  private eventsMapping: {[key: string]: any} = {
    'submittedWrongAccount': {},
    'clickedShowPassword': {},
    'clickedLegalConsultFromLoginPage': {},
  };

  constructor(
    private router: Router
  ) {
    this.setCallbackStorage();
    this.rudderanalytics.load(this.writeKey, this.dataPlaneUrl);
    this.watchPageTransitions();
    this.loadRudderstack();
  }

  public page(url: string, step: number): void {
    const [domain, category, name] = url.split('?')[0].split('/').map((subUrl: string) => _.camelCase(subUrl));
    this.rudderanalytics.page(
      category,
      name || category,
      { step: step },
      this.rudderstackContext()
    );
  }

  public track(eventName: string, properties = {}): void {
    this.checkTrackEventName(eventName);
    this.rudderanalytics.track(
      eventName,
      properties,
      this.rudderstackContext()
    );
  }

  private rudderstackContext(options = {}): any {
    return _.merge({
      app: this.getApp(),
      screen: { resolution: `${screen.width}x${screen.height}` },
      version: version
    }, options);
  }

  private getApp(): string {
    return `web-${this.getSubApp()}`;
  }

  private getSubApp(): string {
    return 'login';  // TO DO change once other subapps covered
  }

  private setCallbackStorage(): void {
    ['load', 'page', 'track', 'identify', 'alias', 'group', 'ready', 'reset', 'getAnonymousId', 'setAnonymousId'].forEach(method => {
      this.rudderanalytics[method] = function (methodName) {
        return function (): void {
          window.rudderanalytics.push([methodName].concat(Array.prototype.slice.call(arguments)));
        };
      }(method);
    });
  }

  private loadRudderstack(): void {
    const rudderstackScript = document.createElement('script');
    _.merge(rudderstackScript, {
      id: 'rudder-js-sdk',
      async: true,
      src: 'https://cdn.rudderlabs.com/v1/rudder-analytics.min.js',
      onload: () => {
        this.rudderanalytics = window.rudderanalytics;
      },
    });
    document.head.appendChild(rudderstackScript);
  }

  private watchPageTransitions(): void {
    this.router.events.subscribe(event => {
      if (event instanceof NavigationEnd) {
        this.page(event.url, event.id);
      }
    });
  }

  private checkTrackEventName(eventName: string): void {
    if (!this.eventsMapping[eventName]) {
      throw Error(`"${eventName}" is not a registered analytics event`);
    }
  }
}
