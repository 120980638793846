<div id="{{ log.id }}" class="current-conversation-log" [ngSwitch]="log.direction" [ngClass]="{ 'log-to-end': log.direction === 'outbound' }">
    <div class="container" *ngIf="log.direction !== 'internal'">
        <div class="customer-image-inbound" *ngSwitchCase="'inbound'">
            <app-customer-image [customer]="customer"></app-customer-image>
        </div>

        <div class="content" [ngClass]="{ 'to-end': log.direction === 'outbound' }">
            <div class="content-extreme">
                <div *ngSwitchCase="'outbound'">{{ agent | fullname }}</div>
            </div>
            <div class="content-card {{ log.direction }}" [ngClass]="{'compressed-card': ['image', 'video'].includes(log.type)}">
                <div class="content-card-message {{ log.direction }}" [ngClass]="{ 'with-attachment': !['text', 'contact', 'location'].includes(log.type) }"
                    *ngIf="!['document', 'call'].includes(log.type)">

                    <div class="content-card-message-reply" *ngIf="log.repliedLog" (click)="scrollToReplied()" id="scroll-to-replied">
                        <div class="reply-description" [ngClass]="{ 'reply-with-attachment': ['image', 'video'].includes(log.repliedLog.type)}">
                            <span class="reply-author">{{ log.repliedLog.direction === 'inbound' ? contact : (repliedAgent() | fullname) }}</span>
                            <div class="reply-text" *ngIf="displayReplyText()"><app-text-with-links [text]="getRepliedText()" [repliedText]="true"></app-text-with-links></div>
                            <div class="reply-attachment-name" *ngIf="log.repliedLog.attachment">
                                <app-attachment [attachment]="log.repliedLog.attachment" [noColor]="true"></app-attachment>
                            </div>
                        </div>
                        <div class="reply-content-attachment" *ngIf="['image', 'video'].includes(log.repliedLog.type)">
                            <img *ngIf="log.repliedLog.type === 'image'" [src]="log.repliedLog.attachment!.url" alt="Image attachment">
                            <video *ngIf="log.repliedLog.type === 'video'" [src]="log.repliedLog.attachment!.url" alt="Video attachment"></video>
                        </div>
                    </div>

                    <div class="image-container" [ngClass]="{ 'fullscreen-container': isFullScreen }" (click)="toggleFullscreen()" id="toggle-fullscreen">
                        <img class="content-attachment" *ngIf="log.type === 'image'" [src]="log.attachment!.url" alt="Image attachment">
                    </div>
                    <video class="content-attachment" *ngIf="log.type === 'video'" [src]="log.attachment!.url" controls alt="Video attachment"></video>
                    <audio *ngIf="['audio', 'voice'].includes(log.type)" [src]="log.attachment!.url" controls alt="Audio attachment"></audio>
                    <div *ngIf="log.type === 'location'">
                        <div><span>Latitude:</span>{{ ' ' + log.content.latitude }}</div>
                        <div><span>Longitude:</span>{{ ' ' + log.content.longitude }}</div>
                    </div>
                    <div *ngIf="log.type === 'contact'">
                        <div *ngFor="let content of contactContent; trackBy: trackByIndex">
                            <app-conversation-contact [contactContent]="content"></app-conversation-contact>
                        </div>
                    </div>
                    <app-text-with-links [text]="text"></app-text-with-links>
                </div>
                <div class="content-card-attachment-file" *ngIf="log.attachment">
                    <app-attachment [attachment]="log.attachment" [withDownload]="true"></app-attachment>
                </div>
                <div class="content-card-call" *ngIf="log.type === 'call'">
                    <app-conversation-log-recording [log]="log"></app-conversation-log-recording>
                    <div class="content-card-call-details">
                        <div>
                            <span class="content-card-call-details-title">Start Time:</span>
                            <span class="content-card-call-details-value">{{ (log.content.startedAt | tzTime ) || '-' }}</span>
                        </div>
                        <div>
                            <span class="content-card-call-details-title">End Time:</span>
                            <span class="content-card-call-details-value">{{ (log.content.endedAt | tzTime ) || '-' }}</span>
                        </div>
                        <div>
                            <span class="content-card-call-details-title" translate-in-namespace>Duration:</span>
                            <span class="content-card-call-details-value">{{ (log.content.duration | secondsToTime) || '-' }}</span>
                        </div>
                    </div>
                </div>
            </div>
            <div class="content-extreme">
                <div>{{ (log[log.direction === 'outbound' ? 'sentAt' : 'receivedAt'] || '') | tzDateTime }}</div>
                <app-log-status class="log-status" *ngSwitchCase="'outbound'" [status]="log.status" [category]="log.category"></app-log-status>
            </div>
        </div>

        <div class="agent-image-outbound" *ngSwitchCase="'outbound'">
            <app-customer-image *ngIf="agent" [customer]="agent" [isAgent]="true"></app-customer-image>
        </div>
    </div>
    <app-log-timestamp *ngSwitchCase="'internal'" [log]="log"></app-log-timestamp>
</div>