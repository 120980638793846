/**
 * Map where all keys are mandatory, used for configurations
*/
export class ConfigMap<K, V> extends Map<K, V> {
  public get(key: K): V {
    const value = super.get(key);
    if (!value) throw (new ConfigMapError(key));
    return value;
  }
}

export class ConfigMapError extends Error {
  constructor(key: any) {
    super(`Missing config for key "${key}"`);
  }
}
