import { Component, Input, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { first } from 'rxjs/operators';
import { RecaptchaService } from 'src/app/services/recaptcha.service';

@Component({
  selector: 'app-forgot-account-password',
  templateUrl: './forgot-account-password.component.html',
  styleUrls: ['./forgot-account-password.component.scss']
})
export class ForgotAccountPasswordComponent implements OnInit {
  @Input() public mode = 'what-account';
  public currentUsername = '';

  constructor(
    private route: ActivatedRoute,
    private recaptcha : RecaptchaService,
  ) {}

  public ngOnInit(): void {
    this.route.data.pipe(first()).subscribe(data => this.mode = data.mode || this.mode);
    if (this.mode === 'forgot-account') {
      this.recaptcha.load();
    }
  }

  public navigate(fromScreen: {origin: string; email: string}): void {
    if (fromScreen.origin === 'forgot-account') {
      this.mode = 'check-inbox-account';
    } else if (fromScreen.origin === 'forgot-password') {
      this.mode = 'check-inbox-password';
    }
    this.currentUsername = fromScreen.email;
  }
}
