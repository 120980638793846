/*
    Useful for methods that can be called many times in short succession
    With this decorator the method will be resolved only once, after it has not been called again for waitingTime ms
*/
export const patient = (waitingTime = 500): MethodDecorator =>
  ((target: Object, propertyKey: string, descriptor: PropertyDescriptor): PropertyDescriptor => {
    let scheduledExecution: number | undefined;
    const originalMethod = descriptor.value;
    descriptor.value = function (...args: any): any {
      window.clearTimeout(scheduledExecution);
      scheduledExecution = window.setTimeout((): void => originalMethod.apply(this, args), waitingTime);
    };
    return descriptor;
  }) as MethodDecorator
;
