import { Component, Input, OnInit } from '@angular/core';
import { Conversation, ConversationLog } from '../../../../../agent/inbox/conversations.service';
import { ConversationsModuleService } from '../../../conversations-module.service';

@Component({
  selector: 'app-conversation-information',
  templateUrl: './conversation-information.component.html',
  styleUrls: ['./conversation-information.component.scss']
})
export class ConversationInformationComponent implements OnInit {

  @Input() public focusedConversation!: Conversation;
  public agentsById: { [key: string]: any } = {};
  public adminsById: { [key: string]: any } = {};

  constructor(
    private conversationsModuleService: ConversationsModuleService,
  ) { }

  public ngOnInit(): void {
    this.initUsersLists();
  }

  public getLogAgent(log: ConversationLog): any {
    return this.focusedConversation.type === 'sms' ? this.adminsById[log.userId] : this.agentsById[log.userId];
  }

  public trackById(index: number, log: ConversationLog): string {
    return log.id;
  }

  private initUsersLists(): void {
    this.agentsById = this.conversationsModuleService.getAgentsByIds();
    this.adminsById = this.conversationsModuleService.getAdminsByIds();
  }

}
