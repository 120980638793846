import { Injectable } from '@angular/core';
import { CanActivate, Router, UrlTree } from '@angular/router';
import { LoginService } from '../public/login/login.service';
import { CurrentUserService } from '../services/current-user.service';
import { InfoService } from '../services/info.service';
import { RouterService } from '../services/router.service';

@Injectable({
  providedIn: 'root'
})
export class AgentGuardService implements CanActivate {

  constructor(
    private loginService: LoginService,
    private currentUserService: CurrentUserService,
    private router: Router,
    private routerService: RouterService,
    private infoService: InfoService,
  ) { }

  public async canActivate(): Promise<boolean | UrlTree> {
    if (!(await this.loginService.isLoggedIn()) || !this.currentUserService.isAgent()) return this.router.parseUrl('login');

    await this.infoService.resolve();
    if (this.currentUserService.is360User()) return true;

    if (this.currentUserService.isDeskUser()) this.routerService.goToDesk();
    else this.routerService.goToSuperman();
    return false;
  }
}
