import { Component, Input, OnInit } from '@angular/core';
import { MatIconRegistry } from '@angular/material/icon';
import { DomSanitizer } from '@angular/platform-browser';
import _ from 'lodash';
import { ConversationType } from '../../agent/inbox/conversations.service';

export interface MappingsByChannelInterface {
  [key: string]: { icon: string; label: string };
}

@Component({
  selector: 'app-channel',
  templateUrl: './channel.component.html',
  styleUrls: ['./channel.component.scss']
})
export class ChannelComponent implements OnInit {

  @Input() public channel = '';
  @Input() public size ='medium';
  @Input() public withName = false;
  @Input() public direction = '';
  @Input() public hasSms = false;

  public mappingsByVirtualChannels: MappingsByChannelInterface = {
    // eslint-disable-next-line @typescript-eslint/naming-convention
    WhatsApp: { icon: '/assets/images/whatsapp/WhatsApp.svg', label: 'WhatsApp' },
    sms: { icon: '/assets/images/sms/sms.svg', label: 'SMS' },
    inbound: { icon: '/assets/images/CDR/incoming.svg', label: 'Inbound Call' },
    outbound: { icon: '/assets/images/CDR/outgoing.svg', label: 'Outbound Call' },
  };

  constructor(
    private matIconRegistry: MatIconRegistry,
    private domSanitizer: DomSanitizer
  ) {
    _.map(this.mappingsByVirtualChannels, (mapping:any) => {
      this.matIconRegistry.addSvgIcon(
        mapping.label,
        this.domSanitizer.bypassSecurityTrustResourceUrl(mapping.icon)
      );
    });
  }

  public ngOnInit(): void {
  }

  public getVirtualChannel(): string {
    return this.channel === ConversationType.Call ? this.direction : this.channel;
  }

  public shouldDisplaySmsBadge(): boolean {
    return !this.withName && this.hasSms && this.channel !== ConversationType.Sms;
  }

}
