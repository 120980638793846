import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'app-notification',
  templateUrl: './notification.component.html',
  styleUrls: ['./notification.component.scss']
})
export class NotificationComponent implements OnInit {

  @Input() public notificationValue: string|number = '';
  @Input() public notificationDot?: boolean;

  constructor() { }

  public ngOnInit(): void {
  }

}
