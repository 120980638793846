import { Component, OnInit, OnDestroy } from '@angular/core';
import { InfoboxType, InfoboxService } from './infobox.service';
import { Subscription } from 'rxjs';
import _ from 'lodash';

interface InfoboxData {
  type: InfoboxType;
  content: string;
  delay: number;
  className?: string;
}

interface UniqueInfoboxData extends InfoboxData {
  id: number;
}

@Component({
  selector: 'app-infobox',
  templateUrl: './infobox.component.html',
  styleUrls: ['./infobox.component.scss']
})
export class InfoboxComponent implements OnInit, OnDestroy {

  public currentInfoxboxes: UniqueInfoboxData[] = [];
  public maxId = 0;
  public infoboxRemovedById: boolean[] = [];
  public infoboxesMaxLength = 3;
  public className?: string;

  private pushSub = Subscription.EMPTY;

  constructor(
    private infoboxService: InfoboxService,
  ) { }

  public ngOnInit(): void {
    this.pushSub = this.infoboxService.pushEvent.subscribe((s: InfoboxData) => {
      if(s) {
        this.open(s);
      } else {
        this.closeAll();
      }
    });
  }

  public ngOnDestroy(): void {
    this.pushSub.unsubscribe();
  }

  public open(data: InfoboxData): void {
    this.className = data.className;
    this.currentInfoxboxes.push(_.extend(data, {id: this.maxId}));
    const openedId = this.maxId;
    this.maxId++;
    if (this.currentInfoxboxes.length > this.infoboxesMaxLength) {
      this.close(this.currentInfoxboxes[0].id);
    }
    if (data.delay) {
      window.setTimeout(() => {
        this.close(openedId);
      }, data.delay);
    }
  }

  public close(id: number): void {
    if (!this.currentInfoxboxes.length) {
      return;
    }
    this.infoboxRemovedById[id] = true;
    // delay required for closing animation.
    window.setTimeout(() => {
      this.currentInfoxboxes = this.currentInfoxboxes.filter(infobox => infobox.id !== id);
    }, 500);
  }

  public trackById(index: number, infobox: UniqueInfoboxData): number | undefined {
    return infobox ? infobox.id : undefined;
  }

  public closeAll(): void {
    this.currentInfoxboxes.map(box => this.close(box.id));
  }

}
