import { Component, Input, OnInit } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'app-no-conversation-placeholder',
  templateUrl: './no-conversation-placeholder.component.html',
  styleUrls: ['./no-conversation-placeholder.component.scss']
})
export class NoConversationPlaceholderComponent implements OnInit {

  @Input() public isSearchMode = false;
  @Input() public noConversation = true;
  @Input() public mode = '';

  constructor(
    private translateService: TranslateService,
  ) { }

  public ngOnInit(): void {
  }

  public getImageSource(): string {
    return `/assets/images/whatsapp/no-conv-${this.mode}.png`;
  }

  public getTitle(): string {
    return this.translateService.instant(`INBOX.CONVERSATIONS_LIST.PLACEHOLDER.TITLE_${this.mode.toUpperCase()}`);
  }

  public getDescription(): string {
    return this.translateService.instant(`INBOX.CONVERSATIONS_LIST.PLACEHOLDER.SUBTITLE_${this.mode.toUpperCase()}`);
  }

}
