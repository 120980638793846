import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { LoginComponent } from './public/login/login.component';
import { ConnexionComponent } from './public/login/login-right-pane/connexion/connexion.component';
import { SetPasswordComponent } from './public/login/login-right-pane/set-password/set-password.component';
import { ForgotAccountPasswordComponent } from './public/login/login-right-pane/forgot-account-password/forgot-account-password.component';
import { AgentComponent } from './agent/agent.component';
import { InboxComponent } from './agent/inbox/inbox.component';
import { AgentGuardService } from './agent/agent-guard.service';
import { ConversationsListComponent } from './agent/inbox/conversations-list/conversations-list.component';
import { LoginGuardService } from './public/login/login-guard.service';
import { ProfileComponent } from './components/profile/profile.component';
import { ConversationsComponent } from './components/conversations/conversations.component';

const routes: Routes = [
  { path: 'login', component: LoginComponent, canActivate: [LoginGuardService], children: [
    { path: 'what-account', component: ForgotAccountPasswordComponent, data: { mode: 'what-account' } },
    { path: 'forgot-account', component: ForgotAccountPasswordComponent, data: { mode: 'forgot-account' } },
    { path: 'forgot-password', component: ForgotAccountPasswordComponent, data: { mode: 'forgot-password' } },
    { path: 'password-setup/:token', component: SetPasswordComponent, data: { mode: 'creation' } },
    { path: 'password-reset/:token', component: SetPasswordComponent, data: { mode: 'edition' } },
    { path: '', component: ConnexionComponent },
  ]},
  { path: 'agent', component: AgentComponent, canActivate: [AgentGuardService], children: [
    { path: 'inbox', redirectTo: `inbox/${ConversationsListComponent.defaultTab}` },
    { path: 'inbox/:tab', component: InboxComponent },
    { path: 'conversations', component: ConversationsComponent },
    { path: 'profile', component: ProfileComponent },
    { path: '**', redirectTo: `inbox/${ConversationsListComponent.defaultTab}` },
  ]},
  { path: '', redirectTo: '/login', pathMatch: 'full' },
  { path: '**', redirectTo: '/login' },
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule {}
