<div class="content-datas list">
    <div class="content-datas-logs" [ngClass]="{ 'logs-agent' : !isAdmin }">        
      <div class="editor-content">
          <div class="list-loading-bar logs-list">
              <mat-progress-bar [hidden]="!isLoading" mode="indeterminate"></mat-progress-bar>
          </div>
          <div class="administration-content logs-content log-is-shown">
              <div class="first-column" [ngClass]="{ 'flex-50': focusedConversation, 'flex-100': !focusedConversation }">
                    <app-table-module-table-title [columns]="columns" (getNextConversations)="getNextConversations.emit()"></app-table-module-table-title>

                    <div class="log-scrolling" (appOnEndScroll)="scrollMore()" [disableScroll]="isLoading">
                        <div class="list calls-list logs-list">
                            <div class="list-row" *ngFor="let conversation of conversations; trackBy: trackById">
                                <app-table-module-table-row class="layout-fill" [conversation]="conversation" ></app-table-module-table-row>
                            </div>
                        </div>
                    </div>
              </div>
              
              <app-conversation-information class="flex-50" *ngIf="focusedConversation" [focusedConversation]="focusedConversation"></app-conversation-information>
          </div>
      </div>
    </div>
</div>