import { Injectable } from '@angular/core';
import { Observable, Subject } from 'rxjs';
import { Call } from 'src/app/services/dialpad-core.service';
import { ApiService } from '../../api/api.service';
import { ZiwoApiResult } from '../../api/core';
import { AgentStatus } from '../../services/current-user.service';

interface OnCallInfo {
  phoneNumber:string;
  direction:string;
  time:string;
}

export enum Route {
  LoginDomain = 'LoginDomain',
  LoginUser = 'LoginUser',
  Dialpad = 'Dialpad',
  Ringing = 'Ringing',
  Early = 'Early',
  ActiveCall = 'ActiveCall',
  CallList = 'CallList',
  Selfcare = 'Selfcare',
  TransferCall = 'TransferCall',
  Contact = 'Contact',
  Roaming = 'Roaming',
  Domain = 'Domain',
  Login = 'Login',
  NoDevice = 'NoDevice',
  NoInternet = 'NoInternet',
  OnBoarding = 'OnBoarding',
}

@Injectable({
  providedIn: 'root'
})
export class StatusBarService {


  public showDialpad = false;
  public callInfo:OnCallInfo|undefined;
  public timer:any;
  public navigationEventsSubject: Subject<Route> = new Subject<Route>();
  private timeStart?:Date;

  constructor(
    private ziwoApiService:ApiService,
  ) { }

  public onCall(call:Call|undefined): void {
    if (!call) {
      return this.clearCall();
    }
    this.showDialpad = true;
    this.callInfo = {
      phoneNumber: call.customerNumber,
      direction: call.direction,
      time: '00:00:00',
    };
    this.startTimer(call);
  }

  public clearCall(): void {
    this.callInfo = undefined;
    if (this.timer) {
      window.clearInterval(this.timer);
      this.timer = undefined;
    }
  }

  public updateStatus(status:AgentStatus): Promise<any> {
    return this.ziwoApiService.putRequest(this.ziwoApiService.endpoints.updateStatus, {
      number: this.mapAgentStatus(status)
    });
  }

  public emitNavigationEventToDialpad(route: Route): void {
    this.navigationEventsSubject.next(route);
  }

  private startTimer(call:any): void {
    if (this.timer) {
      window.clearInterval(this.timer);
    }
    if (!this.callInfo) {
      return;
    }
    const activeState = call.stateFlow.find((x:any) => x.state === 'active');
    this.timeStart = activeState ? activeState.date : new Date();
    this.timer = window.setInterval(() => {
      const now = new Date();
      const seconds:number = Math.floor(((now as any) - (this.timeStart as any)) / 1000);
      (this.callInfo as any).time = new Date(seconds * 1000).toISOString().substr(11, 8);
    }, 1000);
  }

  private mapAgentStatus(status:AgentStatus):number {
    switch (status) {
      case AgentStatus.Available:
        return 1;
      case AgentStatus.Break:
        return 2;
      case AgentStatus.Meeting:
        return 3;
      case AgentStatus.Outgoing:
        return 4;
    }
    throw new Error(`invalid agent status ${status}`);
  }
}
