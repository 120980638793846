import { Component, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges, ViewChild } from '@angular/core';
import { MatDatepicker } from '@angular/material/datepicker';
import moment from 'moment';

@Component({
  selector: 'app-date-time-picker-input',
  templateUrl: './date-time-picker-input.component.html',
  styleUrls: ['./date-time-picker-input.component.scss']
})
export class DateTimePickerInputComponent implements OnInit, OnChanges {
  @Input() public inputId = '';
  @Input() public inputName = '';
  @Input() public inputRequired = false;
  @Input() public focusOn = false;
  @Input() public date = false;
  @Input() public inputPlaceholder = '';
  @Input() public inputMaxDate?: Date | string;
  @Input() public inputMinDate?: Date | string;
  @Input() public filterModel?: Date | string;
  @Output() public handleChange = new EventEmitter<void>();
  @Output() public filterModelChange = new EventEmitter<Date | string>();
  @ViewChild('datepicker') public datePicker?: MatDatepicker<Date>;

  constructor() { }

  public ngOnInit(): void {
    if (!this.inputMinDate) this.inputMinDate = moment().subtract(100, 'year').toDate();
  }

  public ngOnChanges(changes: SimpleChanges): void {
    if (changes.focusOn && changes.focusOn.currentValue && this.date && this.datePicker) this.datePicker.open();
  }

  public onHandleChanges(): void {
    this.filterModelChange.emit(this.filterModel);
    this.handleChange.emit();
  }

}
