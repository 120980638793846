import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { Conversation } from '../../../../agent/inbox/conversations.service';
import { CurrentUserService } from '../../../../services/current-user.service';
import { ConversationsModuleService } from '../../conversations-module.service';

@Component({
  selector: 'app-table-module-table',
  templateUrl: './conversations-table-module-table.component.html',
  styleUrls: ['./conversations-table-module-table.component.scss']
})
export class ConversationsTableModuleTableComponent implements OnInit {

  @Input() public conversations: Conversation[] = [];
  @Input() public columns: {[key: string]: string} = {};
  @Input() public isLoading = false;
  @Input() public stopScrolling = false;
  @Output() public getNextConversations = new EventEmitter<boolean>();

  public isAdmin = this.currentUserService.isAdmin();

  constructor(
    private currentUserService: CurrentUserService,
    private conversationsModuleService: ConversationsModuleService,
  ) { }

  public ngOnInit(): void {
  }

  public get focusedConversation(): Conversation | undefined {
    return this.conversationsModuleService.focusedConversation;
  }

  public trackById(index: number, conversation: Conversation): string {
    return conversation.id;
  }

  public scrollMore(): void {
    if (!this.isLoading && !this.stopScrolling) this.getNextConversations.emit(true);
  }

}
