<mat-form-field appearance="fill">
    <mat-label>{{ title }}</mat-label>
    
    <mat-select *ngIf="!multiple" class="{{ filterModel === filterName + '=' + 'sms' ? 'uppercase' : 'capitalize' }}" [(ngModel)]="filterModel" (selectionChange)="onHandleChanges()">
        <mat-option *ngIf="allOption" [value]="''" class="empty"><span>All</span></mat-option>
        <mat-option *ngFor="let element of list" class="{{ element === 'sms' ? 'uppercase' : 'capitalize' }}" [value]="filterName + '=' + element"><span>{{ element }}</span></mat-option>
    </mat-select>
    
    <mat-select *ngIf="multiple" multiple class="capitalize" [(ngModel)]="selectedElements" (selectionChange)="onHandleMultipleChanges()">
        <mat-option *ngFor="let element of list" [ngClass]="{ 'capitalize': element !== 'sms', 'uppercase': element === 'sms' }" [value]="filterName + '=' + element"><span>{{ element }}</span></mat-option>
    </mat-select>
</mat-form-field>