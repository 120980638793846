import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { ApiService } from 'src/app/api/api.service';
import { InfoboxService } from 'src/app/components/infobox/infobox.service';
import { AnalyticsService } from 'src/app/services/analytics.service';
import { CurrentUserService, User } from 'src/app/services/current-user.service';
import { DialpadCoreService } from 'src/app/services/dialpad-core.service';
import { RecaptchaService } from 'src/app/services/recaptcha.service';
import { RouterService } from 'src/app/services/router.service';
import { ZiwoStorageService } from 'src/app/services/ziwo-storage.service';
import { StorageService } from 'src/app/storage.service';
import { AccountService } from '../../services/account.service';

@Injectable({
  providedIn: 'root'
})
export class LoginService {
  constructor(
    public currentUserService: CurrentUserService,
    private apiService:ApiService,
    private storage: StorageService,
    private account: AccountService,
    private router: RouterService,
    private http: HttpClient,
    private alert: InfoboxService,
    private recaptcha : RecaptchaService,
    private analytics: AnalyticsService,
    private dialpadCoreService: DialpadCoreService,
    private ziwoStorage: ZiwoStorageService
  ) {}

  public get accessToken():string | null {
    return this.storage.local.getAccessToken();
  }

  public set accessToken(token: string | null) {
    if (token) {
      this.storage.local.setAccessToken(token);
    } else {
      this.storage.local.removeAccessToken();
    }
  }

  public async isLoggedIn(): Promise<boolean> {
    if (!this.account.accountName || !this.accessToken) {
      return false;
    }
    return this.http.get(
      this.apiUrl(this.account.accountName, '/profile'),
      // eslint-disable-next-line @typescript-eslint/naming-convention
      { headers: { access_token: this.accessToken } }
    )
      .toPromise().then((res: any) => {
        this.currentUserService.set(res.content);
        return true;
      })
      .catch(() => {
        this.removeAccessToken();
        this.currentUserService.set(null);
        return false;
      });
  }

  public login(accountName: string, username: string, password: string): Promise<void> {
    this.apiService.setContactCenter(accountName);
    return this.http.post(
      this.apiUrl(accountName, '/auth/login'),
      { username: username, password: password, }
    ).toPromise()
      .then((res: any) => this.logIntoTheApp(accountName, res.content.access_token, res.content))
      .catch((res: any) => this.onAccountSensitiveRequestError(res, accountName, 'login'));
  }

  public updatePassword(accountName: string, username: string, token: string, password: string): Promise<void> {
    return this.http.put(
      this.apiUrl(accountName, '/auth/password/update'),
      { token: token, password: password, }
    )
      .toPromise().then((res: any) => this.login(accountName, username, password))
      .catch((res: any) => this.onPasswordUpdateError(res));
  }

  public resetPassword(accountName: string, username: string): Promise<void> {
    return this.http.post(
      this.apiUrl(accountName, '/users/password/reset'),
      { username: username }
    )
      .toPromise().then((res: any) => {
        this.account.accountName = accountName;
      })
      .catch((res: any) => {
        this.onAccountSensitiveRequestError(res, accountName, 'forgot-password');
        throw res;
      });
  }

  public async forgotAccount(username: string): Promise<void> {
    try {
      return this.http.post(
        `https://recovery-api.ziwo.io/accounts/recover/${username}`,
        {},
        { headers: { 'g-recaptcha-token': await this.recaptcha.getToken() } }
      ).toPromise()
        .catch(res => {
          this.onRecaptchaError(res); return res;
        });
    } catch (error) {
      this.onRecaptchaError(error);
    }
  }

  public goToLoggedApp(): void {
    if (this.currentUserService.isAgent()) return this.goToAgentApp();
    return this.goToAdminApp();
  }

  public async logout(): Promise<void> {
    this.dialpadCoreService.logout();
    if (await this.isLoggedIn()) await this.apiService.postRequest('/auth/logout');
    this.removeAccessToken();
    this.currentUserService.set(null);
    this.router.navigate(['login']);
  }

  private removeAccessToken(): void {
    this.accessToken = null;
  }

  private onRecaptchaError(res: any): void {
    this.alert.error('Blocked by Recaptcha');
    throw res;
  }

  private apiUrl(accountName: string, path:string): string {
    return `https://${accountName}-api.aswat.co${path}`;
  }

  private logIntoTheApp(accountName: string, token: string, userProfile: User): void {
    this.ziwoStorage.saveSessionDetails({ accountName, token });
    this.storeAuthentifications(accountName, token);
    this.currentUserService.set(userProfile);
    this.goToLoggedApp();
  }

  private storeAuthentifications(accountName: string, token: string): void {
    this.account.accountName = accountName;
    this.accessToken = token;
  }

  private goToAgentApp(): void {
    this.router.navigate(['agent/inbox']);
  }

  private goToAdminApp(): void {
    this.router.goToSuperman();
  }

  private onAccountSensitiveRequestError(res: any, accountName: string, requestName: string): void {
    if (res.status === 0) {
      this.onWrongAccountRequest(accountName, requestName);
    } else {
      this.alert.error(res.error.error.message);
    }
  }

  private onWrongAccountRequest(accountName: string, requestName: string): void {
    this.alert.error(`Account name "${accountName}" doesn't exist !`);
    this.analytics.track('submittedWrongAccount', { account: accountName, request: requestName });
  }

  private onPasswordUpdateError(res: any): void {
    this.alert.error(res.error.error && res.error.error.message);
    if (res.status === 401) {
      this.router.navigate(['login/forgot-password']);
    }
  }
}
