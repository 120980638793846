import { Component, OnInit } from '@angular/core';
import { MatDialog, MatDialogConfig } from '@angular/material/dialog';
import { ActivatedRoute } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import _ from 'lodash';
import moment, { Moment } from 'moment';
import { Subscription } from 'rxjs';
import { Conversation, ConversationType } from '../../agent/inbox/conversations.service';
import { InboxService } from '../../agent/inbox/inbox.service';
import { InboxSocketService } from '../../agent/inbox/inbox.socket.service';
import { ApiService } from '../../api/api.service';
import { AlertComponent } from '../../components/alert/alert.component';
import { InfoboxService } from '../../components/infobox/infobox.service';
import { ConversationPreviewPipe } from '../../pipes/conversation-preview.pipe';
import { CustomerNumberPipe } from '../../pipes/customer-number.pipe';
import { FullnamePipe } from '../../pipes/fullname.pipe';
import { RouterService } from '../../services/router.service';
import { TimezoneService } from '../../services/timezone.service';
import { ConfigFilters, ConversationsModuleService, RequestConfig } from './conversations-module.service';

@Component({
  selector: 'app-conversations',
  templateUrl: './conversations.component.html',
  styleUrls: ['./conversations.component.scss']
})
export class ConversationsComponent implements OnInit {

  public conversations: Conversation[] = [];
  /* eslint-disable @typescript-eslint/naming-convention */
  public columns = {
    Channel: 'type',
    Date: 'createdAt',
    Status: 'status',
    Customer: 'customer',
    'Customer Phone': 'phone',
    Agent: 'agent',
    'Last Message': 'preview'
  };
  /* eslint-enable @typescript-eslint/naming-convention */
  public channels = [ConversationType.Call, ConversationType.WhatsApp, ConversationType.Sms];
  public conversationsCount: string | number = '';
  public isLoading = false;
  public selectedFilters: ConfigFilters = {};
  public stopScrolling = false;
  private timeOrigin!: Moment;
  private agentsById: {[key: string]: any} = {};
  private adminsById: {[key: string]: any} = {};
  private readonly numberOfConversationsLoadedAtOnce = 30;
  private routeSegmentSub = Subscription.EMPTY;
  private idFromParams: string | null = null;

  constructor(
    protected translateService: TranslateService,
    private infoboxService: InfoboxService,
    private conversationsModuleService: ConversationsModuleService,
    private conversationPreviewPipe: ConversationPreviewPipe,
    private fullnamePipe: FullnamePipe,
    private timezoneService: TimezoneService,
    private activatedRoute: ActivatedRoute,
    private routerService: RouterService,
    private dialog: MatDialog,
    private apiService: ApiService,
    private inboxService: InboxService,
    private inboxSocketService: InboxSocketService,
    private customerNumberPipe: CustomerNumberPipe,
  ) { }

  public ngOnInit(): void {
    this.subscribeToRouteParams();
    this.initialiseConversations();
    this.initUsersLists();
  }

  public ngOnDestroy(): void {
    this.routeSegmentSub.unsubscribe();
  }

  public getConversationsCount(): Promise<void> {
    this.conversationsCount = this.translateService.instant('SHARED.GENERAL.CALCULATING');
    return this.conversationsModuleService.getConversationsCount(this.getFetchFilters())
      .then(res => {
        this.conversationsCount = res.result ? res.content : 0;
      });
  }

  public getNextConversations(shouldConcat?: boolean): void {
    this.isLoading = true;
    if (!shouldConcat) this.stopScrolling = false;
    this.fetchConversations(shouldConcat).then(res => {
      if (!res.result) this.infoboxService.error(res.errorMessage);
      else {
        res.content = this.getFormattedConversations(res.content);
        this.conversations = shouldConcat ? this.conversations.concat(res.content) : res.content;
        if (res.content.length < this.numberOfConversationsLoadedAtOnce) this.stopScrolling = true;
      }
      this.isLoading = false;
    });
  }

  public onFilterChange(updateUrl?: boolean): void {
    this.reloadInitialConversations();
    this.resetConversationsCount();
    if (updateUrl) this.updateUrl();
  }

  public resetFilters(): void {
    this.openDialog();
  }

  private resetConversationsView(): void {
    this.selectedFilters = {};
    this.resetTimeOrigin();
    this.conversationsModuleService.broadcastResetFilters();
    this.updateUrl();
    this.reloadInitialConversations();
    this.resetConversationsCount();
  }

  private initialiseConversations(): void {
    this.selectedFilters = {};
    this.conversationsModuleService.initSortingColumn();
    this.loadConversations();
    this.resetConversationsCount();
    this.setFirstTimeOrigin();
  }

  private reloadInitialConversations(): void {
    this.conversations = [];
    this.conversationsModuleService.closeConversation();
    this.getNextConversations();
  }

  private updateUrl(): void {
    this.routerService.updateUrlIDParameter(this.selectedFilters.conversationId);
  }

  private fetchConversations(shouldConcat?: boolean): Promise<any> {
    return this.conversationsModuleService.getConversations(this.getConfig(shouldConcat));
  }

  private getConfig(shouldConcat?: boolean): RequestConfig {
    return {
      skip: shouldConcat ? this.conversations.length : 0,
      limit: this.numberOfConversationsLoadedAtOnce,
      filters: this.getFetchFilters(),
      sort: this.conversationsModuleService.sortingColumn
    };
  }

  private getFetchFilters(): ConfigFilters {
    const fetchFilters = _.cloneDeep(this.selectedFilters);
    const zeroFromFilter = `fromDate=${moment(0).utc().format('YYYY-MM-DD')}&fromTime=${moment(0).utc().format('HH:mm:ss')}`;
    const maxToFilter = `toDate=${this.timeOrigin.format('YYYY-MM-DD')}&toTime=${this.timeOrigin.format('HH:mm:ss')}`;
    const selectedFromFilter = fetchFilters.dateRange && fetchFilters.dateRange.slice(0, 37);
    const selectedToFilter = fetchFilters.dateRange && fetchFilters.dateRange.slice(38);

    const fromFilter = selectedFromFilter || zeroFromFilter;
    const toFilter = !selectedToFilter || maxToFilter < selectedToFilter ? maxToFilter : selectedToFilter;
    fetchFilters.dateRange = fromFilter + '&' + toFilter;

    return fetchFilters;
  }

  private setFirstTimeOrigin(): void {
    if (this.conversations[0]) {
      this.timeOrigin = this.timezoneService.getTzMoment(this.conversations[0].createdAt).add(1, 'seconds');
    } else this.resetTimeOrigin();
  }

  private resetTimeOrigin(): void {
    this.timeOrigin = this.timezoneService.getTzMoment();
  }

  private getFormattedConversations(conversations: Conversation[]): Conversation[] {
    return conversations.map(conversation => {
      conversation.preview = this.conversationPreviewPipe.transform(conversation);
      conversation = new Conversation(
        conversation,
        {},
        this.apiService,
        this.inboxService,
        this.inboxSocketService,
        this.infoboxService,
        this.fullnamePipe,
        this.customerNumberPipe,
        this.translateService,
      );
      return _.extend(conversation, this.getAgent(conversation));
    });
  }

  private getAgent(conversation: Conversation): { agent: string; agentDisplayed: string } {
    const _agent = conversation.type === ConversationType.Sms ? this.adminsById[conversation.userId] : this.agentsById[conversation.userId];
    const _agentDisplayed = this.fullnamePipe.transform(_agent) || this.getTranslation(
      conversation.userId
        ? (conversation.type === ConversationType.Sms
          ? 'AGENT_DISPLAYED.SYSTEM'
          : 'AGENT_DISPLAYED.DELETED_AGENT')
        : 'AGENT_DISPLAYED.UNASSIGNED'
    );
    return { agent: _agent, agentDisplayed: _agentDisplayed };
  }

  private getTranslation(textToTranslate: string): string {
    return this.translateService.instant(`CONVERSATIONS.${textToTranslate}`);
  }

  private initUsersLists(): void {
    this.agentsById = this.conversationsModuleService.getAgentsByIds();
    this.adminsById = this.conversationsModuleService.getAdminsByIds();
  }

  private resetConversationsCount(): void {
    this.conversationsCount = '';
  }

  private async loadConversations(): Promise<void> {
    if (this.idFromParams) {
      this.conversations = this.responseHandler(
        await this.conversationsModuleService.getConversations({skip: 0, limit: 30, filters: { id: 'id=' + this.idFromParams }, sort: {}})
      );
    } else {
      this.conversations = this.responseHandler(
        await this.conversationsModuleService.getConversations({skip: 0, limit: 30, filters: {}, sort: {}})
      );
    }

    this.conversations = this.getFormattedConversations(this.conversations);
    if (!this.idFromParams) return;
    const conversationToSelect = this.conversations.find(conversation => conversation.id === this.idFromParams);
    if (conversationToSelect) this.conversationsModuleService.toggleConversation(conversationToSelect);
    this.selectedFilters.conversationId = 'id=' + this.idFromParams;
  }

  private subscribeToRouteParams(): void {
    this.activatedRoute.queryParams.subscribe(params => {
      this.idFromParams = params.id;
    });
  }

  private responseHandler(res: any): any[] {
    return res.result ? res.content : [];
  }

  private openDialog(): void {
    const dialogConfig = new MatDialogConfig();

    dialogConfig.disableClose = true;
    dialogConfig.autoFocus = true;
    dialogConfig.data = { content: this.getTranslation('ALERT_FILTERS') };

    const dialogRef = this.dialog.open(AlertComponent, dialogConfig);

    dialogRef.afterClosed().subscribe(
      (confirmed: Boolean): void => {
        if (confirmed) this.resetConversationsView();
      }
    );
  }
}
