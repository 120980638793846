import { Component, ElementRef, Input, OnInit, ViewChild } from '@angular/core';
import { Conversation, ConversationsService } from '../../conversations.service';

@Component({
  selector: 'app-list-of-conversations',
  templateUrl: './list-of-conversations.component.html',
  styleUrls: ['./list-of-conversations.component.scss']
})
export class ListOfConversationsComponent implements OnInit {

  // @ts-ignore
  @ViewChild('unreadListContent') public unreadListContent: ElementRef;
  @Input() public conversations: Conversation[] = [];
  @Input() public withStatus = false;

  constructor(
    private conversationsService : ConversationsService,
  ) { }

  public ngOnInit(): void {
  }

  public trackById(index: number, conversation: Conversation): string {
    return conversation.id;
  }

  public changeConversation(conversation: Conversation): void {
    if (!this.conversationsService.isSelectedConversation(conversation)) this.conversationsService.selectConversation(conversation);
  }

}
