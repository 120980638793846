import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class ValidatorService {

  /**
   * Centralize common validator patterns
   */
  constructor() { }

  public isStringInEnum(enumObj:any, value:string):boolean {
    return Object.values(enumObj).includes(value);
  }
}
