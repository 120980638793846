import { Component, Input, OnInit } from '@angular/core';
import { FormGroup, FormControl, AbstractControl } from '@angular/forms';
import { AnalyticsService } from 'src/app/services/analytics.service';

@Component({
  selector: 'app-password-input',
  templateUrl: './password-input.component.html',
  styleUrls: ['./password-input.component.scss']
})
export class PasswordInputComponent implements OnInit {

  @Input() public passwordWidth = 'medium';
  @Input() public form: FormGroup = new FormGroup({password: new FormControl('')});
  @Input() public displayPasswordCheck = false;
  @Input() public modelName = 'password';
  @Input() public hideError = false;
  @Input() public passwordRequired = true;
  @Input() public inputLabel = 'LOGIN.PASSWORD';
  public passwordVisible = false;

  constructor(
    private analytics: AnalyticsService,
  ) { }

  public ngOnInit(): void {
  }

  public get formControl(): AbstractControl {
    return this.form.controls[this.modelName];
  }

  public displayErrors(): boolean {
    return Boolean(this.formControl.errors && (this.formControl.dirty || this.formControl.touched));
  }

  public displayRequiredError(): boolean {
    // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
    return Boolean(this.formControl.errors && this.formControl.errors!.required);
  }

  public displayPatternError(): boolean {
    // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
    return !this.hideError && Boolean(this.formControl.errors && this.formControl.errors!.pattern);
  }

  public displayMatchError(): boolean {
    // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
    return Boolean(this.formControl.errors && this.formControl.errors!.match);
  }

  public togglePassword(): void {
    this.passwordVisible = !this.passwordVisible;
    if (this.passwordVisible) {
      this.analytics.track('clickedShowPassword');
    }
  }

}
