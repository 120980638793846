import { Injectable } from '@angular/core';
import { InboxService } from './inbox.service';
import { InboxSocketService } from './inbox.socket.service';
import _ from 'lodash';
import { InfoboxService } from 'src/app/components/infobox/infobox.service';
import moment from 'moment';
import { FullnamePipe } from '../../pipes/fullname.pipe';
import { CustomerNumberPipe } from '../../pipes/customer-number.pipe';
import { StorageService } from 'src/app/storage.service';
import { TranslateService } from '@ngx-translate/core';
import { ApiService } from '../../api/api.service';

const conversationHistoryDefaultParams = {skip: 0, limit: 5};

export enum ConversationType {
  WhatsApp = 'WhatsApp',
  Sms = 'sms',
  Call = 'call',
}

export enum LogType {
  Create = 'create-conversation',
  Open = 'open-conversation',
  Close = 'close-conversation',
  Reopen = 'reopen-conversation',
  Transfer = 'transfer-conversation',
  Assign = 'assign-conversation',
  Unassign = 'unassign-conversation',
}

export class Conversation {
  public draft: ConversationDraft;
  public preview!: string;
  public logs: Array<ConversationLog>;
  public attachments: Array<Attachment>;
  public customer!: Customer;
  public id!: string;
  public status!: ConversationCategory;
  public type!: ConversationType;
  public isSending = false;
  public phone = '';
  public lastIncomingAt?: string;
  public info = { direction: '' };
  public lastMessageStatus?: string | null;
  public hasSMS!: boolean;
  public isHistory!: boolean;
  public createdAt!: string;
  public lastRepliedAt!: string;
  public unseenCount!: number;
  public hasAttachments!: boolean;
  public hasUnseen!: boolean;
  public hasUnrespondedTransfer!: boolean;
  public userId!: number;
  public areLogsLoading = false;
  public allLogsLoaded = false;
  public areAttachmentsLoading = false;
  public agentDisplayed?: string;
  public agent?: string;
  public firstResponse?: string;
  public totalResponseTime?: number;
  public closedAt?: string;
  public updatedAt?: string;
  public totalResolutionTime?: number;
  private listenersMappingsByEventName: { [key: string]: any };
  private agentsForTransfer: any[] = [];
  private numberOfLogsLimit = 50;
  private numberOfAttachmentsLimit = 50;

  constructor(
    dbConversation: any,
    draft: any,
    private apiService: ApiService,
    private inboxService: InboxService,
    private inboxSocketService: InboxSocketService,
    private infoboxService: InfoboxService,
    private fullnamePipe: FullnamePipe,
    private customerNumberPipe: CustomerNumberPipe,
    private translateService: TranslateService,
  ) {
    this.update(dbConversation);
    this.draft = new ConversationDraft(draft);
    this.listenersMappingsByEventName = {};
    this.listenToConversationUpdates();
    this.logs = [];
    this.attachments = [];
  }

  public get mainConversation(): Conversation {
    return this;
  }

  public get expireTime(): number | null {
    if (this.type !== ConversationType.WhatsApp || this.status === ConversationCategory.Closed || !this.lastIncomingAt) return null;
    return 24 - moment().diff(moment(this.lastIncomingAt), 'hours');
  }

  public get title(): string {
    if (!this.fullnamePipe || !this.customerNumberPipe) return '';
    return `${this.fullnamePipe.transform(this.customer)} | ${this.customerNumberPipe.transform(this.phone)}`;
  }

  public update(dbConversation: any): void {
    _.extend(
      this,
      _.omit(dbConversation, 'customer'),
      { customer: this.customer ? this.customer : dbConversation.customer }
    );
  }

  public updateLog(dbConversationLog: any): void {
    // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
    _.findLast(this.logs, (log: ConversationLog) => log.id === dbConversationLog.id)!.update(dbConversationLog);
  }

  public getInitialLogs(): Promise<void> {
    this.logs = [];
    this.attachments = [];
    this.getAttachments().catch(() => {});
    return this.getLogs().catch(() => {});
  }

  public getMoreLogs(): Promise<void> {
    if (this.allLogsLoaded) return Promise.reject('All logs loaded');
    if (!this.logs) return this.getInitialLogs();
    const numberOfLogsToSkip = this.logs.length;
    return this.getLogs(numberOfLogsToSkip).catch(() => {});
  }

  public getLogs(numberOfLogsToSkip: number = 0): Promise<void> {
    if (!this.inboxService) return Promise.reject('No InboxService');
    this.areLogsLoading = true;
    return this.inboxService.getConversationLogs(this.id, this.numberOfLogsLimit, numberOfLogsToSkip)
      .then((res:any) => res.result ? res.content : [])
      .then((dbConversationLogs: any) => {
        dbConversationLogs.forEach((dbConversationLog: any) => this.addLog(dbConversationLog, true));
        this.allLogsLoaded = dbConversationLogs.length < this.numberOfLogsLimit;
        this.areLogsLoading = false;
      })
      .catch(() => {
        this.areLogsLoading = false;
      });
  }

  public getFormattedLogs(): Promise<void> {
    if (this.logs.length) return Promise.resolve();
    return this.getLogs().then(() => {
      this.logs =  this.logs.filter(log => log.type !== LogType.Create).reverse();
      let firstResponseLog: ConversationLog | undefined;
      let lastResponse = '';
      if (this.type === ConversationType.Call) firstResponseLog = this.logs[0];
      else firstResponseLog = this.logs.find(log => log.direction === 'outbound');
      if (firstResponseLog) {
        const lastResponseLog = _.findLast(this.logs, (log: ConversationLog): boolean => log.direction === 'outbound');
        if (this.type === ConversationType.Call) {
          this.firstResponse = firstResponseLog.content.answeredAt;
          if (this.hasSMS) lastResponse = lastResponseLog && lastResponseLog.createdAt;
          else lastResponse = _.last(this.logs).content.answeredAt;
        } else {
          this.firstResponse = firstResponseLog.createdAt;
          lastResponse = lastResponseLog.createdAt;
        }
        this.totalResponseTime = moment(lastResponse).diff(this.createdAt, 'seconds');
      }
      if (this.status === 'closed') {
        const closeLog = _.findLast(this.logs, (log: ConversationLog): boolean => log.type === LogType.Close);
        this.closedAt = closeLog ? closeLog.createdAt : this.updatedAt;
        this.totalResolutionTime = moment(this.closedAt).diff(this.createdAt, 'seconds');
      }
    });
  }

  public getAttachments(): Promise<void> {
    if (!this.inboxService) return Promise.reject('No InboxService');
    this.areAttachmentsLoading = true;
    return this.inboxService.getAttachments(this.id, this.numberOfAttachmentsLimit)
      .then((res:any) => res.result ? res.content : [])
      .then((dbAttachments: any[]) => {
        dbAttachments.forEach((dbAttachment: any) => {
          // TO DO remove once BE includes dbAttachment.url
          dbAttachment.url = this.apiService.apiUrl(`/WhatsApp/attachments/${dbAttachment.id}`);
          this.attachments.push(LogAttachment.fromDbAttachment(dbAttachment));
        });
        this.areAttachmentsLoading = false;
      })
      .catch(() => {
        this.areAttachmentsLoading = false;
      });
  }

  public getCustomer(): Promise<void> {
    if (!this.inboxService) return Promise.reject('No InboxService');
    return this.inboxService.getCustomer(this.customer.id).then((res:any) => {
      if (res.result) {
        this.customer = new Customer(res.content, this, this.apiService, this.inboxService, this.inboxSocketService, this.infoboxService,
          this.fullnamePipe, this.customerNumberPipe, this.translateService);
      }
    }).catch(() => {});
  }

  public addLog(dbConversationLog: any, isOlderLog: boolean = false): void {
    const newLog = new ConversationLog(dbConversationLog, this.inboxService);
    if (isOlderLog) this.logs.push(newLog);
    else this.logs.unshift(newLog);
    if (newLog.attachment && !isOlderLog) {
      this.attachments.unshift(newLog.attachment);
    }
  }

  public listen(): void {
    _.forEach(this.listenersMappingsByEventName, (eventMappings: any, eventName: string) => {
      const listener = (eventData: any): void => {
        if (eventData.result && eventMappings.check(eventData.content)) {
          eventMappings.handling(eventData.content);
        }
      };
      // @ts-ignore
      if (this.inboxSocketService) this.inboxSocketService['on' + eventName](listener);
      eventMappings.listener = listener;
    });
  }

  public stopListenning(): void {
    if (!this.inboxSocketService) return;
    _.forEach(this.listenersMappingsByEventName, (eventMappings: any, eventName: string) => {
      // @ts-ignore
      this.inboxSocketService['off' + eventName](eventMappings.listener);
    });
  }

  public listenToConversationUpdates(): void {
    this.stopListenning();
    _.extend(this.listenersMappingsByEventName, {
      // eslint-disable-next-line @typescript-eslint/naming-convention
      UpdatedConversation: { handling: (conversation: any) => this.update(conversation),
        check: (conversation: any) => conversation.id === this.id },
    });
    this.listen();
  }

  public listenToLogsUpdates(): void {
    this.stopListenning();
    _.extend(this.listenersMappingsByEventName, {
      // eslint-disable-next-line @typescript-eslint/naming-convention
      NewConversationLog: { handling: (log: any) => this.addLog(log),
        check: (conversationLog: any) => conversationLog.conversationId === this.id },
      // eslint-disable-next-line @typescript-eslint/naming-convention
      UpdatedConversationLog: { handling: (log: any) => this.updateLog(log),
        check: (conversationLog: any) => conversationLog.conversationId === this.id },
    });
    this.listen();
  }

  public stopListenningToLogsUpdates(): void {
    this.stopListenning();
    delete this.listenersMappingsByEventName.NewConversationLog;
    delete this.listenersMappingsByEventName.UpdatedConversationLog;
    this.listen();
  }

  public open(): Promise<void | Object> {
    return this.inboxService ? this.inboxService.openConversation(this.id) : Promise.reject('No InboxService');
  }

  public close(): Promise<any> {
    if (!this.inboxService) return Promise.reject('No InboxService');
    return this.inboxService.closeConversation(this.id).then(() => {
      this.infoboxService.shortInfo(this.translateService.instant('CONVERSATION.CLOSE_SUCCESS'), 'current-conversation-infobox');
    });
  }

  public scrollTo(): void {
    const element = document.getElementById(this.id);
    if (element) {
      element.scrollIntoView();
    }
  }

  public markAsRead(): void {
    if (this.hasUnseen) this.inboxService.markConversationAsRead(this.id);
  }

  public sendMessage(): Promise<void> {
    if (!this.inboxService) return Promise.reject('No InboxService');
    const sendMessageMethodByConversationType = {
      // eslint-disable-next-line @typescript-eslint/naming-convention
      WhatsApp: 'sendWhatsApp',
      call: 'sendSms',
      sms: 'sendSms',
    };

    this.isSending = true;
    // @ts-ignore
    return this.inboxService[sendMessageMethodByConversationType[this.type]](this.id, this.draft)
      .then((results: any) => _.isArray(results) ? _resFromResults(_.flattenDeep(results)) : results)
      .then((res: any) => {
        if (res.result) {
          this.draft = new ConversationDraft({});
        } else {
          this.infoboxService.error(res.errorMessage);
        }
        this.isSending = false;
        return res;
      }).catch(() => {});
  }

  public transferTo(agentId: string): Promise<any> {
    if (!this.inboxService) return Promise.reject('No InboxService');
    this.isSending = true;
    return this.inboxService.transferTo(this.id, agentId).then((res: any) => {
      if (!res.result) this.infoboxService.error(res.errorMessage);
      else {
        const agent = this.agentsForTransfer.find((agentForTransfer: any) => agentForTransfer.id === agentId);
        this.infoboxService.shortInfo(
          this.translateService.instant('CONVERSATION.TRANSFER_SUCCESS') + this.fullnamePipe.transform(agent),
          'current-conversation-infobox'
        );
      }
      this.isSending = false;
      return res;
    }).catch(() => {});
  }

  public getAgentsForTransfer(): Promise<any> {
    if (!this.inboxService) return Promise.reject('No InboxService');
    return this.inboxService.getAgentsForTransfer(this.id).then((res: any) => {
      this.agentsForTransfer = res.result ? res.content : [];
      return res;
    });
  }
}

export class ConversationHistory extends Conversation {
  public isHistory: boolean;
  private _mainConversation: any;

  constructor(
    dbConversation: any,
    customer: Customer,
    mainConversation: any,
    apiService: ApiService,
    inboxService: InboxService,
    inboxSocketService: InboxSocketService,
    infoboxService: InfoboxService,
    fullnamePipe: FullnamePipe,
    customerNumberPipe: CustomerNumberPipe,
    translateService: TranslateService,
  ) {
    super(
      dbConversation,
      {},
      apiService,
      inboxService,
      inboxSocketService,
      infoboxService,
      fullnamePipe,
      customerNumberPipe,
      translateService,
    );
    this.customer = customer;
    this._mainConversation = mainConversation;
    this.isHistory = true;
    this.stopListenning();
  }

  public get mainConversation(): Conversation {
    return this._mainConversation;
  }

  public scrollTo(): void {
    const element = document.getElementById(`history-${this.id}`);
    if (element) {
      element.scrollIntoView(false);
    }
  }
}

interface DbConversationLog {
  type: DraftType;
  createdAt: string;
  content: unknown;
}

export interface DbConversationAttachmentLog extends DbConversationLog {
  content: {
    text: string;
    url: string;
  };
}

export class ConversationLog {
  public id!: string;
  public conversationId!: string;
  public contextId!: string;
  public direction!: string;
  public status!: string;
  public userId!: number;
  public type!: string;
  public createdAt!: string;
  public content!: any;
  public attachment!: Attachment;
  public repliedLog?: ConversationLog;
  public category!: ConversationType;
  public sentAt!: string;
  public receivedAt!: string;

  constructor(
    dbConversationLog: any,
    private inboxService: InboxService
  ) {
    this.update(dbConversationLog);
    this.createAttachment(dbConversationLog);
    this.createRepliedLog();
  }

  public update(dbConversationLog: DbConversationLog): void {
    _.extend(this, dbConversationLog);
  }

  public canCreateAttachment(): boolean {
    return [
      DraftType.Image,
      DraftType.Video,
      DraftType.Audio,
      DraftType.Voice,
      DraftType.Document
    ].includes(this.type as DraftType);
  }

  public scrollTo(): void {
    const element = document.getElementById(this.id);
    if (element) {
      element.scrollIntoView();
    }
  }

  private createRepliedLog(): void {
    if (this.contextId) {
      this.inboxService.getConversationLog(this.conversationId, this.contextId)
        .then((res: any) => this.repliedLog = res.result ? new ConversationLog(res.content, this.inboxService) : undefined).catch(() => {});
    }
  }

  private createAttachment(dbConversationLog: DbConversationLog): void {
    if (this.canCreateAttachment()) {
      this.attachment = LogAttachment.fromDbConversationAttachmentLog(dbConversationLog as DbConversationAttachmentLog);
    }
  }
}

export enum DraftType {
  Text = 'text',
  Image = 'image',
  Audio = 'audio',
  Voice = 'voice',
  Video = 'video',
  Location = 'location',
  Contact = 'contact',
  Document = 'document',
}

export class ConversationDraft {
  public text!: string;
  public attachments!: Array<DraftAttachment>;

  constructor(savedDraft: any) {
    this.text = savedDraft && savedDraft.text || '';
    this.attachments = [];
  }

  public get type(): DraftType {
    return this.attachments[0] ? this.attachments[0].type : DraftType.Text;
  }

  public attachFiles(files: File[]): void {
    files.forEach(file => this.attachments.push(new DraftAttachment(file)));
  }

  public removeAttachmentAt(index: any): void {
    this.attachments.splice(index, 1);
  }
}

export class Attachment {
  public type!: DraftType;
  public name!: string;
  public url!: string;
  public createdAt!: string;

  constructor() {
  }

  public get icon(): string {
    switch (this.type) {
      case 'image': return 'image';
      case 'video': return 'video';
      case 'audio': case 'voice': return 'music';
      case 'document': return this.extension ? this.iconOfDocumentFromExtension(this.extension) : 'default';
      default: return 'default';
    }
  }

  public get extension(): string {
    return this.name ? _.last(this.name.split('.')) : '';
  }

  private iconOfDocumentFromExtension(extension: string | any[]): string {
    return _.find({ pdf: 'pdf', doc: 'word', xls: 'excel', ppt: 'powerpoint' },
      (icon: any, extensionRoot: any) => extension.includes(extensionRoot)) || 'default';
  }
}

interface DbAttachment {
  mimeType: string;
  originalName: string;
  url: string;
  createdAt: string;
}

export class LogAttachment extends Attachment {
  public url!: string;
  public createdAt!: string;

  private constructor() {
    super();
  }

  public static fromDbConversationAttachmentLog(dbConversationAttachmentLog: DbConversationAttachmentLog | ConversationLog): LogAttachment {
    const instance = new this();
    instance.type = dbConversationAttachmentLog.type as DraftType;
    instance.name = dbConversationAttachmentLog.type === DraftType.Document
      ? dbConversationAttachmentLog.content.text
      : _.upperFirst(instance.type) + ' file';
    instance.url = dbConversationAttachmentLog.content.url;
    instance.createdAt = dbConversationAttachmentLog.createdAt;
    return instance;
  }

  public static fromDbAttachment(dbAttachment: DbAttachment): LogAttachment {
    const instance = new this();
    // TO DO remove once BE includes dbAttachment.type
    // replace with: this.type = dbAttachment.type
    instance.type = Object.values(DraftType).find((draftType: DraftType) => dbAttachment.mimeType.includes(draftType))
      || DraftType.Document;
    instance.name = dbAttachment.originalName;
    instance.url = dbAttachment.url;
    instance.createdAt = dbAttachment.createdAt;
    return instance;
  }
}

export class DraftAttachment extends Attachment {
  public file: any;
  constructor(file: any) {
    super();
    this.type = [DraftType.Image, DraftType.Audio, DraftType.Video].find(type => file.type.includes(type)) || DraftType.Document;
    this.name = file.name;
    this.file = file;
  }

  public isTextCompatible(): boolean {
    return [DraftType.Video, DraftType.Image].includes(this.type);
  }
}

function _resFromResults(results: any[]): any {
  return results.find((res: any) => res && !res.result) || { result: true };
}

export enum ContactType {
  Phone = 'phone',
  Email = 'email',
}

export class Customer {
  public id!: string;
  public conversation: Conversation;
  public contactTypes: Array<ContactType>;
  public dob?: Date | string;
  public areConversationsLoading = false;
  public conversationHistory!: Array<ConversationHistory>;
  public canScrollConversationHistory = true;
  public previousSearchFilter: any;
  public firstName!: string;
  public lastName!: string;
  public title!: string;
  public phone!: string;
  public email!: string;
  public phones!: any[];
  public emails!: any[];
  public photo!: string;
  public gender!: string;

  constructor(
    dbCustomer: any,
    conversation: Conversation,
    private apiService: ApiService,
    private inboxService: InboxService,
    private inboxSocketService: InboxSocketService,
    private infoboxService: InfoboxService,
    private fullnamePipe: FullnamePipe,
    private customerNumberPipe: CustomerNumberPipe,
    private translateService: TranslateService,
  ) {
    this.update(dbCustomer);
    this.conversation = conversation;
    this.contactTypes = [ContactType.Phone, ContactType.Email];
    this.getConversationHistory();
  }

  public static saveAllContactChangesInDb(customer: Customer, formCustomer: any): Promise<any> {
    return Promise.all(customer.contactTypes.map((contactType: ContactType) =>
      Customer.saveContactTypeChangesInDb(contactType, customer, formCustomer)))
      .then((results: any) => _resFromResults(results)).catch(() => {});
  }

  public static async saveContactTypeChangesInDb(contactType: ContactType, customer: Customer, formCustomer: any): Promise<any> {
    const contactHandler = (contact: any): ContactHandler => new ContactHandler(contact, contactType, customer.id, customer.inboxService);
    const contactDifference = (firstCustomer: Customer, secondCustomer: Customer): Customer[] =>
    // @ts-ignore
      _.differenceBy(firstCustomer[contactType + 's'], secondCustomer[contactType + 's'], (contact: any) => contact[contactType]);
    const addContactFieldToResults = (results: any[], contacts: any): void =>
      results.forEach((result: any, index: number) => result.contact = contacts[index][contactType]);

    const newContacts = contactDifference(formCustomer, customer);
    const creationResults = await Promise.all(
      newContacts.map((newContact: any) => contactHandler(newContact).createInDb())
    );
    addContactFieldToResults(creationResults, newContacts);

    const primaryContact = formCustomer.getPrimaryContact(contactType);
    if (primaryContact && !primaryContact.id) {
      // @ts-ignore
      primaryContact.id = customer[contactType + 's'].find((contact: any) => contact[contactType] === primaryContact[contactType]).id;
    }
    const previousPrimaryContact = customer.getPrimaryContact(contactType);
    const shouldSetPrimary = primaryContact && (!previousPrimaryContact || primaryContact.id !== previousPrimaryContact.id);
    const primaryResult = shouldSetPrimary ? await contactHandler(primaryContact).setAsPrimaryInDb() : { result: true };

    const removedContacts = contactDifference(customer, formCustomer);
    const deleteResults = await Promise.all(
      removedContacts.map((removedContact: any) => contactHandler(removedContact).deleteFromDb())
    );
    addContactFieldToResults(deleteResults, removedContacts);
    return _resFromResults([...creationResults, primaryResult, ...deleteResults]);
  }

  public update(dbCustomer: any): void {
    _.extend(this, dbCustomer);
    this.dob = dbCustomer.dob ? new Date(dbCustomer.dob) : undefined;
    this.sortContacts();
  }

  public getPrimaryContact(contactType: ContactType): any {
    // @ts-ignore
    return this[contactType + 's'].find((contact: { isPrimary: any }) => contact.isPrimary);
  }

  public hasPrimaryContact(contactType: ContactType): boolean {
    return Boolean(this.getPrimaryContact(contactType));
  }

  public filterConversationHistories(searchFilter: string): void {
    this.getConversationHistory(searchFilter);
  }

  public getConversationHistory(searchFilter?: string): void {
    this.getNewConversationHistory(searchFilter);
  }

  public scrollConversationHistory(searchFilter?: string): void {
    this.getNextConversationHistory(searchFilter);
  }

  public async save(formCustomer: { saveProfileInDb: () => any }): Promise<any> {
    const results = await Promise.all([
      Customer.saveAllContactChangesInDb(this, formCustomer),
      formCustomer.saveProfileInDb()
    ]);
    await this.refresh();
    return _resFromResults(results);
  }

  public getProfile(): any {
    const profile = _.cloneDeep(this) as any;
    profile.dob = this.dob ? moment(this.dob).format('YYYY-MM-DD') : undefined;

    const keysToKeep = ['firstName', 'lastName', 'title', 'gender', 'dob', 'timezoneId', 'id'];
    Object.keys(profile).map((key) => {
      if (!keysToKeep.includes(key)) delete profile[key];
    });

    return profile;
  }

  public saveProfileInDb(): Promise<any> {
    return this.inboxService.updateCustomer(this.getProfile());
  }

  public refresh(): Promise<any> {
    if (!this.inboxService) return Promise.reject('No InboxService');
    return this.inboxService.getCustomer(this.id).then((res: any) => {
      if (res.result) {
        this.update(res.content);
      }
      return res;
    }).catch(() => {});
  }

  private async getNewConversationHistory(searchFilter?: string): Promise<void> {
    if (!this.inboxService) return;
    this.areConversationsLoading = true;
    // eslint-disable-next-line max-len
    return this.inboxService[searchFilter === undefined ? 'getConversationHistory' : 'searchConversationHistory'](this.getConversationHistoryConfig(conversationHistoryDefaultParams.skip, searchFilter)).then((res: any) => {
      const newConversationHistory = this.getConversationHistoryFromResult(res);
      this.conversationHistory = newConversationHistory;
      this.previousSearchFilter = searchFilter;
    }).catch(() => {
      this.conversationHistory = [];
      this.areConversationsLoading = false;
    });
  }

  private async getNextConversationHistory(searchFilter?: string): Promise<void> {
    if (!this.inboxService) return;
    this.areConversationsLoading = true;
    // eslint-disable-next-line max-len
    return this.inboxService[searchFilter === undefined ? 'getConversationHistory' : 'searchConversationHistory'](this.getConversationHistoryConfig(this.conversationHistory.length, searchFilter)).then((res: any) => {
      const newConversationHistory = this.getConversationHistoryFromResult(res);
      this.conversationHistory = this.conversationHistory.length
        ? this.conversationHistory.concat(newConversationHistory)
        : newConversationHistory;
      this.previousSearchFilter = searchFilter;
    }).catch(() => {
      this.areConversationsLoading = false;
    });
  }

  private getConversationHistoryFromResult(res: any): ConversationHistory[] {
    const newConversationHistory = res.result
      ? res.content.map((dbConversation: any) => new ConversationHistory(
        dbConversation,
        this,
        this.conversation,
        this.apiService,
        this.inboxService,
        this.inboxSocketService,
        this.infoboxService,
        this.fullnamePipe,
        this.customerNumberPipe,
        this.translateService))
      : [];
    this.canScrollConversationHistory = Boolean(newConversationHistory.length);
    this.areConversationsLoading = false;
    return newConversationHistory;
  }

  private getConversationHistoryConfig(skip: number, searchFilter?: string)
    : {id: string; skip: number; limit: number; searchText?: string}{
    return {
      id: this.conversation.id,
      skip: skip,
      limit: conversationHistoryDefaultParams.limit,
      searchText: searchFilter
    };
  }

  private sortContacts(): void {
    const sortPrimaryFirst = (contactA: any, contactB: any): number => {
      if (contactA.isPrimary && !contactB.isPrimary) return -1;
      if (!contactA.isPrimary && contactB.isPrimary) return 1;
      return 0;
    };

    this.phones.sort((phoneA: any, phoneB: any): number => sortPrimaryFirst(phoneA, phoneB));
    this.emails.sort((emailA: any, emailB: any): number => sortPrimaryFirst(emailA, emailB));
  }
}

export class ContactHandler {
  private contact: any;
  private type: ContactType;
  private customerId: string;

  constructor(
    contact: any,
    contactType: ContactType,
    customerId: any,
    private inboxService: InboxService
  ) {
    this.contact = contact;
    this.type = contactType;
    this.customerId = customerId;
  }

  public createInDb(): Promise<any> {
    return this.inboxService.createContact(this.customerId, this.type, this.contact).then((res: any) => {
      if (res.result) {
        this.contact.id = res.content.id;
      }
      return res;
    }).catch(() => {});
  }

  public deleteFromDb(): Promise<any> {
    return this.inboxService.deleteContact(this.customerId, this.type, this.contact);
  }

  public async setAsPrimaryInDb(): Promise<any> {
    return this.contact.id
      ? this.inboxService.setContactAsPrimary(this.customerId, this.type, this.contact)
      : Promise.reject({ result: false, errorMessage: `Primary ${this.type} id missing` });
  }
}

export enum ConversationCategory {
  New = 'new',
  Open = 'open',
  Closed = 'closed',
}
export class ConversationList {
  public conversations: Array<Conversation>;
  public currentCategory: ConversationCategory = ConversationCategory.New;
  private listenersMappingsByEventName = {
    /* eslint-disable @typescript-eslint/naming-convention */
    AssignedConversation: { handling: (data: any): void => this.addDbConversationToTop(data) },
    UpdatedConversation: { handling: (data: any): void => this.moveConversationToTop(data) },
    UnassignedConversation: { handling: (data: any): void => this.removeConversation(data) },
    /* eslint-enable @typescript-eslint/naming-convention */
  };

  constructor(
    dbConversations: Array<any>,
    private apiService: ApiService,
    private inboxService: InboxService,
    private inboxSocketService: InboxSocketService,
    private infoboxService: InfoboxService,
    private fullnamePipe: FullnamePipe,
    private customerNumberPipe: CustomerNumberPipe,
    private translateService: TranslateService,
    private storageService: StorageService,
  ) {
    this.conversations = dbConversations.map((dbConversation: any) => this.generateConversationObject(dbConversation));
    this.listen();
  }

  public switchCategory(category: ConversationCategory): void {
    this.currentCategory = category;
  }

  public switchCategoryAndChangeTab(category: ConversationCategory): void {
    this.switchCategory(category);
    this.categorySwitchHandler(this.currentCategory);

  }

  public setCategorySwitchHandler(callback: (category: ConversationCategory) => void): void {
    this.categorySwitchHandler = (category: ConversationCategory): void => callback(category);
  }

  public saveConversationsDrafts(): void {
    this.storageService.local.setDraftsByConversationId(
      _.fromPairs(this.conversations.filter(conversation => conversation.draft.text)
        .map(conversation => [conversation.id, { text: conversation.draft.text }]))
    );
  }

  public listen(): void {
    _.forEach(this.listenersMappingsByEventName, (eventMappings: any, eventName: string) => {
      const listener = (eventData: any): void => {
        if (eventData.result) {
          eventMappings.handling(eventData.content);
        }
      };
      // @ts-ignore
      this.inboxSocketService['on' + eventName](listener);
      eventMappings.listener = listener;
    });
  }

  public stopListenning(): void {
    _.forEach(this.listenersMappingsByEventName, (eventMappings: any, eventName: string) => {
      // @ts-ignore
      this.inboxSocketService['off' + eventName](eventMappings.listener);
    });
  }

  public getConversationsOfCategory(category: ConversationCategory): Conversation[] {
    return this.conversations?.filter(conversation => conversation.status === category);
  }

  public getUnseenCountOfCategory(category: ConversationCategory): number {
    return this.getConversationsOfCategory(category).filter(conversation => conversation.hasUnseen).length;
  }

  private generateConversationObject(dbConversation: any): Conversation {
    return new Conversation(
      dbConversation,
      this.getSavedDraftOfConversation(dbConversation),
      this.apiService,
      this.inboxService,
      this.inboxSocketService,
      this.infoboxService,
      this.fullnamePipe,
      this.customerNumberPipe,
      this.translateService,
    );
  }

  private getSavedDraftOfConversation(conversation: Conversation): ConversationDraft {
    const savedDraftsByConvId = this.storageService.local.getDraftsByConversationId();
    const savedDraft = savedDraftsByConvId ? savedDraftsByConvId[conversation.id] : null;
    return savedDraft || {};
  }

  private addDbConversationToTop(dbConversation: any): void {
    this.conversations?.unshift(this.generateConversationObject(dbConversation));
  }

  private moveConversationToTop(dbConversation: any): void {
    const conversationInList = this.pullConversationFromList(dbConversation);
    if (conversationInList) this.conversations?.unshift(conversationInList);
  }

  private removeConversation(dbConversation: any): void {
    const removedConversation = this.pullConversationFromList(dbConversation);
    if (removedConversation) removedConversation.stopListenning();
  }

  private pullConversationFromList(dbConversation: any): Conversation {
    return _.remove(this.conversations, (conversation: Conversation) => conversation.id === dbConversation.id)[0];
  }

  private categorySwitchHandler(newCategory: ConversationCategory): void {
  };
}
@Injectable({
  providedIn: 'root'
})

export class ConversationsService {
  public currentConversation?: Conversation;
  public conversationList: ConversationList =
  new ConversationList([], this.apiService, this.inboxService, this.inboxSocketService,
    this.infoboxService, this.fullnamePipe, this.customerNumberPipe, this.translateService, this.storageService);
  private listenersMappingsByEventName = {
    /* eslint-disable @typescript-eslint/naming-convention */
    AssignedConversation: { handling: (data: any): void => this.onAssignedConversation(data) },
    UpdatedConversation: { handling: (data: any): void => this.onUpdatedConversation(data) },
    UnassignedConversation: { handling: (data: any): void => this.onRemovedConversation(data) },
    NewConversationLog: { handling: (data: any): void => this.onNewConversationLog(data) },
    // Workaround => TO DO REMOVE after BE fixed
    UpdatedConversationLog: { handling: (data: any): void => this.assignCallConversationDirectionFromLog(data) },
    /* eslint-enable @typescript-eslint/naming-convention */
  };
  private focusListener?: any;

  constructor(
    private apiService: ApiService,
    private inboxService: InboxService,
    private inboxSocketService: InboxSocketService,
    private infoboxService: InfoboxService,
    private fullnamePipe: FullnamePipe,
    private customerNumberPipe: CustomerNumberPipe,
    private storageService: StorageService,
    private translateService: TranslateService,
  ) {
    this.listenToFocus();
    this.fetchConversations().then(() =>
      this.selectStoredConversation()
    ).catch(() => {});
  }

  public fetchConversations(): Promise<void> {
    return this.inboxService.getConversations().then((res: any) => this.setConversationList(res.content))
      .catch(error => {});
  }

  public selectHistoryConversation(conversationHistory: Conversation): void {
    if (conversationHistory.mainConversation.id === conversationHistory.id) return this.backToMainConversation();

    if (this.currentConversation) this.currentConversation.stopListenningToLogsUpdates();
    this.currentConversation = conversationHistory;
    this.currentConversation.getInitialLogs();
  }

  public backToMainConversation(): void {
    if (this.currentConversation && this.currentConversation.isHistory) {
      this.setLiveCurrentConversation(this.currentConversation.mainConversation);
    }
  }
  public saveDrafts(): void {
    this.conversationList.saveConversationsDrafts();
  }

  public selectConversation(conversation?: Conversation): void {
    this.setLiveCurrentConversation(conversation);
    if (this.currentConversation) this.currentConversation.getCustomer().catch(() => {});
  }

  public isSelectedConversation(conversation: any): boolean {
    return Boolean(this.currentConversation && this.currentConversation.id === conversation.id);
  }

  public filterConversations(searchText: string): Promise<void> {
    return this.inboxService.searchConversations(searchText)
      .then((res: any) => this.setConversationList(res.content))
      .catch(error => {});
  }

  public setCategorySwitchHandler(callback: (category: ConversationCategory) => void): void {
    this.categorySwitchHandler = (category: ConversationCategory): void => callback(category);
  }

  private setLiveCurrentConversation(conversation?: Conversation): void {
    if (this.currentConversation) this.currentConversation.stopListenningToLogsUpdates();
    this.currentConversation = conversation;
    if (!this.currentConversation) this.storageService.local.removeCurrentConversationId();
    else {
      this.storageService.local.setCurrentConversationId(this.currentConversation.id);
      if (this.currentConversation.status !== this.conversationList.currentCategory) {
        this.conversationList.switchCategoryAndChangeTab(this.currentConversation.status);
      }
      this.currentConversation.getInitialLogs().then(() => {
        if (this.currentConversation) this.currentConversation.listenToLogsUpdates();
        this.scrollToFirstUnseenInboundLog();
        this.markCurrentConversationAsRead();
      }).catch(error => {});
    }
  }

  private isSelectedConversationId(conversationId: string): boolean {
    return this.isSelectedConversation({ id: conversationId });
  }

  private setConversationList(dbConversations: Array<any>): void {
    this.stopConversationsFromListening();
    this.conversationList = new ConversationList(dbConversations, this.apiService, this.inboxService, this.inboxSocketService,
      this.infoboxService, this.fullnamePipe, this.customerNumberPipe, this.translateService, this.storageService);
    this.conversationList.setCategorySwitchHandler((newCategory: ConversationCategory) => this.categorySwitchHandler(newCategory));
  }

  private categorySwitchHandler(newCategory: ConversationCategory): void {
  };

  private selectStoredConversation(): void {
    const storedConversationId = this.storageService.local.getCurrentConversationId();
    if (storedConversationId) this.selectConversationById(storedConversationId);
  }

  private findConversationById(conversationId: string): Conversation | undefined {
    return this.conversationList.conversations?.find(conversation => conversation.id === conversationId);
  }

  private selectConversationById(conversationId: string): void {
    const matchingConversation = this.findConversationById(conversationId);
    if (matchingConversation) this.selectConversation(matchingConversation);
  }

  private onAssignedConversation(dbConversation: any): void {
    if (dbConversation.type === ConversationType.Call) this.focusAssignedCallConversation(dbConversation);
  }

  private onUpdatedConversation(dbConversation: any): void {
    if (this.isSelectedConversationId(dbConversation.id)) {
      this.conversationList.switchCategoryAndChangeTab(dbConversation.status);
    }
  }

  private onRemovedConversation(dbConversation: any): void {
    if (this.isSelectedConversation(dbConversation)) this.selectConversation(undefined);
  }

  private onNewConversationLog(dbConversationLog: any): void {
    this.scrollListToConversationById(dbConversationLog.conversationId);
    if (this.isSelectedConversationId(dbConversationLog.conversationId)) {
      this.scrollToFirstUnseenInboundLog();
      if (document.hasFocus()) this.markCurrentConversationAsRead();
    }
    this.assignCallConversationDirectionFromLog(dbConversationLog);
  }

  private assignCallConversationDirectionFromLog(dbConversationLog: any): void {
    if (dbConversationLog.type !== ConversationType.Call) return;
    const conversationOfLog = this.findConversationById(dbConversationLog.conversationId);
    if (conversationOfLog && !conversationOfLog.info) conversationOfLog.update({info: {direction: dbConversationLog.direction}});
  }

  private listenToFocus(): void {
    _.forEach(this.listenersMappingsByEventName, (eventMappings: any, eventName: string) => {
      const listener = (eventData: any): void => {
        if (eventData.result) {
          eventMappings.handling(eventData.content);
        }
      };
      // @ts-ignore
      this.inboxSocketService['on' + eventName](listener);
      eventMappings.listener = listener;
    });
    this.focusListener = window.addEventListener('focus', () => this.markCurrentConversationAsRead());
  }

  private stopListeningToFocus(): void {
    _.forEach(this.listenersMappingsByEventName, (eventMappings: any, eventName: string) => {
      // @ts-ignore
      this.inboxSocketService['off' + eventName](eventMappings.listener);
    });
    window.removeEventListener('focus', this.focusListener);
  }

  private stopConversationsFromListening(): void {
    this.conversationList.conversations?.forEach(conversation => conversation.stopListenning());
  }

  private markCurrentConversationAsRead(): void {
    if (this.currentConversation && !this.currentConversation.isHistory) this.currentConversation.markAsRead();
  }

  private focusAssignedCallConversation(dbConversation: any): void {
    this.conversationList.switchCategoryAndChangeTab(dbConversation.status);
    setTimeout(() => {
      this.selectConversationById(dbConversation.id);
      this.scrollListToConversationById(dbConversation.id);
    }, 500);
  }

  private scrollListToConversationById(conversationId: string): void {
    const conversationToScrollTo = this.findConversationById(conversationId);
    setTimeout(() => conversationToScrollTo && conversationToScrollTo.scrollTo(), 0);
  }

  private scrollToFirstUnseenInboundLog(): void {
    if (this.currentConversation) {
      const oldestInboundUnseenLog: ConversationLog = _.findLast(this.currentConversation.logs, (log: ConversationLog) =>
        log.direction === 'inbound' && log.status !== 'seen') || this.currentConversation.logs[0];
      setTimeout(() => {
        setTimeout(() => oldestInboundUnseenLog && oldestInboundUnseenLog.scrollTo(), 300);
      }, 0);
    }
  }
}
