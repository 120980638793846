import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, ParamMap } from '@angular/router';
import { first } from 'rxjs/operators';
import { AccountService } from '../../services/account.service';
import { UsernameService } from '../../services/username.service';
import { LoginService } from './login.service';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss']
})

export class LoginComponent implements OnInit {
  private accountQueryParam = 'account';
  private usernameQueryParam = 'username';

  constructor(
    private route: ActivatedRoute,
    private accountService: AccountService,
    private usernameService: UsernameService,
    private login: LoginService,
  ) {}

  public async ngOnInit(): Promise<void> {
    this.fetchQueryParamsFromUrl();
  }

  private fetchQueryParamsFromUrl():void {
    this.route.queryParamMap.pipe(first()).subscribe((params : ParamMap) => {
      const accountName = params.get(this.accountQueryParam);
      if (accountName) {
        this.accountService.accountName = accountName.replace(/ /g, '+');
      }
      const username = params.get(this.usernameQueryParam);
      if (username) {
        this.usernameService.username = username.replace(/ /g, '+');
      }
    });
  }
}
