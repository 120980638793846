import { Directive, EventEmitter, HostListener, Input, Output } from '@angular/core';

@Directive({
  selector: '[appOnEndScroll]'
})
export class OnEndScrollDirective {

  @Output('appOnEndScroll') public endScroll = new EventEmitter<void>();
  @Input() public disableScroll = false;

  constructor() { }

  @HostListener('scroll', ['$event'])
  public onEndScroll(event: any):void {
    // end of scroll reached
    if (!this.disableScroll && event.target.offsetHeight + event.target.scrollTop === event.target.scrollHeight) this.endScroll.emit();
  };
}
