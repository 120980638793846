import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'reverse',
  pure: false
})
export class ReversePipe implements PipeTransform {

  public transform(array: any[]): any[] {
    if (!array || !array.length) {
      return array;
    }
    return array.slice().reverse();
  }

}
