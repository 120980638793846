import { Injectable } from '@angular/core';
import { Socket } from 'ngx-socket-io';
import { Observable } from 'rxjs';
import { StatusBarService } from '../components/status-bar/status-bar.service';

export class AgentSocket extends Socket {
  constructor(url:string, conf:any) {
    super({ url: url, options: conf });
  }
}

declare global {
  interface Window {
    ZIWO?: WindowZiwo;
  }
}

interface WindowZiwo {
  connect: Function;
  disconnect: Function;
  internetMonitor: Observable<boolean>;
  client: any;
  labels: object;
  sockets: {
    agent: any;
  };
}

export interface Call {
  [key: string]: any;
}

@Injectable({
  providedIn: 'root'
})
export class DialpadCoreService {
  public client!: any; // To type properly
  public agentSocket!:AgentSocket;
  private core!:WindowZiwo;
  private isZiwoCoreReady = false;
  private isInit = false;

  constructor(
    private statusBarService:StatusBarService,
  ) { }

  public isItInit(): boolean {
    return this.isInit;
  }

  public isReady(): boolean {
    return this.isZiwoCoreReady;
  }

  public init(): void {
    if (this.isInit) {
      return;
    }
    this.registerListerners();
    this.isInit = true;
  }

  public logout(): void {
    if (this.client) {
      this.client.disconnect();
      this.isZiwoCoreReady = false;
      this.client = undefined;
      try {
        delete window.ZIWO;
      } catch {
        console.warn('Ziwo core was already killed off');
      }
    }
  }

  public getCurrentCall(): Call|undefined {
    try {
      const calls = this.client.calls;
      if (calls.length === 0) {
        return undefined;
      }
      if (calls.length === 1) {
        return calls[0];
      }
      const call = calls.find((x:any) =>
      // get call with latest state with 'active'
        x.states && x.states[x.states.length - 1] && x.states[x.states.length - 1].state === 'active');
      // if we have an active call, return it, otherwise, returns the first call
      return call ? call : calls[0];
    } catch {
      return undefined;
    }
  }

  public onDialpadEvents(ev: any): void {
    console.log('[DIALPAD EVENT]', ev);
  }

  public async getCore(): Promise<WindowZiwo> {
    return new Promise<WindowZiwo>((onRes, onErr) => {
      if (this.isZiwoCoreReady) {
        onRes(this.core);
        return;
      }
      const int = window.setInterval(() => {
        if (this.isZiwoCoreReady) {
          onRes(this.core);
          window.clearInterval(int);
          return;
        }
      }, 500);
    });
  }

  private registerListerners(): void {
    window.addEventListener('ziwo-ready', ev => {
      // core of our communication system is up and running
      this.core = window.ZIWO as WindowZiwo;
      this.client = this.core.client;
      this.agentSocket = this.core.sockets.agent;
      this.isZiwoCoreReady = true;
    });
    window.addEventListener('ziwo-ringing', ev => {
      const call = (ev as CustomEvent).detail;
      this.statusBarService.onCall(call);
    });
    window.addEventListener('ziwo-active', ev => {
      const call = (ev as CustomEvent).detail;
      this.statusBarService.onCall(call);
    });
    window.addEventListener('ziwo-hangup', ev => {
      window.setTimeout(() => {
        const currentCall = this.getCurrentCall();
        this.statusBarService.onCall(currentCall);
      }, 500);
    });
  }
}
