import { Pipe, PipeTransform } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { Conversation, ConversationType } from '../agent/inbox/conversations.service';
import { CustomerNumberPipe } from './customer-number.pipe';

@Pipe({
  name: 'conversationPreview',
  pure: false
})
export class ConversationPreviewPipe implements PipeTransform {

  constructor(private translateService: TranslateService, private customerNumberPipe: CustomerNumberPipe) {
  }

  public transform(conversation: Conversation): string {
    if (!conversation.preview) {
      return '';
    }
    let _preview = conversation.preview;
    if (conversation.type === ConversationType.Call && conversation.preview && !conversation.hasSMS) {
      const previewParts = conversation.preview.split(' ');
      if (previewParts.length >= 2) {
        let previewStart = previewParts.splice(0, 2).join('_').toUpperCase();
        previewStart = `CONVERSATION.PREVIEW.${previewStart}`;
        const previewEnd = previewParts.join(' ');
        _preview = `${this.translateService.instant(previewStart)} ${this.customerNumberPipe.transform(previewEnd)}`;
      }
    }
    return _preview;
  }

}
