import { Component, OnInit, ViewChild, ElementRef } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { MatIconRegistry } from '@angular/material/icon';
import { DomSanitizer } from '@angular/platform-browser';
import _ from 'lodash';
import { ConversationCategory, ConversationsService } from '../conversations.service';
import { ActivatedRoute } from '@angular/router';
import { Subscription } from 'rxjs';
import { ValidatorService } from 'src/app/services/validator.service';
import { patient } from 'src/app/decorators/patient';

interface TabCategoryIndexMapInterface {
  [key: number]: TabCategory;
}

interface MappingsByChannelInterface {
  [key: string]: {
    [key: string]: string;
    iconPath: string;
  };
}

export enum TabCategory {
  New = 'new',
  Open = 'open',
  Closed = 'closed',
}

@Component({
  selector: 'app-conversations-list',
  templateUrl: './conversations-list.component.html',
  styleUrls: ['./conversations-list.component.scss']
})
export class ConversationsListComponent implements OnInit {

  public static defaultTab = TabCategory.New;

  @ViewChild('searchConversationsListInput') public searchConversationsListInput!: ElementRef;

  public iconsByCategory: MappingsByChannelInterface = {
    new: { label: 'new', iconPath: '/assets/images/whatsapp/new.svg' },
    open: { label: 'open', iconPath: '/assets/images/icons/agent.svg' },
    closed: { icon: 'check_circle', iconPath: '' }
  };
  public searchText = '';
  public categoriesNames = Object.keys(this.iconsByCategory);
  public isSearching = false;
  public selectedIndex = 0;

  private routeSegmentSub = Subscription.EMPTY;
  private readonly tabCategoryIndexMap:TabCategoryIndexMapInterface = {
    0: TabCategory.New,
    1: TabCategory.Open,
    2: TabCategory.Closed,
  };

  constructor(
    protected translateService: TranslateService,
    private matIconRegistry: MatIconRegistry,
    private domSanitizer: DomSanitizer,
    private conversationsService: ConversationsService,
    private activatedRoute: ActivatedRoute,
    private validator: ValidatorService,
  ) {
    _.map(this.iconsByCategory, (icon:any) => {
      if (!icon.iconPath) {
        return;
      }
      this.matIconRegistry.addSvgIcon(
        icon.label,
        this.domSanitizer.bypassSecurityTrustResourceUrl(icon.iconPath)
      );
    });
  }

  @patient()
  public filterConversations(searchText: string): void {
    this.conversationsService.filterConversations(searchText);
  }

  public ngOnInit(): void {
    this.routeSegmentSub = this.activatedRoute.paramMap.subscribe(p => {
      this.setSelectedIndex(this.getTabFromParam(p.get('tab')));
    });

    this.setCategorySwitchHandler();
  }

  public ngOnDestroy(): void {
    this.routeSegmentSub.unsubscribe();
  }

  public trackByIndex(index: number, category: string): number {
    return index;
  }

  public getCategoryLabel(category: string): string {
    return this.translateService.instant(`INBOX.CONVERSATIONS_LIST.TAB_LABELS.${category.toUpperCase()}`);
  }

  public searching(): void {
    this.isSearching = !this.isSearching;
    if (!this.isSearching) {
      this.fetchConversations();
    } else {
      window.setTimeout(() => this.focusSearchInput(), 0);
      this.filterConversations(this.searchText);
    }
  }

  public getNumberOfUnreadConversations(category: string): number {
    return this.conversationsService.conversationList.getUnseenCountOfCategory(category as ConversationCategory);
  }

  public switchCategory(newCategory: {index: number}): void {
    this.updateTabUrlSegment(this.tabCategoryIndexMap[newCategory.index]);
    this.conversationsService.conversationList.switchCategory(this.categoriesNames[newCategory.index] as ConversationCategory);
  }

  private setSelectedIndex(selectedTab: TabCategory): void {
    this.selectedIndex = _.findKey(this.tabCategoryIndexMap, (tab: TabCategory) => tab === selectedTab) as number;
  }

  private updateTabUrlSegment(tab:TabCategory): void {
    history.pushState(
      {tabNavigation: tab},
      tab.toString(),
      `${window.location.href.replace(/\/inbox\/([a-zA-Z])+($|\?|\/)/, `/inbox/${tab.toString()}${window.location.search ? '?' : ''}`)}`
    );
  }

  private fetchConversations(): void {
    this.conversationsService.fetchConversations();
  }

  private focusSearchInput(): void {
    if (this.searchConversationsListInput && this.searchConversationsListInput.nativeElement) {
      this.searchConversationsListInput.nativeElement.focus();
    }
  }

  private getTabFromParam(tabParam:string|null): TabCategory {
    if (!tabParam) {
      return ConversationsListComponent.defaultTab;
    }
    return this.validator.isStringInEnum(TabCategory, tabParam) ?
      tabParam as TabCategory :
      ConversationsListComponent.defaultTab;
  }

  private setCategorySwitchHandler(): void {
    this.conversationsService.setCategorySwitchHandler((category: ConversationCategory): void => {
      if (this.categoriesNames.includes(category)) this.selectedIndex = this.categoriesNames.indexOf(category);
    });
  }

}
