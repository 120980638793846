<div #messengerElement class="messenger" [ngClass]="{ 'initial-textarea': !hasTextAreaBeenInitialized }">
    <div #messengerContainer class="container" [ngClass]="{ 'dragover': isDraggingOver }" (dragover)="onDragOver($event)" (dragleave)="onDragLeave($event)" (drop)="onDropSuccess($event)">
        <div class="textbox">
            <textarea #messengerTextarea class="textarea" [disabled]="!canSendSms()" [(ngModel)]="conversation.draft.text" [placeholder]="placeholderText()" [maxlength]="maxLength || ''" (ngModelChange)="setMessengerBoxHeight($event)" (keydown.enter)="enterClicked($event)" id="messenger-textarea"></textarea>
        </div>
        <div class="lower-box">
            <div class="attachments" *ngIf="withAttachements() && conversation.draft.attachments.length">
                <div class="attachment" *ngFor="let attachment of conversation.draft.attachments; index as currentIndex; trackBy: trackByIndex">
                    <app-attachment [attachment]="attachment" [withRemove]="true" (remove)="removeAttachment($event, currentIndex)"></app-attachment>
                </div>
            </div>
            <div class="end-of-box">
                <input hidden type="file" multiple #uploader (change)="onAttachSuccess($event)" id="uploader-input" [disabled]="!canSendSms()"/>
                <mat-icon class="attachment-icon" [ngClass]="{ 'with-attachment': conversation.draft.attachments.length, 'disabled': !canSendSms() }" *ngIf="withAttachements()" type="fab" (click)="uploader.click()" id="uploader-icon">
                    attach_file
                </mat-icon>
                <button class="messenger-send-button" mat-raised-button (click)="send()" [disabled]="!canSendMessage()" id="send-message-button">
                    {{ 'INBOX.CURRENT_CONVERSATION.MESSENGER.SEND' | translate }}
                </button>
            </div>
        </div>
    </div>
    <div class="bottom-message">
        <span class="bottom-text">{{ getMaxLengthMessage() }}</span>
        <span class="bottom-text return-use-message-text" [ngClass]="{ 'visible': displayReturnUseMessageText() }">{{ 'INBOX.CURRENT_CONVERSATION.MESSENGER.RETURN_MESSAGE' | translate }}</span>
    </div>
</div>