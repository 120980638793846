<div class="search">
    <mat-icon class="search-icon-search">search</mat-icon>
    <div class="search-content">
        <mat-form-field class="field" floatLabel="never">
            <mat-label>{{ searchText ? searchText : 'SHARED.GENERAL.SEARCH' | translate }}</mat-label>
            <input autocomplete="off" matInput type="text" [(ngModel)]="searchInput" (ngModelChange)="search()" [id]="inputId"/>
        </mat-form-field>
        <button class="search-icon-close" (click)="clear()" *ngIf="searchInput" mat-icon-button aria-label="search" tabindex="-1" type="button" id="search-clear-button">
            <mat-icon>close</mat-icon>
        </button>
    </div>
</div>