import { Component, OnInit } from '@angular/core';
import { DateService, DayTimeLabel } from '../../../services/date.service';

@Component({
  selector: 'app-login-left-pane',
  templateUrl: './login-left-pane.component.html',
  styleUrls: ['./login-left-pane.component.scss']
})
export class LoginLeftPaneComponent implements OnInit {

  public dayTimeCssClass?: DayTimeLabel;

  constructor(
    private dateService: DateService,
  ) {
    this.dayTimeCssClass = this.dateService.getDayTime();
  }

  public ngOnInit(): void {
  }

}
