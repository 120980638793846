import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-call-list',
  templateUrl: './call-list.component.html',
  styleUrls: ['./call-list.component.scss']
})
export class CallListComponent implements OnInit {

  constructor() { }

  public ngOnInit(): void {
  }

}
